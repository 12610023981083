import React from 'react'
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import { ConferenceContext } from '../utilities/Contexts'
import {useContext} from 'react'
import { useSelector } from 'react-redux'
// import io from 'socket.io-client'
const TerpList = ()=>{

    const [_menu, _setMenu] = React.useState([])
    const [_selected, _setSelected] = React.useState(0)
    const {terpList, socket, position, LiveBroadcastSelection, setTerpList, stream} = useContext(ConferenceContext)

    console.log(stream)
    console.log(socket)

    const onInputChange = (event) => {

        console.log(event.target.value)
        _setSelected(event.target.value)
        LiveBroadcastSelection(event.target.value)
        
        socket.emit('newStream',{'session_id':stream.sessionId,'stream_id':event.target.value})
    }

    React.useEffect(()=>{

        socket.on('TerpList',(data)=>{

            console.log(data.results)
            setTerpList(data.results)

            if(data.results.length===0)
            {
                socket.emit('getTerpList',{'sessionID':stream.sessionId})
            }
            else
            {
                if(data.results.find(terp=>terp.stream_id===stream.streamId))
                {
                    setTerpList(data.results)
                }
                else
                {
                    socket.emit('getTerpList',{'sessionID':stream.sessionId})
                }
            }
        })
            
        socket.emit('getTerpList',{'sessionID':stream.sessionId})

    },[setTerpList, stream])

    React.useEffect(()=>{

        var theMenu = []
        console.log(terpList)
        terpList.map((terp,index)=>{
            console.log(terp)
            console.log(terp.spotlight)
            if(terp.spotlight){

                console.log(terp)
                
                _setSelected(terp.stream_id)
                LiveBroadcastSelection(terp.stream_id)
            }
            if(terp.position === 'interpreter')
            {
                theMenu.push(<MenuItem key={index} value={terp.stream_id}>{terp.name}</MenuItem>)
            }
            
            return ''
        })
        _setMenu(theMenu)
    },[terpList])

    return(
        <FormControl fullWidth>
            <InputLabel id='select-label' variant='outlined'>Terp List</InputLabel>
                <Select
                disabled={position==='interpreter'?false:true}
                labelId='select-label'
                value={_selected}
                onChange={onInputChange}>
                    {_menu}
                </Select>  
        </FormControl>
    )
}

export default TerpList
