/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import axios from 'axios'

import {Grid,AppBar, Skeleton, Toolbar, Button, IconButton, ButtonGroup, Box, Typography, Divider, Stack, Tooltip, Slider} from '@mui/material'
import {MicOutlined, MicOffOutlined, VideocamOutlined, VideocamOffOutlined, ZoomOutMapOutlined, ZoomInMapOutlined, Lock, LockOpen} from '@mui/icons-material'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import {VideoContainer} from '../antmedia/VideoContainer'
import {WebRTCInit} from '../antmedia/WebRTCInit'
import DeviceInputs from '../components/DeviceInputs'
import CallEnd from '@mui/icons-material/CallEnd'
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import { VolumeDown } from '@mui/icons-material'
import TerpList from '../antmedia/TerpList'
import '../styles/videoconference.css'
import ButtonStyled from '../components/ButtonStyled'
import {useSelector} from 'react-redux'
import {socket} from '../components/socket'
// import io from 'socket.io-client'
import { useContext, useCallback, useRef } from 'react'
import { ConferenceContext } from '../utilities/Contexts'
import { ErrorBoundary } from 'react-error-boundary'


const VideoConference = (props)=>
{
    const handle = useFullScreenHandle()
    const [streamList, setStreamList] = React.useState([])
    const [camera,setCamera]= React.useState(0)
    const [cameraOff, setCameraOff]= React.useState(false)
    const [lock, setLock] = React.useState(true)
    const [webRTCAdaptor, setWebRTCAdaptor] = React.useState(null)
    const [_videoContainer, setVideoContainer] = React.useState(null)
    const [micMuted, setMicMuted] = React.useState(false)
    const [viewer, setViewer] = React.useState(0)
    const [start, setStart] = React.useState(false)
    const [numColor, setNumColor] = React.useState('#84BD00')
    const [animeName, setAnimeName] = React.useState('blinker')
    const [barOpacity, setBarOpacity] = React.useState(1)
    const [videoInput, setVideoInput] = React.useState(<Skeleton animation='wave' variant="rectangular" width={210} height={60} />)
    const [audioInput, setAudioInput] = React.useState(<Skeleton animation='wave' variant="rectangular" width={210} height={60} />)
    const [isPending, startTransition] = React.useTransition()
    const [volumeOff, setVolumeOff] = React.useState(false)
    const auth = useSelector(state=>state.auth)
    const stream = useSelector(state=>state.stream)
    const [terpList, setTerpList] = React.useState([])
    const [position, setPosition] = React.useState(stream.position)
    const [volume, setVolume] = React.useState(75)
    const [volumeIcon, setVolumeIcon] = React.useState(<VolumeUp/>)
    const [init, setInit] = React.useState(false)
    // const socket = io(process.env.REACT_APP_LOCALHOST)
  
    var webSocketUrl = ''

    console.log(stream)
    console.log('Stream ID: '+stream.streamId)

    const manageCameras=useCallback((operator)=>{
      switch(operator)
      {
        case 'increment':
          setCamera(camera+1)
        break;
        case 'decrement':
          setCamera(camera-1)
        break;
        default:
          return camera
      }
    },[camera])

      React.useEffect(()=>{
        if(_videoContainer === null)
        {
          console.log(_videoContainer)
          var scene = document.getElementsByClassName('Scene')[0]
          setVideoContainer(new VideoContainer(scene, socket, manageCameras))
        }
      },[_videoContainer])


    if(process.env.REACT_APP_SERVER==='DEV')
    {
      webSocketUrl = "wss://dev.accessondemand.io:/WebRTCAppEEs/websocket?rtmpForward=undefined"
    }
    else if(process.env.REACT_APP_SERVER==='PRO')
    {
      webSocketUrl = 'wss://www.accessondemand.io:/WebRTCAppEE/websocket?rtmpForward=undefined'
    }else
    {
      webSocketUrl = 'ws://127.0.0.1:5080/WebRTCAppEE/websocket?rtmpForward=undefined'
    }

    // 

    const backBtn =()=>
    {
        console.log('Checking the WebRTCAdaptor')

        console.log(webRTCAdaptor)
        console.log('Auth: '+auth.role)
        console.log('Stream ID: '+stream.streamId)
        console.log('Session ID: '+stream.sessionId)

        if(webRTCAdaptor.localStream)
        {
          socket.emit('stopPublish',{'sessionID':stream.sessionId, 'streamID':stream.streamId})
          webRTCAdaptor.closeStream()
          webRTCAdaptor.checkVideoLength()
        }

        startTransition(()=>{
          switch(auth.role)
          {
            case 3:
            case 4:
              window.location.href='/services/presentation'
              break;
            case 6:
              window.location.href = '/services/assignments'
              break;
            case 7:
              window.location.href = '/myrequests'
              break;
            default:
              console.log('No role is found')
              break;
          }
        })
    }

    React.useEffect(()=>{

      if(webRTCAdaptor===null){ return }

      socket.emit('toggleCamera', {'sessionID':stream.sessionId, 'streamID':stream.streamId, 'toggled':cameraOff})

    },[cameraOff])

    const toggleCamera=()=>
    {
      if(webRTCAdaptor===null) { return }

      console.log(cameraOff)

      if(!cameraOff)
      {
        webRTCAdaptor.turnOffLocalCamera()
        setCameraOff(true)
      }
      else
      {
        webRTCAdaptor.turnOnLocalCamera()
        setCameraOff(false)
      }
    }

    const toggleMic=()=>
    {
      if(!micMuted)
      {
        webRTCAdaptor.muteLocalMic()
        setMicMuted(!micMuted)
      }
      else
      {
        webRTCAdaptor.unmuteLocalMic()
        setMicMuted(!micMuted)
      }
    }

    const handleVolume = React.useCallback(()=>{

      let remoteVideos = document.querySelectorAll('.remoteVideo')


      if((volume/100) > 0)
      {
          if(volume>=50)
          {
            setVolumeIcon(<VolumeUp/>)
          }
          else
          {
            setVolumeIcon(<VolumeDown/>)
          }

          for(let i=0; i<remoteVideos.length; i++)
          {
            console.log((volume/100))
            remoteVideos[i].muted = false
            remoteVideos[i].volume = (volume/100)
          }
        }
        else
        {
          setVolumeIcon(<VolumeOff/>)
          for(let i=0; i<remoteVideos.length; i++)
          {
            remoteVideos[i].volume = (volume/100)
            remoteVideos[i].muted = true
          }
      }

    },[volume])

    const toggleVolume=()=>{

      setVolumeOff(!volumeOff)
      // let remoteVideos = document.querySelectorAll('.remoteVideo')
      // console.log(remoteVideos)

      // if(!volumeOff)
      // {
      //   for(let i=0; i<remoteVideos.length; i++)
      //   {
      //     remoteVideos[i].muted = true
      //   }
      //   setVolumeOff(!volumeOff)
      // }
      // else
      // {
      //   for(let i=0; i<remoteVideos.length; i++)
      //   {
      //     remoteVideos[i].muted = false
      //   }
      //   setVolumeOff(!volumeOff)
      // }
    }

    const toggleLock=()=>{
      setLock(!lock)
    }

    const toggleScreen =()=>
    {
        if(handle.active)
        {
          handle.exit()
        }
        else
        {
          handle.enter()
        }
    }

    const LiveBroadcastSelection=(streamId)=>
    {
        try
        {
          var camera = document.querySelector(`[id='${streamId}']`)

          console.log('Got the camera')
          console.log(camera)
        }
        catch(err)
        {
          console.log('Got error with querySelector')
          console.log(err.message)
        }
        if(camera!==null&&camera!==undefined)
        {
            let cam = document.querySelectorAll('.selected')[0]
            console.log(cam)
            console.log(camera)
            console.log(camera.children[0])
            if(cam===undefined)
            {
              console.log('No external camera selected')
            }
    
            if(cam!==null&&cam!==undefined)
            {
              console.log(cam)
              console.log(camera)
              console.log('Changing broadcastLive here')
              cam.classList.remove('selected')
              cam.children[0].classList.remove('selected')
              cam.children[3].classList.remove('broadcastLive')
            }

            console.log('Set the broadcastLive Here')
            camera.classList.toggle('selected')
            camera.children[0].classList.toggle('selected')
            camera.children[3].classList.toggle('broadcastLive')
        }
    }

    const setDeviceInputs=(sourceObj, input, defaultLabel)=>
      {
        if(input==='audioinput')
        {
          setAudioInput(
              <DeviceInputs
                title="Microphone"
                input="audioinput"
                sourceObj={sourceObj}
                defaultLabel={defaultLabel}
                // changeSource={changeAudioSource}
              />
          )
        }
        else if(input==='videoinput')
        {
          console.log('Setting up video input')
  
          setVideoInput(
              <DeviceInputs
                title="Webcam"
                input="videoinput"
                sourceObj={sourceObj}
                defaultLabel={defaultLabel}
                // changeSource={changeVideoSource}
              />
            )
        }
        console.log('Testing')
      }

      const adjustStreamList =React.useCallback((newStream, operation)=>
        {
          console.log(_videoContainer)
          if(_videoContainer===null) { return }
          switch(operation)
          {
            case "addCamera":
              console.log(newStream)
              setStreamList([...streamList,newStream])
              _videoContainer.AddCamera(newStream.streamId, newStream.stream,'remoteVideo')
            break
            case "removeCamera":
              console.log(newStream)
              console.log('Removing the camera in adjust list')
              setStreamList(streamList.filter(stream=>stream.streamId!==newStream.streamId))
              _videoContainer.RemoveCamera(newStream.streamId)
            break
            default:
              console.log('no operation here')
            break
          }
        },[_videoContainer, streamList])

    const initializeWebRTC= React.useCallback(()=>
    {
      if(_videoContainer===null) { return }
      setWebRTCAdaptor(WebRTCInit(webSocketUrl,stream.streamId,stream.streamToken,stream.sessionId, socket, adjustStreamList, setDeviceInputs,stream.position, stream.name))

      socket.emit('getViewers',{'sessionID':stream.sessionId, 'streamID':stream.streamId})

    },[stream, webSocketUrl, adjustStreamList,_videoContainer])

    React.useEffect(()=>{

    },[])

    const initializeVideoContainer = React.useCallback(()=>
    {
      if(_videoContainer===null)
      {
        return
      }

      _videoContainer.AddLocal(stream.streamId, stream.name, stream.position, stream.sessionId)

      _videoContainer.Resize()

      window.addEventListener('resize',()=>{
        _videoContainer.Resize()
      })

      initializeWebRTC()

      socket.on('setViewer',(data)=>{

        console.log(data.viewers)

        setViewer(data.viewers)
      })

      setStreamList([...streamList,`localVideo${stream.streamId}`])

      socket.on('setNewStream',(data)=>{
        
        LiveBroadcastSelection(data.newStream)

        console.log(stream.streamId)

        console.log(data.newStream)

        console.log(stream.streamId===data.newStream)
      })

      socket.on('retrieveStreamId',()=>{
        socket.emit('updateActive',{sessionID:stream.sessionId, streamID:stream.streamId})
      })

      if(stream.position==='interpreter')
      {
        console.log('This is an interpreter getting terplist')
        
      }
    },[_videoContainer, initializeWebRTC, streamList, stream])

    React.useEffect(()=>{
      if(viewer>0)
      {
        setStart(true)
        setNumColor('#84BD00')
        setAnimeName('blinker')
      }
      else
      {
        setStart(false)
        setNumColor('whitesmoke')
        setAnimeName('')
      }
    },[viewer])

    const RetrieveSpotLights=useCallback(()=>{
      console.log('Camera: '+camera)
      if(camera>0)
      {
        console.log('camera updated')
        axios.get(process.env.REACT_APP_LOCALHOST+`/active?sessionId=${stream.sessionId}`)
        .then((resp)=>{
          if(resp.status===200)
          {
            console.log(resp)
            return resp.data
          }
        })
        .then((data)=>{

          if(stream.position==='interpreter')
          {
            console.log(data.results)

            if(data.results.length>0)
            {
              setTerpList(data.results)
            }
          }

          console.log('active')
        })
        .catch((err)=>{
          console.log(err.message)
        })
      }
    },[camera, stream])
    
    React.useEffect(()=>{

      if(webRTCAdaptor===null)
      {
        console.log(webRTCAdaptor)
        console.log('Retrigger')
        initializeVideoContainer()

        RetrieveSpotLights()
      }

    },[RetrieveSpotLights, initializeVideoContainer,webRTCAdaptor])

    React.useEffect(()=>{
      
      console.log(streamList)

      if(streamList.length!==0)
      {
        let remoteVideos = document.querySelectorAll('.remoteVideo')

        for(let i=0; i<remoteVideos.length; i++)
        {
          if(volumeOff)
          {
            remoteVideos[i].muted = true
          }
          else
          {
            remoteVideos[i].muted = false
          }
        }
      }
    },[streamList, volumeOff])

    React.useEffect(()=>{

      if(_videoContainer!==null)
      {
        socket.on('removeCamera',(data)=>{

          console.log('Testing for the leaveHub event')
          console.log('Removing the camera')
          try
          {
            adjustStreamList(data,'removeCamera')
          }
          catch(err){
            console.log('VideoConference.jsx 97')
            console.log(err.message)
          }
          
        })

        // socket.on('addCamera',(data)=>{
        //   console.log('Testing for the joinHub event')
        //   console.log('Adding the camera')
        //   try
        //   {
        //     adjustStreamList(data,'addCamera')
        //   }
        //   catch(err){
        //     console.log('VideoConference.jsx 504')
        //     console.log(err.message)
        //   }
        // })
      }
      else
      {
        socket.off('removeCamera',(data)=>{
          console.log('Testing for the leaveHub event')
          console.log('Removing the camera')
          try
          {
            adjustStreamList(data,'removeCamera')
          }
          catch(err){
            console.log('VideoConference.jsx 97')
            console.log(err.message)
          }
        })

        // socket.off('addCamera',(data)=>{
        //   console.log('Testing for the joinHub event')
        //   console.log('Adding the camera')
        //   try
        //   {
        //     adjustStreamList(data,'addCamera')
        //   }
        //   catch(err){
        //     console.log('VideoConference.jsx 504')
        //     console.log(err.message)
        //   }
        // })
      }
    },[_videoContainer])

    return(
        <>
        <ErrorBoundary fallback={<div>Videoconference is not functioning</div>}>
        <ConferenceContext.Provider value={{terpList,socket, position, LiveBroadcastSelection, setTerpList, stream, webRTCAdaptor, init}}>
        <AppBar position='fixed'
                onMouseEnter={() => setBarOpacity(1)}
                onMouseLeave={() => !lock?setBarOpacity(0):()=>{}}        
                sx={{opacity:barOpacity, maxHeight: '64px', clipPath: 'border-box'}} id='topBar'>
          <Toolbar>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
              <Stack direction='row' spacing={2} alignItems={'center'}  >
                <ButtonGroup>
                  {/* <Button variant='outlined' color='error' sx={{color:'#fff', mr:3}} onClick={backBtn} endIcon={<CallEnd/>}><b>Leave</b></Button> */}
                  <ButtonStyled variant='contained' color='error' action={backBtn} endIcon={<CallEnd/>} title='Leave' />
                </ButtonGroup>
                <Divider orientation="vertical" flexItem />
                <Box display={'flex'} maxHeight={'64px'} >
                  <Typography variant='h5'><b>{stream.title}</b></Typography>
                </Box>
              </Stack>
              <Stack direction='row' ml={1} spacing={1}>
                <Typography variant='h5'>
                  Viewers:
                </Typography>
                <Typography
                variant='h5'
                sx={{
                  color:`${numColor}`,
                  '@keyframes blinker':{
                    '0%':{opacity:1},
                    '100%':{opacity:.2},
                  },
                  animationName:`${animeName}`,
                  animationDuration:'.7s',
                  animationDirection:'alternate',
                  animationIterationCount:'infinite',
                }}>
                  {start?viewer:0}
                </Typography>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>

        <FullScreen handle={handle}>
          <div className='Scene'>

          </div>
        </FullScreen>
        <AppBar 
        position='fixed' 
        sx={{top:'auto',bottom:0,opacity:barOpacity}}
        onMouseEnter={() => setBarOpacity(1)}
        onMouseLeave={() => !lock?setBarOpacity(0):()=>{}} 
        id='bottomBar'>
          <Toolbar>
            <Grid md={12} container sx={{m:0, p:0, mt:2, mb:1}}>
              <Grid item md={6}>
                <Stack direction={'row'} spacing={1}>
                  {audioInput}
                  {videoInput}
                {stream.position==='interpreter' && <TerpList/>}
                </Stack>
              </Grid>
              <Grid md={6} container justifyContent={'end'}>
              <Box>
                <Tooltip title={volumeOff ? 'Turn on sound' : 'Turn off sound'}>
                  <IconButton sx={{backgroundColor:'#84BD00', color:'#fff',m:.5}} onClick={toggleVolume}>{volumeOff?<VolumeOff/>:<VolumeUp/>}</IconButton>
                </Tooltip>
                {/* <Stack spacing={1} direction='row' sx={{mb:0}} alignItems={'center'}>
                  {volumeIcon}
                    <Slider value={volume} onChange={(event, newValue)=>setVolume(newValue)}/>
                </Stack> */}
                <Tooltip title={micMuted ? 'Unmute' : 'Mute'}>
                  <IconButton sx={{backgroundColor:'#84BD00', color:'#fff',m:.5}} onClick={toggleMic}>{micMuted?<MicOffOutlined/>:<MicOutlined/>}</IconButton>
                </Tooltip>
                <Tooltip title={cameraOff ? 'Enable webcam' : 'Disable webcam'}>
                  <IconButton sx={{backgroundColor:'#84BD00', color:'#fff',m:.5}} onClick={toggleCamera}>{cameraOff?<VideocamOffOutlined/>:<VideocamOutlined/>}</IconButton>
                </Tooltip>
                <Tooltip  title={lock ? 'Unlock control panel' : 'Lock control panel'}>
                  <IconButton sx={{backgroundColor:'#84BD00', color:'#fff',m:.5}} onClick={toggleLock}>{lock?<Lock/>:<LockOpen/>}</IconButton>
                </Tooltip>
                <Tooltip  title={'Fullscreen'}>
                  <IconButton sx={{backgroundColor:'#84BD00', color:'#fff',m:.5}} onClick={toggleScreen}>{<ZoomOutMapOutlined/>}</IconButton>
                </Tooltip>
              </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        </ConferenceContext.Provider>
        </ErrorBoundary>
        </>
    )
}

export default VideoConference
