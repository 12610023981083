/********************************************************
 *                                                      *
 *           Created by: ajpccl - AaronJoel Parker      *
 *           Modified by: gvccl - Gabriel Veit          *
 *                                                      *
 ********************************************************/
import * as React from 'react'
import Paper from '@mui/material/Paper';
import { Grid, Typography,Container,Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import dataProcess from '../media/undraw_data_processing_yrrv.png';
import serverCluster from '../media/undraw_server_cluster_jwwq.png';
import undrawProgressive from '../media/undraw_progressive_app_m9ms.png';
import '../styles/icon.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClosedCaptioning, 
  faHandsAmericanSignLanguageInterpreting, 
  faCommentDots } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import '../styles/icon.css';
import Aos from "aos";
import "aos/dist/aos.css";
  
  const imgStyled = {
    width:'auto',
    maxWidth:'100%',
    objectFit:'cover',
    borderRadius:'5px'
  }
  
  
  const classes = {
      root: {
        flexGrow: 1
      },
      paper: {
        padding: 20,
        textAlign: "left"
      }
    };

const Presentation = ()=>
{
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

    return(
        <Container sx={{mb:4}}>
            <Box sx={{display:'flex',justifyContent:'center',textAlign:'center',height:'100%'}}>
                <Stack spacing={3} sx={{mt:10, mb:5, textAlign:'center'}}>
                    <Typography variant='h3'><b>Improving accessibility</b></Typography>
                    <Typography variant='body1' sx={{ fontSize:{md:20, sm:16}}}><b>Access on Demand</b> provides access to live captioning and sign language interpretation any time, anywhere.</Typography>
                </Stack>
            </Box>
            <div style={classes.root}>
      <Box sx={{marginBottom: 10}}>
        <Grid container spacing={2} justify="flex-start" sx={{position: 'relative'}}>
          <Grid item sm={12} md={6} lg={7} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up">
                  <Typography variant='h4'><b>Flexibility</b></Typography>
                    <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                      Access on Demand allows you to choose the type of access service that best fits your needs.
                    </Typography>
                    <Box className="iconBox" sx={{marginTop:5, display: 'flex', flexDirection: 'row', position: 'relative'}}>
                        <Box sx={{height: 1/2, margin: "auto", position: "relative", top: 0, bottom: 0, right: 0}}>
                          <IconButton sx={{float:'left', border: 3, borderColor: 'primary.main', marginRight:2.5, cursor: "default"}}>
                            <FontAwesomeIcon className="icon" icon={faClosedCaptioning}/>
                          </IconButton>
                        </Box>
                        <Box>
                          <Typography variant='h5' sx={{fontSize:{md:24, sm:18}}}>
                          <b>Real-Time Captioning</b>
                          </Typography>
                          <Typography variant='body1' sx={{marginBottom: 1, width:'auto', fontSize:{md:20, sm:16}}}>
                            Connect to real-time captioners any time, anywhere for instant translation of speech to text by trained professionals. All the captioners will need is access to high-speed internet service. They needn’t even be on site!
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="iconBox" sx={{marginTop:5, display: 'flex', flexDirection: 'row', position: 'relative'}}>
                        <Box sx={{height: 1/2, margin: "auto", position: "relative", top: 0, bottom: 0, right: 0}}>
                          <IconButton className="icon-box" sx={{border: 3, borderColor: 'primary.main', marginRight:2.5, cursor: "default"}}>
                            <FontAwesomeIcon className="icon" icon={faHandsAmericanSignLanguageInterpreting} />
                          </IconButton>
                        </Box>
                        <Box>
                          <Typography variant='h5' sx={{marginBottom: 1, fontSize:{md:24, sm:18}}}>
                            <b>Sign Language Interpreting</b>
                          </Typography>
                          <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                          Connect to sign language interpreters anytime and anywhere in the world for instant on-demand access. Our platform livestreams your conversations for interpretation by qualified interpreters working remotely.
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="iconBox" sx={{marginTop:5, display: 'flex', flexDirection: 'row', position: 'relative'}}>
                        <Box sx={{height: 1/2, margin: "auto", position: "relative", top: 0, bottom: 0, right: 0}}>
                          <IconButton className="icon-box" sx={{border: 3, borderColor: 'primary.main', marginRight:2.5, cursor: "default"}}>
                            <FontAwesomeIcon className="icon" icon={faCommentDots} />
                          </IconButton>
                        </Box>
                        <Box>
                          <Typography variant='h5' sx={{marginBottom: 1, fontSize:{md:24, sm:18}}}>
                            <b>Auto Captioning</b>
                          </Typography>
                          <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                          Instantly transcribe audio to text using our state-of-the-art automated speech recognition (ASR) technology. Our ASR service offers live, secure access to accurate, high-quality transcriptions of your meetings, events and conversations.
                          </Typography>
                        </Box>
                      </Box>
                </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={6} lg={5} order={{ xs: 1, sm: 1, md: 2, lg: 2 }} sx={{ 
            height: 1/2,
            margin: "auto",
            position: "relative",
            top: 0, bottom: 0, right: 0,
          }}>
              <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up" sx={{width:'auto'}}>
                  <img src={dataProcess} style={imgStyled} alt='dataProcess'/>
                </Box>  
              </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="flex-start" sx={{
          position: 'relative'
        }}>
          <Grid item sm={12} md={6} lg={7} order={{ xs: 2, sm: 2, lg: 2 }} sx={{ 
            height: 1/2,
            margin: "auto",
            position: "relative",
            top: 0, bottom: 0, right: 0,
          }}>
            <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up">
                  <Typography variant='h4'><b>Cross-platform</b></Typography>
                  <Box sx={{marginTop:5}}>
                    <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                      Access on Demand is entirely web-based; we support all major web browsers and operating systems. 
                    </Typography>
                  </Box>
                  <Box sx={{marginTop:5}}>
                    <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                      We support future-oriented technologies, including Vuzix and Smart Glasses.  
                    </Typography>
                  </Box>
                </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} order={{ xs: 1, sm: 1, lg: 1 }}>
              <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up" sx={{width:'auto'}}>
                  <img src={serverCluster} style={imgStyled} alt='serverCluster'/>
                </Box>  
              </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="flex-start" sx={{
          position: 'relative'
        }}>
          <Grid item sm={12} md={6} lg={7} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} sx={{ 
            height: 1/2,
            margin: "auto",
            position: "relative",
            top: 0, bottom: 0, right: 0,
          }}>
            <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up">
                  <Typography variant='h4'><b>Future-ready</b></Typography>
                  <Box sx={{marginTop:5}}>
                    <Typography variant='body1' sx={{width:'auto', fontSize:{md:20, sm:16}}}>
                      Technology is evolving at a rapid pace, creating new possibilities for tools and interfaces for communication. We support technologies of the future, including smart glasses, and various other leading Mixed Reality (XR) platforms. Stay tuned for more exciting news regarding our service readiness with upcoming technologies. 
                    </Typography>
                  </Box>
                </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={6} lg={5} order={{ sm: 1, md: 2, lg: 2 }} sx={{ 
            height: 1/2,
            margin: "auto",
            position: "relative",
            top: 0, bottom: 0, right: 0,
          }}>
              <Paper style={classes.paper} elevation={0} sx={{boxShadow: 0}}>
                <Box data-aos="fade-up" sx={{width:'auto'}}>
                  <img src={undrawProgressive} style={imgStyled} alt='dataProcess'/>
                </Box>  
              </Paper>
          </Grid>
        </Grid>  
      </Box>    
      </div>
        </Container>
        
    );
}

export default Presentation