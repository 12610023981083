/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import {createSlice} from "@reduxjs/toolkit";

import Cookies from 'js-cookie'

export const mcuSlice = createSlice({
    name:'mcu',
    initialState:{
        name:Cookies.get('name')||'',
        position:Cookies.get('position')||'',
        code:Cookies.get('code')||'',
    },
    reducers:{
        setMCU:(state,action)=>{
            state.name = action.payload.name
            state.position = action.payload.position
            state.code = action.payload.code
            Cookies.set('name',action.payload.name);
            Cookies.set('position',action.payload.position);
            Cookies.set('code',action.payload.code);
        },
    }
})

export const { setMCU } = mcuSlice.actions

export default mcuSlice.reducer