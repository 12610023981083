/*******************************\
* Created By: Nicholas Capadano * 
\*******************************/


import React from 'react'
import { Box, Typography, Grid, Stack, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, CardActions, IconButton, ThemeProvider } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import InfoChip from './InfoChip'
import { QRCodeCanvas } from 'qrcode.react'
import { Access, Category } from '../utilities/Enums'
import { AccessTimeOutlined, ContentCopyOutlined, CalendarMonthOutlined, Cancel, ChevronRight, Close, Comment, CommentOutlined, ContentCopy, CopyAll, ErrorOutlineOutlined, Info, InfoOutlined } from '@mui/icons-material'
import SessionInfoTemplate from './SessionInfoTemplate'
import ButtonStyled from './ButtonStyled'
import '../styles/card.css'
import '../styles/test.css'
import MoreOptionsMenu from './MoreOptionsMenu'
import { SessionCardContext } from '../utilities/Contexts'

const SessionCard = (props) => {

    const [openInfo, setOpenInfo] = React.useState(false);
    const [cardElevation, setCardElevation] = React.useState(3);
    const [isCancelable, setIsCancelable] = React.useState(props.openCancelDialog !== undefined);
    const access = Array.isArray(props.access) ? props.access.map(item => Access[item]).join(', ') : Access[props.access]
    const [menuItems, setMenuItems] = React.useState([])
    const [hover, setHover]=React.useState(false)

    // var menuItems = []

    const handleInfoDialog = () => {
        setOpenInfo(!openInfo)
    }

    console.log(props.status)

    //This function ensures that the card elevation is set back to 4 on mobile
    const handleDialog = (handlerFunction) => {
        handlerFunction();
        hoverLeave();
    }

    const hoverEnter = () => {
        setCardElevation(12)
        setHover(true)
    }

    const hoverLeave = () => {
        setCardElevation(3)
        setHover(false)
    }

    const CopyToClipboard=async()=>{
        await navigator.clipboard.writeText(props.token)
    }

    console.log(props.role)

    const menuSetup=()=>{

        
    }

    React.useEffect(()=>{
        var arr=[]

        if(props.status.label!=='Unreviewed')
        {
            arr.push({action: ()=> {handleDialog(handleInfoDialog)}, title: 'More Info', icon: <InfoOutlined /> })
        }
        arr.push({action: ()=> {}, title: 'Add a Note', icon: <CommentOutlined /> })
        if(props.token && props.role ===6)
        {
            arr.push({action: ()=>{CopyToClipboard()}, title: 'Session Token', icon: <ContentCopyOutlined/>})
        }
        arr.push(isCancelable && {action: ()=> { handleDialog(props.handleCancelDialog) }, title: 'Cancel', icon: <Cancel />, divider: true})

        setMenuItems(arr)

    },[props.status])

    return (
        <>
        <SessionCardContext.Provider value={{hover}}>
        <Card
        className='sessionCard'
        elevation={cardElevation}
        sx={{
            maxWidth: 350,
            width: '100%',
            minWidth: 240,
            m: 2,
            transition: '0.25s',
            border: '1px solid transparent',
            borderRadius: '16px',
            '&:hover': {
                backgroundColor: 'white',
                color: 'black',
                // borderRadius: '12px',
                // border: '1px solid #bfbfbf'
            },

            '&:hover .MuiChip-colorChipGrey': {
                // backgroundColor: lightTheme.palette.chipGrey.main,
                // color: 'black'
            }
        }}
        onMouseEnter={hoverEnter}
        onMouseLeave={hoverLeave}
        >
        <CardContent sx={{ }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                wrap='none'>
                <Grid2 container direction={'column'} alignItems={'flex-start'} width={'100%'} >

                    <Grid2 container display={'flex'} textAlign={'left'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} wrap='none'>
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 'bold',
                                verticalAlign: 'middle',
                                mr: '8px',
                                lineHeight: '1.5',
                                fontSize: '1rem',
                                overflow: 'hidden',       // Ensure that the content that overflows the container is hidden
                                whiteSpace: 'nowrap',     // Prevent the text from wrapping to the next line
                                textOverflow: 'ellipsis', // Add ellipsis when text overflows
                                display: 'none'
                             }}>
                            {props.title}
                        </Typography>

                        <InfoChip
                            color={props.status.color}
                            label={props.status.label}
                            size='small' 
                            tooltip={props.status.explanation}
                            icon={props.status.label === 'Unassigned' ? <ErrorOutlineOutlined /> : null}
                            sx={{
                                // marginLeft: '8px'
                                height: '22px'
                            }}/>
                    </Grid2>

                    <Grid2 container direction={'row'} gap={'5px'} justifyContent={'center'} 
                        sx={{ display: 'none'}}
                    >

                        <Typography
                            variant='body2'>
                            {access}
                        </Typography>
                    </Grid2>
                </Grid2>

                <div style={{height: '32px', width: '35px', display: 'flex', alignItems: 'flex-end'}}>
                    <MoreOptionsMenu menuItems={menuItems} hoverLeave={hoverLeave} />
                </div>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                // sx={{minHeight: '275px'}}
                >

                <Grid2 container flexDirection={'column'} textAlign={'left'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} wrap='none'>
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 'bold',
                                verticalAlign: 'middle',
                                lineHeight: '1.5',
                                fontSize: '1.25rem',
                                overflow: 'hidden',       // Ensure that the content that overflows the container is hidden
                                whiteSpace: 'nowrap',     // Prevent the text from wrapping to the next line
                                textOverflow: 'ellipsis', // Add ellipsis when text overflows
                             }}>
                            {props.title}
                        </Typography>

                        <Grid2 container direction={'row'} gap={'5px'} justifyContent={'center'} sx={{ '& > *': {
                        // fontSize: '14px' 
                        }}}>

                        <Typography
                            variant='body2'
                        >
                            {access}
                        </Typography>
                </Grid2>
                </Grid2>
                <Stack direction='row' sx={{ mt: 1.5, mb: 2 }} flexWrap={'wrap'} gap={1} justifyContent={'center'} >
                    {/* <Chip color='chipGrey' label={Category[props.category]} size='small' /> */}
                    <InfoChip color='chipGrey' label={props.date} size='small' icon={<CalendarMonthOutlined />} />
                    <InfoChip color='chipGrey' label={`${props.startTime} - ${props.endTime}`} size='small' icon={<AccessTimeOutlined />} />
                    {/* <InfoChip color='chipGrey' label={Access[props.access]} size='small' /> */}
                </Stack>
                
                <Box className='raised' sx={{ mx: 'auto', my: 2, width: 140, height: props.role === 7 && 140 }}>
                    {props.role === 7 && <QRCodeCanvas
                        size={140}
                        value={`${props.title}_${props.sessionId}_${props.token}`} />
                    }
                </Box>
                
                <Dialog
                    
                    open={openInfo}
                    onClose={()=> {handleDialog(handleInfoDialog)}}
                    // scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className='moreInfoDialog'>
                    <DialogTitle variant='dialogTitle'>
                        More Info
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        className='closeButton'
                        onClick={()=> {handleDialog(handleInfoDialog)}} >
                        <Close />
                    </IconButton>
                    <DialogContent sx={{ pt: '10px' }}>
                        <SessionInfoTemplate
                            title={props.title}
                            requestNum={props.requestNum}
                            accessType={access}
                            date={props.date}
                            startTime={props.startTime}
                            endTime={props.endTime}
                            organization={props.organization}
                            category={Category[props.category]}
                            status={props.status}
                            description={props.description}
                            notes={props.notes}
                            role={props.role}
                            theme={props.theme} />
                    </DialogContent>
                    <DialogActions>
                        <ButtonStyled action={()=> {handleDialog(handleInfoDialog)}} color={'secondary'} variant='text' title='Edit Request' />
                        <ButtonStyled action={()=> {handleDialog(handleInfoDialog)}} color={'info'} title='OK' />
                    </DialogActions>
                </Dialog>

            </Grid>
        </CardContent>
        <CardActions sx={{ px: 2, pb: 2 }}>
            <Grid
                container
                direction='column-reverse'
                // justifyContent='space-between'
                wrap='nowrap'
                alignItems='center'
                gap='5px'>

                    {props.clipboard}

                    <ButtonStyled
                        title={'Start Streaming'}
                        action={()=> {props.sessionSelected() } }
                        color='primary'
                        variant='contained'
                        boxShadow='none'
                        size='medium'
                        width='100%'/>

                    {isCancelable && <Dialog
                        open={props.openCancelDialog}
                        onClose={
                            ()=> { handleDialog(props.handleCancelDialog) }
                        }
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="deleteRequestDialog">
                        <DialogTitle id="alert-dialog-title">
                            Are you sure?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This cancellation request will be permanent. Please click below to confirm your action.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ButtonStyled variant="outlined" color='secondary' action={() => { handleDialog(props.handleCancelDialog) }} title='Go Back' />
                            <ButtonStyled variant="contained" color='error' action={() => { props.cancelSession() }} autoFocus title='Cancel Request' />
                        </DialogActions>
                    </Dialog>
                    }
            </Grid>
        </CardActions>
    </Card>
    </SessionCardContext.Provider>
    </>
    );
}

export default SessionCard