/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import {Button, Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import { PlayCircleFilled, StopCircle } from '@mui/icons-material'
import {Navigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {io} from 'socket.io-client'

const WebSpeech = ()=>
{

    const [transcript, setTranscript] = React.useState('')
    const [speechRecognition, setSpeechRecognition] = React.useState(null)
    const [listening, setListening] = React.useState(false)
    const [autoRestart, setAutoRestart] = React.useState(true)
    const auth = useSelector(state=>state.auth)
    const stream = useSelector(state=>state.stream)
    const socket = io(process.env.REACT_APP_API_URL)
    
    let finalText =''

    const backBtn = ()=>
    {
        window.location.href='/services/presentation'
    }


    const toggleMic = ()=>
    {
        if(listening){
            speechRecognition.stop()
        }
        else{
            speechRecognition.start()
        }

        setListening(!listening)
    }

    React.useEffect(()=>{
        if(listening)
        {
            console.log(transcript)
            socket.emit('incomingMessage',{message:transcript,room:stream.sessionId})
        }
    },[transcript])

    React.useEffect(()=>{
        if('webkitSpeechRecognition' in window){
            console.log('webkitSpeechRecognition is supported')
            setSpeechRecognition(new window.webkitSpeechRecognition())
        }
    },[])

    React.useEffect(()=>{
        if(speechRecognition){
            speechRecognition.continuous = true
            speechRecognition.interimResults = true
            speechRecognition.lang = 'en-US'
            speechRecognition.onresult = (e)=>{
                let interimTranscript = ''
                for(var i = e.resultIndex; i< e.results.length; i++){
                    var localTranscript = e.results[i][0].transcript;
                    localTranscript.replace('\n','<br>')
                    
                    if(e.results[i].isFinal){
                        finalText += localTranscript
                    }
                    else{
                        interimTranscript += localTranscript
                    }
                    setTranscript(finalText+interimTranscript)
                }
                console.log(finalText+interimTranscript)
                // 
            }
            speechRecognition.onerror = (e)=>{
                console.log(e)
            }
            speechRecognition.onaudiostart = (e)=>{
                console.log(e)
            }
            speechRecognition.onaudioend = (e)=>{
                console.log(e)
            }

            speechRecognition.onend =(e)=>{
                console.log(e)

                if(autoRestart)
                {
                    console.log('Webspeech closed, restarting')
                    speechRecognition.start()
                }
                else
                {
                    console.log('Webspeech closed')
                }
            }
        }
    },[speechRecognition])

    return(
        <>
        {auth.isAuthenticated?
        <Box sx={{backgroundColor:'#343434', height:'100vh'}}>
            <AppBar position='fixed' sx={{opacity:1, m:0, top:0}}>
                <Toolbar>
                <Button sx={{backgroundColor:'#84BD00', color:'#fff'}} onClick={backBtn}>Back</Button>
                    {listening?<Box sx={{
                        '@keyframes blinker':{
                            '0%': {opacity:1},
                            '100%': {opacity:.4},
                    },
                    animationName:'blinker',
                    animationDuration:'1s',
                    animationDirection:'alternate',
                    animationIterationCount:'infinite',
                    display:'flex',
                    justifyContent:'space-evenly',
                    m:2
                    }}><Typography variant='h5'>Listening</Typography><Box sx={{backgroundColor:'red', borderRadius:'50%', width:8, height:8}}></Box></Box>:<></>}
                    
                </Toolbar>
            </AppBar>
            <Box sx={{color:'whitesmoke', p:2, overflow:'hidden', overflowY:'scroll', height:'100%',pt:10, pb:10}}>
            <Typography variant='h5'>
                {transcript}
            </Typography>
            </Box>
            <AppBar position='fixed' sx={{top:'auto',bottom:0}}>
                <Toolbar>
                    <IconButton onClick={toggleMic} sx={{backgroundColor:'#84BD00', color:'#fff'}}>
                        {listening?<StopCircle/>:<PlayCircleFilled/>}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>:<Navigate to='/login' replace={true} />}
        </>
    )
}

export default WebSpeech
