import React from 'react'
import { Box, Typography, Grid, Pagination,Stack, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, CardActions, IconButton } from '@mui/material'
import Template from '../components/Template'
import NewSession from '../components/NewSession'
import { format } from 'date-fns'
import { Navigate } from 'react-router-dom'
import ButtonStyled from '../components/ButtonStyled'
import InfoChip from '../components/InfoChip'
import SessionInfoTemplate from '../components/SessionInfoTemplate'
import SessionCard from '../components/SessionCard'
import AccessButton from '../components/AccessButton'
import SearchBar from '../components/SearchBar'
import CategoryBar from '../components/CategoryBar'
import StatusBar from '../components/StatusBar'
import DateBar from '../components/DateBar'
import Tags from '../components/Tags'
import { lightTheme } from '../themes/theme'
import { useTheme } from "@mui/material/styles";
import { Close, Add, ChevronRight, ErrorOutlineOutlined } from '@mui/icons-material'
import { QRCodeCanvas } from 'qrcode.react'
import { useNavigate } from 'react-router-dom'
import { setStream } from '../slices/stream.slice'
import { Access, Category, Status } from '../utilities/Enums'
import {useSelector, useDispatch} from 'react-redux'
import { setMCU } from '../slices/mcu.slice'
import { useRetrieveSessionsQuery, useCancelSessionMutation } from '../endpoints/member.endpoint'
import { useStreamingSelectedMutation } from '../slices/api.slice'
import { SessionsContext } from '../utilities/Contexts'
import {io} from 'socket.io-client'
import '../styles/card.css'
import '../styles/test.css'

const socket = io(process.env.REACT_APP_LOCALHOST)

//#region Main Sessions
const MySessionsUpgrade = (props) => {
  React.useEffect(() => {
    document.title = 'Requests'
  }, [])

  const auth = useSelector((state) => state.auth)

  return (
    <>{auth.isAuthenticated ?
      <Template>
          <Box sx={{ m: 0, p: 0 ,overflow: "hidden", overflowY: "scroll",}}>
            <DesktopSessions />
          </Box>
      </Template>
      : <Navigate replace to='/' />}</>
  )
}

export default MySessionsUpgrade

//#endregion

//#region DesktopSession
const DesktopSessions = (props) => {

  const [display, setDisplay] = React.useState()
  const [inputText, setInputText] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [sessions, setSessions] = React.useState([])
  const [tag, setTag] = React.useState('Title')
  const [searchBar, setSearchBar] = React.useState(<SearchBar setInputText={setInputText} tag={tag}/>)
  const auth = useSelector((state) => state.auth)
  const [page, setPage] = React.useState(1)
  const [count, setCount]= React.useState(0)
  var globalArr = []
    
  const [collection, setCollection] = React.useState([])
  const [roleId, setRoleId] = React.useState()

  const newSession = () => {
    setOpen(true);
  }

  const filterSessions = () => {
    // if (inputText.length > 0) {
    //   var arr = []
    //   switch (tag) {
    //     case "Title":
    //       arr = collection.filter(row => row.req.sess.title.includes(inputText))
    //       setSessions(arr)
    //       break;
    //     case "Time":
    //       arr = collection.filter(row => row.req.sess.start_time.includes(inputText))
    //       setSessions(arr)
    //       break;
    //     case "Status":
    //       arr = collection.filter(row => Status[row.req.tick_req.tick.bus_ag[0].status_id].label === inputText)
    //       setSessions(arr)
    //       break;
    //     case "Category":
    //       arr = collection.filter(row => Category[row.req.sess.category_id]=== inputText)
    //       setSessions(arr)
    //       break;
    //     default:
    //       console.log('Cannot find any data')
    //       break;
    //   }
    // }
    // else {
      var arr = []

      collection[page-1].map(row=>{
        console.log(arr)
        arr.push(row)
      })

      setSessions(arr)
    // }
  }

  React.useEffect(() => {
    console.log('Firing collection effect!')
    if (collection.length>0) {

      setCount(collection.length)
      filterSessions()
    }
    // else {
    //   var noData = <Typography variant='h3'>You have no request right now.</Typography>
    //   setDisplay(noData)
    // }

  }, [collection, page])

  React.useEffect(()=>{

    socket.on('batch',(data)=>{

        console.log(data.result.subscribed)
        globalArr.push(data.result.subscribed)
        if(data.result.done)
        {
          setCollection(globalArr)
          setCount(globalArr.length)
        }
    })

      socket.emit('sessions',{'token':auth.token})
  },[])

  

  React.useEffect(() => {
    var arr = []
    if(sessions.length>0){
      sessions.map((row, index) => {

        var _session = <SessionGrid key={index} info={row.req} index={row.id}/>
        arr.push(_session)
        return _session
      })

      setDisplay(arr)
    }
    else
    {
      setDisplay(null)
    }
  }, [sessions])

  React.useEffect(()=>{
    switch(tag){
      case "Title":
        setSearchBar(<SearchBar setInputText={setInputText} tag={tag}/>);
        break;
      case "Date":
        setSearchBar(<DateBar setInputText={setInputText} tag={tag}/>);
        break;
      case "Time":
        setSearchBar(<SearchBar setInputText={setInputText} tag={tag}/>);
        break;
      case "Category":
        setSearchBar(<CategoryBar setInputText={setInputText} tag={tag}/>);
        break;
      case "Status":
        setSearchBar(<StatusBar setInputText={setInputText} tag={tag}/>);
        break;
      default:
        console.log('Cannot find any data')
        break;
    }
  },[tag])

  return (
    <SessionsContext.Provider value={{collection, setCollection, filterSessions}}>
      <Box sx={{ minWidth: { md: 660 } }}>
        <Grid container
          direction="row"
          justifyContent={'center'}
          alignItems='flex-start'
          sx={{ mt: 3 }}>
          <ButtonStyled color='primary' action={newSession} variant='contained' sx={{ mt: 1 }} startIcon={<Add />} title='New Request' />
          <Box sx={{ ml: 8 }}>
            {searchBar}
            <Tags setTag={setTag} />
          </Box>
          <NewSession open={open} setOpen={setOpen}/>
        </Grid>
        <Grid container
          direction="column"
          sx={{ justifyContent: { md: 'center', sm: 'center' }, alignItems: { md: 'center', sm: 'center' }, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ mt: 5 }}>Requests</Typography>
          <Typography variant="body1" sx={{ mt: 5 }}>Hover your mouse over the request card to reveal QRCode</Typography>
          <Grid
            container
            direction='row'
            sx={{ direction: 'row', justifyContent: 'center', alignItems: { md: 'center', sm: 'center' }, mb: 3, maxWidth: 1232 }}>
            {display}
          </Grid>
          {count>1 && <Pagination count={count} variant="outlined" shape="rounded" onChange={(e,p)=>setPage(p)} />}
        </Grid>
        <AccessButton theme={props.theme} />
      </Box>
    </SessionsContext.Provider>
  )
}
//#endregion

//#region SessionGrid
const SessionGrid = (props) => {

  const { info } = props

  const status = Status[info?.tick_req.tick.bus_ag[0].status_id]??null

  const [sessionId] = React.useState(`${info.sess.id}`)
  const [open, setOpen] = React.useState(false)
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false)
  const [access, setAccess] = React.useState(`${info.access_id}`)
  const [cardElevation, setCardElevation] = React.useState(4)
  const [startTime, setStartTime] = React.useState(format(new Date(info.sess.start_time), "hh:mm aaaaa'm'"))
  const [endTime, setEndTime] = React.useState(format(new Date(info.sess.end_time), "hh:mm aaaaa'm'"))
  const [date, setDate] = React.useState(format(new Date(info.date), 'MM/dd/yyyy'))
  const theme = useTheme();
  const [isPending, startTransition] = React.useTransition()
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const {collection, setCollection} = React.useContext(SessionsContext)
  const [cancelSession] = useCancelSessionMutation() 
  const [streamingSelected] = useStreamingSelectedMutation()

  const history = useNavigate()

  //
  const handleDialog = () => {
    setOpen(!open)
  }

  //
  const hoverEnter = () => {
    setCardElevation(12)
  }

  //
  const hoverLeave = () => {
    setCardElevation(4)
  }

  const handleCancelDialog = () => {
    setOpenCancelDialog(!openCancelDialog)
  }

  const CancelSession = () => {

    cancelSession({token: auth.token, request: info.id})
    .then((res) => {
       setCollection(collection.filter(row => row.id!== props.index))
       handleCancelDialog()
     })
     .catch((err) => {
       console.log(err.message)
       handleCancelDialog()
     })
  }

  console.log(info.sess.title)
  console.log(sessionId)
  console.log(auth.token)

  const sessionSelected=()=>
      {
        console.log(info.sess.id)
          streamingSelected({token: auth.token, sessionId: info.sess.id})
          .then((res) => {
              console.log(res.data)
              if(res.data.status > 0)
              {
                dispatch(setStream({
                  name:res.data.name, 
                  position:res.data.position, 
                  sessionTitle:info.sess.title, 
                  streamId: res.data.result.streamId, 
                  sessionId: res.data.result.roomId, 
                  streamToken: res.data.result.tokenId
                }))

                startTransition(()=>{history('/broadcast')})

              }
           })
      }

  return (
    
    <SessionCard 
        status={status}
        title={info.sess.title}
        category={info.sess.category_id}
        access={access}
        token={auth.token}
        sessionId={sessionId}
        role={auth.role}
        requestNum={info.number}
        date={date}
        startTime={startTime}
        endTime={endTime}
        organization={info.bus.name}
        description={info.sess.description}
        notes={info.sess.notes}
        theme={theme}
        sessionSelected={sessionSelected}
        openCancelDialog={openCancelDialog}
        handleCancelDialog={handleCancelDialog}
        cancelSession={CancelSession}
    />
  )
}
//#endregion


