/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import Cookies from 'js-cookie'

import { WebRTCAdaptor } from './webrtc_adaptor'

var maxVideoBitrateKbps = 200;

    const autoRepublishEnabled = false;

    const publishImmediately = true;

    var mediaVideo = null;
    /**
     * Timer job that checks the WebRTC connection 
     */
    var autoRepublishIntervalJob = null;

    var pc_config = {
        'iceServers' : [ {
            'urls' : 'stun:stun1.l.google.com:19302'
        } ]
    };




    var sdpConstraints = {
        OfferToReceiveAudio : true,
        OfferToReceiveVideo : true
    };

    let toggle = true;
  
    

export const WebRTCInit = (webSocketURL,streamId, token, sessionId, socket, adjustStreamList, setDeviceInputs,position,name)=>
{
    if(position==='captioner')
    {
        mediaVideo = false
        console.log('It is captioner')
        console.log(position)
    }
    else
    {
        mediaVideo = true
    }
    
    var mediaConstraints = {
        video : true,
        audio : true
    };

    var webRTCAdaptor = new WebRTCAdaptor({
        websocket_url : webSocketURL,
        mediaConstraints : mediaConstraints,
        peerconnection_config : pc_config,
        sdp_constraints : sdpConstraints,
        localVideoId : 'localVideo'+streamId,
        remoteVideoId:'remoteVideo',
        debug:true,
        bandwidth:maxVideoBitrateKbps,
        callback : (info, obj) => {

                // console.log(obj)
                console.log('Stream ID: '+streamId)
                console.log('Session ID: '+sessionId)
            if (info === "initialized") {
                console.log("initialized");
                if (publishImmediately) {
                  webRTCAdaptor.publish(streamId, token)
                  webRTCAdaptor.joinRoom(sessionId,streamId)
                    console.log('sending the MSG')

                    socket.emit('startPublish',{"sessionID":sessionId,"streamID":streamId,"toggled": toggle,'role':Cookies.get('role')})
                    socket.emit('joinStream',{"sessionID":sessionId,"streamID":streamId,"name":name, "position":position})

                }
                
            } 
            else if(info === 'joinedTheRoom'){

              if(obj.streams != null){

                  console.log('Object Stream Length '+obj.streams.length)
                  obj.streams.forEach((item)=>{
                      console.log('Stream joined with ID: '+item)

                       webRTCAdaptor.play(item, token, sessionId)

                  })
              }
            //   videoContainer._streamsList = obj.streams
              webRTCAdaptor.getRoomInfo(sessionId, obj.streamId)
          }
            else if (info === "publish_started") {
                //stream is being published
                console.log("publish started");

                // if (autoRepublishEnabled && autoRepublishIntervalJob == null) 
                // {
                //     autoRepublishIntervalJob = setInterval(() => {
                //         // checkAndRepublishIfRequired();
                //     }, 3000);
                // }
                webRTCAdaptor.enableStats(obj.streamId);
            } else if (info === "publish_finished") {
                //stream is being finished
                console.log("publish finished");

                try
                {
                    adjustStreamList(obj,'removeCamera')
                }
                catch(err)
                {   console.log('WebRTCInit.jsx 121')
                    console.log(err.message)
                }
            }
            else if (info === "closed") {
                //console.log("Connection closed");
                if (typeof obj != "undefined") {
                    console.log("Connecton closed: " + JSON.stringify(obj));
                }
            }
            else if (info === "pong") {
                //ping/pong message are sent to and received from server to make the connection alive all the time
                //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
            }
            else if (info === "refreshConnection") {
                // checkAndRepublishIfRequired();
            }
            else if (info =='newStreamAvailable'){
              console.log('Got info!')
              
              let camera = document.getElementById(obj.streamId)

              console.log('Camera: '+ camera)

              if(camera === null)
              {
                adjustStreamList(obj,'addCamera')
              }

              console.log(`New User: ${obj.streamId} join room at ${sessionId}.`)
            }
            else if(info === 'streamInformation'){

               webRTCAdaptor.play(obj.streamId, token, sessionId)
            }
            else if(info === 'roomInformation'){
                var tempRooms = Array()

                console.log("Checking object's stream")
                console.log(obj.streams)

                if (obj.streams != null){
                    obj.streams.forEach((item)=>{
                        console.log('Stream joined with ID: '+item)
                        tempRooms.push(item)
                         webRTCAdaptor.play(item,token, sessionId)
                        let camera = document.getElementById(item)
                    })
                }
            }
            else if (info === 'play_finished')
            {  
                try
                {
                    console.log('WebRTCInit.jsx 177')
                    // adjustStreamList(obj,'removeCamera')
                }
                catch(err)
                {
                    console.log(err.message)
                }
            }
            else if (info === "available_devices") {
                console.log("Available Devices: ");
                console.table(obj)
                var videoHtmlContent = "";
                var audioHtmlContent = "";
                var videoDevices = []
                var audioDevices = []
                console.log(webRTCAdaptor.getLocalVideoTracks()[0].label)
                obj.forEach(function(device) {
                    if (device.kind === "videoinput") {
                        videoDevices.push(device)
                        // videoHtmlContent += getCameraRadioButton(device.label, device.deviceId);
                    }
                    else if (device.kind === "audioinput"){
                        audioDevices.push(device)
                        // audioHtmlContent += getAudioRadioButton(device.label, device.deviceId);
                    }
                }); 

                setDeviceInputs(videoDevices, 'videoinput',webRTCAdaptor.getLocalVideoTracks()[0].label)
                setDeviceInputs(audioDevices, 'audioinput', webRTCAdaptor.getLocalAudioTracks()[0].label)
                // $(videoHtmlContent).insertAfter(".video-source-legend");
                // $(".video-source").first().prop("checked", true);	
                
                // $(audioHtmlContent).insertAfter(".audio-source-legend");
                // $(".audio-source").first().prop("checked", true);	

                if (document.querySelector('input[name="videoSource"]')) {
                    document.querySelectorAll('input[name="videoSource"]').forEach((elem) => {
                        elem.addEventListener("change", function(event) {
                        var item = event.target;
                        // switchVideoMode(item)
                        });
                      });
                }
                if (document.querySelector('input[name="audioSource"]')) {
                    document.querySelectorAll('input[name="audioSource"]').forEach((elem) => {
                        elem.addEventListener("change", function(event) {
                        var item = event.target;
                        // switchAudioMode(item)
                        });
                      });
                }
            }
            else {
                console.log( info + " notification received");
            }
        },
        callbackError :(error, message)=> {
            //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
            window.parent.postMessage('interpreter_callback_error', '*');

            console.log("error callback: " +  JSON.stringify(error));
            var errorMessage = JSON.stringify(error);
            if (typeof message != "undefined") {
                errorMessage = message;
            }
            var errorMessage = JSON.stringify(error);
          //   if (error.indexOf("NotFoundError") != -1) {
          //       errorMessage = "Camera or Mic are not found or not allowed in your device";
          //   }
            if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
                errorMessage = "Camera or Mic is being used by some other process that does not let read the devices";
            }
            else if(error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
                errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
            }
            else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
                errorMessage = "You are not allowed to access camera and mic.";
            }
            else if (error.indexOf("TypeError") != -1) {
                errorMessage = "Video/Audio is required";
            }
            else if (error.indexOf("ScreenSharePermissionDenied") != -1) {
                errorMessage = "You are not allowed to access screen share";
                                   
            }
            else if(error.indexOf('no_stream_exist') != -1){
                console.log('Error at StreamID: '+error.streamId)
                try
                {
                    // adjustStreamList(error,'removeCamera')
                }
                catch(err)
                {
                    console.log(err.message)
                }
                
            }
            else if (error.indexOf("WebSocketNotConnected") != -1) {
                errorMessage = "WebSocket Connection is disconnected.";
            }
            alert(errorMessage);
        }
    });

    return webRTCAdaptor
}
