/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Overview from '../pages/Overview'
import Events from '../pages/Events'
import Help from '../pages/Help'
import AboutUs from '../pages/AboutUs'
import LogIn from '../pages/LogIn'
import MySessions from '../widgets/MySessions'
import Profile from '../pages/Profile'
import Register from '../pages/Register'
import ForgotPassword from '../pages/ForgotPassword'
import ForgotUsername from '../pages/ForgotUsername'
import PasswordReset from '../pages/PasswordReset'
import MySessionsUpgrade from '../widgets/MySessionsupgrade'

const Home = ()=>
{
  return (
        <Routes>
          <Route path='/' element={<Overview/>}/>
          <Route path='/events' element={<Events/>}/>
          <Route path='/about' element={<AboutUs/>}/>
          <Route path='/login' element={<LogIn />}/>
          <Route path='/myrequests' element={<MySessions />}/>
          {/* <Route path='/myrequestsupgrade' element={<MySessionsUpgrade/>}/> */}
          <Route path='/profile' element={<Profile />}/>
          <Route path='/registration' element={<Register />}/>
          <Route path='/forgotPass' element={<ForgotPassword />}/>
          <Route path='/forgotUser' element={<ForgotUsername />}/>
          <Route path='/reset' element={<PasswordReset />}/>
          <Route path='/help'element={<Help />}/>
        </Routes>
  );
}

export default Home