/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import { InputAdornment,TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import {Search} from '@mui/icons-material'
import {Status } from '../utilities/Enums'

const SearchBar = (props)=>
{
    // const [tag, setTag] = React.useState(||'')
    const [dropValue, setDropValue] = React.useState()

    let inputHandler = (e)=>
    {
        if(e.key === 'Enter')
        {
            var valueInput = e.target.value
            props.setInputText(valueInput)
        }
    }

    let dropHandler = (e)=>{

        setDropValue(e.target.value)

        props.setInputText(Status[e.target.value].label)
    }

    /*Unfinished code but it will not cause any bugs, 
    and will need to be implemented with 
    global state value via Redux.*/
    return (
        <>{props.tag!=='Status'?<TextField
            sx={{width:'15em', borderRadius:7}}
            label='Search'
            onKeyDown={inputHandler}
            InputProps={{
                startAdornment:(
                <InputAdornment position='start'>
                <Search/>
            </InputAdornment>
            )
            }} variant='outlined'/>:
            <FormControl sx={{width:'15em'}}>
                <InputLabel>Status</InputLabel>
                <Select
                value={dropValue}
                onChange={dropHandler}
                label='Status'>
                    <MenuItem value={1}>Unreviewed</MenuItem>
                    <MenuItem value={2}>Pending</MenuItem>
                    <MenuItem value={4}>Assigned</MenuItem>
                    <MenuItem value={5}>Unassigned</MenuItem>
                    <MenuItem value={6}>Cancelled</MenuItem>
                </Select>
            </FormControl>}
            </>
    )
}

export default SearchBar