/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: njcdhb - Nicholas Capadano           *
 *                                                      *
 ********************************************************/

import {Button, Typography} from '@mui/material'
import React from 'react'

const ButtonStyled = (props)=>
{
    return <Button
        variant={props.variant || 'contained'}
        size={props.size || 'large'}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        color={props.color || 'primary'}
        href= {props.href}
        disabled={props.disabled}
        onClick={props.action || props.onClick}
        sx={{
                // color:'#fff',
                // background: props.gradient || 'linear-gradient(47deg,#84BD00,#C4D600)',
                // border:0,
                color:props.textColor,
                backgroundColor:props.backgroundColor||'primary',
                fontFamily: 'sans-serif',
                fontWeight:'bold',
                marginTop: props.marginTop || 0,
                width: props.width || 'auto',
                boxShadow: props.boxShadow || 'none',
                '&:hover':{
                    // boxShadow: props.boxShadow || 'none'
                },
                textTransform: 'Capitalize',
                borderRadius: props.borderRadius||'22px',
                ...props.sx
        }}
        >
            {props.title}
    </Button>
        
}

export default ButtonStyled