import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
    baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_LOCALHOST+'/'}),
    reducerPath:'api',
    endpoints:build=>({
        streamingSelected:build.mutation({
            query:({token,sessionId})=>({
                url:`/token/?sessId=${sessionId}&id=${token}`,
                method:'GET',
            }),
        }),
        retrieveOrgs:build.query({
            query:token=>'/orgs',
        }),
        retrieveActiveTerps:build.query({
            query:sessionId=>`/active/?sessionId=${sessionId}`,
        }),
    })
})

export const {
    useStreamingSelectedMutation, 
    useRetrieveOrgsQuery, 
    useRetrieveActiveTerpsQuery} = apiSlice
