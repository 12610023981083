/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import { Typography,Box, TextField, Fade} from '@mui/material'
import Template from '../components/Template'
import ButtonStyled from '../components/ButtonStyled'
import ToggleSwitch from '../components/ToggleSwitch'
import axios from 'axios'


const ForgotUsername=()=>
{
    const [email, setEmail] = React.useState('')
    const [opacity, setOpacity] = React.useState(0)
    const [scale, setScale] = React.useState('scale(1.0)')
    const [show, setShow] = React.useState(false)
    const [color, setColor] = React.useState('primary')
    const [msg ,setMsg] = React.useState('')
    const [header, setHeader] = React.useState('subtitle1')

    function onEmailChange(e)
    {
        setEmail(e.target.value)
    }

    const sendPasswordReset=()=>
    {
        if(email !== '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            axios.get(process.env.REACT_APP_LOCALHOST+`/member/forgotUser/?email=${email}`)
            .then((res)=>{
                console.log(res.data)
                setEmail('')
                setShow(true)
                setColor('primary')
                setHeader('subtitle1')
                setMsg('If the email you provided is in our system, you will retrieve your username shortly.')
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        else
        {
            NeedEmailAddress()
        }
    }

    const NeedEmailAddress=()=>
    {
        setShow(true)
        setColor('red')
        setHeader('h5')
        setMsg('Invalid Email Address')

        setTimeout(()=>{
            setShow(false)
        },6000)
    }

    React.useEffect(()=>{
        document.title='Password Reset'
    },[])

    return(
        <Template>
            <Box sx={{p:'auto', height:'100vh'}}>
                <Box sx={{textAlign:'center'}}>
                    <Typography variant='h2' sx={{m:2}}>Forgot username?</Typography>
                    <Box>
                        <Typography variant='body1' sx={{m:2}}>Don't worry, just type in the email you registered the account with. We will send you your username</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        '& .MuiTextField-root': { m: 1},
                        textAlign:'center',
                        m:'auto',
                        p:3,
                        borderRadius:5,
                        width:350,
                        borderColor:'#84BD00',
                        alignContent:'center'
                    }}
                    noValidate>
                        <TextField
                        required
                        value={email}
                        color='success'
                        id='outlined-required'
                        label='Email Address'
                        placeholder='Enter Email Address'
                        size='large'
                        fullWidth
                        onChange={onEmailChange}/>
                        <ButtonStyled action={sendPasswordReset} title='Send Email' marginTop={2}/>
                    </Box>
                    <Fade in={show}>
                        <Typography variant={header} sx={{color:color,transform:scale, textAlign:'center', m:1}}>{msg}</Typography>
                    </Fade>
                <ToggleSwitch />
            </Box>
        </Template>
    )
}

export default ForgotUsername