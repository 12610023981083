import {apiSlice} from '../slices/api.slice'

const agencyApi = apiSlice.injectEndpoints({
    endpoints:build=>({
        retrieveTickets:build.query({
            query:token=>`/coordinator/tickets/?id=${token}`,
            providesTags:['Coord Tickets']
        }),
        retrieveApproved:build.query({
            query:token=>`/coordinator/approved/?id=${token}`,
            providesTags:['Coord Tickets']
        }),
        assignTranslators:build.mutation({
            query:translators=>({
                url:`/coordinator/assign`,
                method:'POST',
                body:translators
            }),
            invalidatesTags:['Coord Tickets','Org Events'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            }
        }),
        rejectRequest:build.mutation({
            query:({token,ticket})=>({
                url:`/coordinator/reject/?id=${token}&ticket=${ticket}`,
                method:'GET',
            }),
            invalidatesTags:['Coord Tickets'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            }
        }),
        reassignment:build.mutation({
            query:translators=>({
                url:`/coordinator/update`,
                method:'POST',
                body:translators
            }),
            invalidatesTags:['Coord Tickets','Org Events'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            }
        }),
        retrieveTranslators:build.query({
            query:token=>`/translators?id=${token}`,
            providesTags:['Translators']
        }),
        addTranslator:build.mutation({
            query:translator=>({
                url:'/translator',
                method:'POST',
                body:translator
            }),
            invalidatesTags:['Translators'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            }
        })
    })
})

export const {
    useRetrieveTicketsQuery,
    useRetrieveApprovedQuery,
    useAssignTranslatorsMutation,
    useRejectRequestMutation,
    useReassignmentMutation,
    useRetrieveTranslatorsQuery,
    useAddTranslatorMutation
} = agencyApi
