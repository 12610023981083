

import { createSlice } from '@reduxjs/toolkit'

import Cookies from 'js-cookie'

export const naviSlice = createSlice({

    name:'navi',
    initialState:{
        selectedIndex:parseInt(Cookies.get('selectedIndex'))||0,
    },
    reducers:{
        IndexSelected:(state,action)=>{
            state.selectedIndex=action.payload.index
            Cookies.set('selectedIndex',action.payload.index)
        },
        IndexRemove:()=>{
            Cookies.remove('selectedIndex')
        }
    }
})

export const {IndexSelected, IndexRemove} = naviSlice.actions

export default naviSlice.reducer