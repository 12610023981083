/********************************************************
 *                                                      *
 *    Created by: njcdhb - Nicholas Capadano           *
 *                                                      *
 ********************************************************/

import { Button, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import InfoChip from '../components/InfoChip'
import { AccessTimeOutlined, BusinessOutlined, CalendarMonthOutlined, EmailOutlined, ErrorOutlineOutlined, Person, LocationOnOutlined } from '@mui/icons-material'
import { Status } from '../utilities/Enums'


const SessionInfoTemplate = (props) => {
    
    const theme = useTheme()

    return <Grid2 container direction={'column'} gap={'16px'} className='sessionInfo' wrap='nowrap' >

        <Grid2 container gap={'16px'} className='sessionInfoPart1' alignContent={'flex-start'} direction={'column'}
            // flex={'0 1 720px'} 
            maxWidth={'720px'}
            width={'100%'}>

            {/* Title, RequestNum, and AccessType */}
            <Grid2 container direction={'column'} gap={'0px'}>

                <Grid2 container direction={'row'} gap={'5px'} pb={'4px'}>
                    <Typography
                        variant='sessionTitle'
                        sx={{
                            textWrap: 'wrap',
                            wordBreak: 'break-word'
                        }}
                    >
                        {props.title}
                    </Typography>
                </Grid2>

                {props.eventTitle &&
                    <Grid2 container direction={'row'} gap={'5px'}>
                    <Typography variant='subtitle2'>
                        Event:
                    </Typography>
                    <Typography
                        variant='body1'>
                        {props.eventTitle}
                    </Typography>
                </Grid2>
                }

                {props.requestNum &&
                    <Grid2 container direction={'row'} gap={'5px'}>
                        <Typography variant='subtitle2'>
                            Request Number:
                        </Typography>
                        <Typography
                            variant='body1'
                        >
                            {props.requestNum}
                        </Typography>
                    </Grid2>
                }

                {props.accessType &&
                    <Grid2 container direction={'row'} gap={'5px'}>
                        <Typography variant='subtitle2'>
                            Access Type:
                        </Typography>
                        <Typography
                            variant='body1'>
                            {props.accessType}
                        </Typography>
                    </Grid2>
                }


            </Grid2>

            {/* InfoChips */}
            <Grid2 container display={'inline-flex'} gap={'8px'}>
                <InfoChip tooltip="Date" label={props.date} size='medium' icon={<CalendarMonthOutlined />} />
                <InfoChip tooltip="Time" label={`${props.startTime} - ${props.endTime}`} size='medium' icon={<AccessTimeOutlined />} />
                {props.organization &&
                    <InfoChip tooltip={props.role === '5' ? 'Client Organization' : 'Organization'} label={props.organization} size='medium' icon={<BusinessOutlined />} />
                }
                {/* temporary */}
                {props.clientName &&
                    <InfoChip tooltip={
                        props.clientName === 'Manager William Smith' ? 'Manager Name' : "Client Name"
                        // "Client Name"
                    } label={props.clientName} size='medium' icon={<Person />} />
                }
                {/* temporary */}
                {props.clientEmail &&
                    <InfoChip tooltip={
                        props.clientEmail === 'hospital_mgr@outlook.com' ? 'Manager Email' : "Client Email"
                        // "Client Email"
                    } label={props.clientEmail} size='medium' icon={<EmailOutlined />} />
                }
                {props.category &&
                    <InfoChip tooltip="Category" label={props.category} size='medium' />
                }
                {props.location &&
                    <InfoChip tooltip="Location" label={props.location} size='medium' icon={<LocationOnOutlined />} />
                }
            </Grid2>
        </Grid2>

        <Grid2 container gap={'16px'} className='sessionInfoPart2' alignContent={'flex-start'}
            flex={'0 1 70%'} maxWidth={'720px'}
        // maxWidth={'100%'}
        // width={'auto'}
        >
            {/* Status */}
            {props.status && <Grid2 container className='statusContainer' direction={'column'} p={'10px'} borderRadius={'5px'} gap={'5px'} width={'100%'} sx={{
                backgroundColor: theme.palette.chipGrey.main,
            }}>
                <Grid2 container direction={'row'} gap={'10px'} alignItems={'center'}>
                    <Typography variant='subtitle2'>
                        Request Status:
                    </Typography>
                    <InfoChip color={props.status.color} sx={props.status.label === 'Unreviewed' && {border: '1px solid #bdbdbd'}} label={props.status.label} size='small' icon={props.status.label === 'Unassigned' ? <ErrorOutlineOutlined /> : null} />
                </Grid2>

                <Typography variant='body1'>
                    {props.status.explanation}
                </Typography>
            </Grid2>}

            {/* Description and Notes */}
            <Grid2 container direction={'column'} gap={'16px'}>


                {/* temporary */}
                { props.coordClients &&
                    <Grid2 container direction={'column'}>
                        <Typography variant='subtitle2'>
                            Clients
                        </Typography>

                        {
                            props.coordClients.map((coordClient, index)=>{
                                return <Typography
                                variant='body1' >
                                        {`${coordClient.first_name} ${coordClient.last_name}`}
                                    </Typography>
                            })
                        }
                        
                    </Grid2>
                }
                {props.description && <Grid2 container direction={'column'}>
                    <Typography variant='subtitle2'>
                        Description
                    </Typography>
                    <Typography
                        variant='body1' >
                        {props.description}
                    </Typography>
                </Grid2>}

                {/* temporary */}
                {/* {props.notes && */}
                { props.status && props.status.label === 'Unassigned' &&
                <Grid2 container direction={'column'}>
                    <Typography variant='subtitle2'>
                        Notes
                    </Typography>
                    <Typography variant='body1'>
                        {props.notes || '- The time requested is not possible to be fulfilled. Please update the start and end times'}
                    </Typography>
                </Grid2>
                }
            </Grid2>
        </Grid2>
    </Grid2>

}

//{props.notes && 'Please make the end time earlier for this request to be assigned (notes example)'}

export default SessionInfoTemplate