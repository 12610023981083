/*****************************************************
*                                                    *
*           Created by gvccl - Gabriel Veit          *
*                                                    *
******************************************************/


import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import '../styles/table.css';
import {Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import InfoChip from '../components/InfoChip';
import {useSelector} from 'react-redux'
import { AssignTranslatorsContext } from '../utilities/Contexts';
import { useContext } from 'react';
import { Access } from '../utilities/Enums'
import { getUnixTime } from 'date-fns';

let counter = 0;

const ListOfTranslators = (props) => {

  // const { request, accessType, setList } = props;

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [listTexts, setListTexts] = React.useState([]);
  const [translators, setTranslators] = React.useState([])
  const [selectId, setSelectId]=React.useState(null)
  const [selectionModel, setSelectionModel] = React.useState()
  const auth = useSelector((state) => state.auth)
  // const [access, setAccess] = React.useState(request.access_id || accessType)
  const {request, access, setList, businessAgreementId } = useContext(AssignTranslatorsContext)


  const availability = {'Available':'covered', 'Not Available':'error', 'Assigned':'covered'}

  // const assignedRow = React.useCallback((translatorId)=>{
  //   setTranslators(translators=>translators.map((translator)=>translator.id===translatorId?{...translator,availability:newAvailability}:translator))
  // },[])

const columns = [
  // { field: 'id', headerName: 'ID', width: 100 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    width: 220,
  },
  {
    field: 'availability',
    headerName: 'Availability',
    width: 150,
    renderCell: (params) => {
      // temporary
      return <InfoChip label={params.value} color={availability[params.value]} size='small' />;
    },
    type: 'singleSelect',
    valueOptions: [
      {value: 'Available', label: 'Available'},
      {value: 'Unavailable', label: 'Unavailable'}
    ]
  }]

  const AppendListTexts=React.useCallback(()=> {
    var arr = []

    var arrJson = []

    selectedRows.map((row) => {

      var list = <ListItem key={row.id} disablePadding><ListItemText primary={`• ${row.firstName} ${row.lastName}`} /></ListItem>

      var json = { translator_id: row.id, business_agreement_id: businessAgreementId }

      arr.push(list)
      arrJson.push(json)
      return arrJson
    })
    
    setList(arrJson)

    setListTexts(arr)

  },[selectedRows, request, setList])

  console.log('Access: '+access)
  console.log('Auth: '+auth.token)
  console.log('RequestId: '+request.id)
  console.log(businessAgreementId)

  const InitializeList=React.useCallback(()=>{
    if(access)
    {
      axios.get(process.env.REACT_APP_LOCALHOST + `/translators/?id=${auth.token}&accessId=${access}&requestId=${request.id}&businessAgreementId=${businessAgreementId}`)
      .then((res) => {

        if (res.status === 200) {

          return res.data
        }
      })
      .then((data) => {

        var arr = []

        //temporary: to make every third translator unavailable

        data.result.translators.map((_translator) => {

          let status

          console.log(data)
          console.log(_translator)

          const scheduledStartTime = getUnixTime(new Date(data.result.request.sess.start_time))

          const scheduledEndTime = getUnixTime(new Date(data.result.request.sess.end_time))

          if(_translator.serv.length>0)
          {
            let flag = true
            _translator.serv.map((service, index)=>{

              if(flag)
              {
                let bookedStartTime = getUnixTime(new Date(service.bus_ag.req.sess.start_time))

                let bookedEndTime = getUnixTime(new Date(service.bus_ag.req.sess.end_time))

                if((bookedEndTime < scheduledStartTime) || (bookedStartTime>scheduledEndTime))
                {
                  status = 'Available'
                }
                else
                {
                  if(data.result.request.id!==service.bus_ag.req.id)
                  {
                    status='Not Available'
                  }
                  else
                  {
                    status='Assigned'
                  }
                  flag = false
                }
              }
            })
          }
          else
          {
            status='Available'
          }

          arr.push({ id: _translator.id, lastName: _translator.mem.last_name, firstName: _translator.mem.first_name, email: _translator.mem.email, availability: status })
          
          return arr
        })
        
        setTranslators(arr)
        setSelectionModel(data.result.services.map(service => service.translator_id))
      })
      .catch((err) => {
        console.log(err.message)
      })
    }
  },[auth.token, request.id, request.bus_ag, access])

  React.useEffect(() => {

    InitializeList()

  }, [InitializeList])

  React.useEffect(()=>{

    if(selectId===null){ return;}
    const selectedRows = translators.filter((r) => selectId.has(r.id))
    setSelectedRows(selectedRows)
    // updateRow(selectId,'Assigned')

  },[selectId, translators])

  React.useEffect(()=>{
    setSelectId(new Set(selectionModel))
  },[selectionModel])

  React.useEffect(() => {
    AppendListTexts()
  }, [AppendListTexts])

  return (
    <Grid2 container sx={{ pt: 2, pb: '20px', m: '0' }}>
      <Grid2 xs={12}>
        <Typography variant='subtitle2' sx={{ textTransform: 'capitalize' }}>
          {`${Access[access]}`}
        </Typography>
        <div style={{ width: '100%' }}>
          <DataGridPro
            checkboxSelection
            pagination
            rows={translators}
            columns={columns}
            isRowSelectable={(params)=>params.row.availability!=='Not Available'}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            autoHeight
            pageSizeOptions={[5, 10, 100]}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(e) => {
              console.log('Firing a model change')
              setSelectionModel(e)
            }} />
          <List sx={{ marginTop: '10px', marginBottom: '20px' }}>
            <Typography>
              <b>Selected:</b>
            </Typography>
              {listTexts}
          </List>
        </div>
      </Grid2>
    </Grid2>
  );
}

export default ListOfTranslators

