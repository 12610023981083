/******************************************************\
*                                                      *
*    Created by: ajpccl - AaronJoel Parker             *
*                                                      *
\******************************************************/

const Status = {
    1:{label:'Unreviewed',color:'chipGrey',explanation:'This request is waiting to be reviewed by a manager.'},
    2:{label:'Pending',color:'warning', explanation:'The request has been reviewed and is waiting to be assigned.'},
    3:{label:'Pending', color:'warning', explanation:'The request has been reviewed and is waiting to be assigned.'},
    4:{label:'Assigned',color:'covered', explanation:'This request has been assigned.'},
    5:{label:'Unassigned',color:'error', explanation:'This request was not able to be assigned to a translator. Automatic captioning is available'},
    6:{label:'Cancelled',color:'error', explanation:'Please check the note for an explanation from the manager.'},
}

const Access = { 
    1: 'Interpreter', 
    2: 'Captioner', 
    3: 'Specialist' 
}

const Role = {
    3:'Manager',
    4:'Presenter',
    5:'Coordinator',
    6:'Service Provider',
}

const Category = { 
    1: 'medical', 
    2: 'class', 
    3: 'sport', 
    4: 'concert', 
    5: 'meeting', 
    6: 'conference', 
    7: 'other'
}

export {Status, Access, Category, Role};