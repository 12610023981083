/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import * as React from 'react'

import { Fab, Fade } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'


const ActionButton = ()=>
{
    const [up, setUp] = React.useState(false)

    let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    )

    window.onscroll=(()=>{

        if((scrollHeight/2) < window.scrollY)
        {
            setUp(true)
        }
        else
        {
            setUp(false)
        }
    })

    const jumpToTop = (event) =>{
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if(c > 0){
            window.requestAnimationFrame(jumpToTop);
            window.scrollTo(0, c - c / 3);
        }
    };
    return(
        <Fade in={up}>
            <Fab
                onClick={jumpToTop}
                sx={{position:'fixed', 
                    bottom:10,
                    left:30,zIndex:1000, 
                    backgroundColor:'primary.main', 
                    color:'white',
                    '&:hover':{
                    backgroundColor:'primary.secondary',
                    color:'primary.main'
            }}}>
                <KeyboardArrowUp fontSize='large'/>
            </Fab>
        </Fade>
    )
}

export default ActionButton