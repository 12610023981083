/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import React from 'react'
import ButtonStyled from '../components/ButtonStyled'
import { Grid, Typography, Box, Stack, Paper, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Tooltip, useTheme } from '@mui/material'
import Template from '../components/Template'
import axios from 'axios'
import { format } from 'date-fns'
import { Navigate } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ToggleSwitch from '../components/ToggleSwitch'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { AccessTimeOutlined, Close, InfoOutlined, LocationOnOutlined } from '@mui/icons-material'
import SessionInfoTemplate from '../components/SessionInfoTemplate'
import InfoChip from '../components/InfoChip'
import {useSelector} from 'react-redux'
import { useRetrieveEventsQuery } from '../endpoints/member.endpoint'
import { EventsContext, SessionsContext } from '../utilities/Contexts'


//#region Main Event
const Events = () => {

    const [eventData, setEventData] = React.useState(null)
    const [refresh, setRefresh] = React.useState(false)
    const auth = useSelector((state)=>state.auth)
    const {data:events,
        isLoading,
    isSuccess,
    isFetching,
    isError,
    error
    }= useRetrieveEventsQuery(auth.token)

    if(isLoading){
        console.log('Loading...')
    }
    else if(isSuccess){
        if(eventData===null){
            console.log(events)
            var arr = []
            events.result.map((business, index) => {
                    if (business.eve.length > 0) {
                        console.log(business.name)

                        var headLine = <Typography variant='h4'>{business.name}</Typography>
                        arr.push(headLine)

                        business.eve.map((_eve, index) => {

                            var cell = <EventCell key={index} event={_eve}/>

                            arr.push(cell)
                        })
                    }
            })
            setEventData(arr)
        }
    }
    else if(isError){
        console.log(error)
    }

    React.useEffect(() => {
        document.title = 'Events'
    }, [])

    return (
        <>
        {auth.isAuthenticated?
        <EventsContext.Provider value={{eventData, setEventData}}>
            {auth.role===6?<Grid2 container columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} justifyContent={'center'}>
                    <Grid2 container flexDirection={'column'} flexBasis={'800px'}
                        sx={{ py: 4 }}>
                        {eventData}
                    </Grid2>
                </Grid2>:<Template>
                <Grid2 container columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} justifyContent={'center'}>
                    <Grid2 container flexDirection={'column'} flexBasis={'800px'}
                        sx={{ py: 4 }}>
                        {eventData}
                    </Grid2>
                </Grid2>
                <ToggleSwitch />
            </Template>}

        </EventsContext.Provider>:<Navigate replace to='/login' />}
        </>
    )
}

export default Events

//#endregion


//#region EventCells
const EventCell = (props) => {
    console.log(props.event)
    const eventSessions = props.event.eve_ses
    // const [refresh, setRefresh] = React.useState(false)
    const [sessionList, setSessionList] = React.useState([])
    const [startTime] = React.useState(format(new Date(props.event.start_time), "hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(props.event.end_time), "hh:mm aaaaa'm'"))
    const [getDate] = React.useState(format(new Date(props.event.date), 'MM/dd/yyyy'))
    // const {refresh, setRefresh} = React.useContext(EventsContext)
    
    React.useEffect(() => {
        console.log(eventSessions)
        if (eventSessions.length > 0) {
            var arr = []

            eventSessions.map((eventSession, index) => {

                var cell = <SessionCell 
                            key={index} 
                            session={eventSession.sess} 
                            eventTitle={props.event.title} 
                            location={props.event.location} 
                            orgId={props.event.business_id} 
                            date={props.event.date}/>
                arr.push(cell)
            })
            setSessionList(arr)
        }
    }, [])

    const getEvents = () => {
        props.getEvents()
    }

    // React.useEffect(() => {
    //     setRefresh(refresh)
    // }, [refresh])

    return (
        <SessionsContext.Provider value={{sessionList, setSessionList}}>
        <Box sx={{ my: 1 }} id={props.orgId}>
            <Accordion elevation={3} className='userEventCell' >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Grid2
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ minHeight: '4.5em', mr: 4, width: '100%' }}>
                        <Grid2 xs sm={8} container minWidth={'150px'}>
                            <Grid2 xs={12} sm={7} container alignContent={'center'}>
                                <Typography sx={{ display: { lg: 'initial' } }} variant='h6' textAlign='left' >{props.event.title}</Typography>
                            </Grid2>

                            <Grid2 xs={12} sm={5} >
                                <Stack spacing={{ xs: 0, sm: 1 }}>
                                    <Typography variant='body1' sx={{ display: { lg: 'initial' } }}>{`${getDate}`}</Typography>
                                    <Typography variant='body2' sx={{ display: { lg: 'initial' } }}>{`${startTime} - ${endTime}`}</Typography>
                                </Stack>
                            </Grid2>
                        </Grid2>

                        <Grid2 xs sm container minWidth={'100px'} justifyContent={{sm: 'flex-end'}}>
                            <Typography variant='body1' sx={{ fontSize: { xs: '0.9rem', sm: '1rem' },  }}>{props.event.location}</Typography>
                        </Grid2>
                    </Grid2>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        {sessionList}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Box>
        </SessionsContext.Provider>
    )
}
//#endregion


//#region SessionCells
const gridStyled = {
    height: { md: 300, xs: 250 },
    m: { md: 2, xs: 'auto' },
    p: { md: 2, xs: 2 },

}

const disableStatus = { 'subscribe': false, 'unsubscribe': true }

const SessionCell = (props) => {

    const session = props.session
    console.log(session)
    const [cardElevation, setCardElevation] = React.useState(4)
    const [open, setOpen] = React.useState(false)
    const [open1, setOpen1] = React.useState(false)
    const [startTime, setStartTime] = React.useState(format(new Date(session.start_time), "hh:mm aaaaa'm'"))
    const [endTime, setEndTime] = React.useState(format(new Date(session.end_time), "hh:mm aaaaa'm'"))
    const [requestId, setRequestId] = React.useState(session.req[0]?.id??'')
    const [subscribe, setSubscribe] = React.useState(session.req.length>0)
    const [description, setDescription] = React.useState(`Description: ${session.description}`)
    const [access, setAccess] = React.useState(session.req[0]?.access_id??null);
    const [error, setError] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false)
    const [refresh, setRefresh] = React.useState(false)
    const auth = useSelector((state)=>state.auth)

    const handleDialog = () => {
        setOpen(!open)
    }

    const handleDialog1 = () => {
        setOpen1(false)
      }

    const handleSubscribe = () => {

        if (access !== null) {
            axios.post(process.env.REACT_APP_LOCALHOST + '/subscribe', {
                id: auth.token,
                org: props.orgId,
                sessionId: session.id,
                accessType: access,
                date: props.date
            })
                .then((data) => {
                    console.log(data)

                    setSubscribe(true)
                    setOpen(false)
                    setDisabled(true)
                    setRequestId(data.data.result.request.id)

                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else {
            setError(true)
        }
    }

    const handleUnsubscribe = () => {

        axios.post(process.env.REACT_APP_LOCALHOST + `/unsubscribe`, {
            id: auth.token,
            sessionId: session.id,
            requestId: requestId
        })
        .then((data) => {
                setSubscribe(false)
                console.log(data)
                setOpen1(false)
                setDisabled(false)
            })
            .catch((err) => {

                console.log(err)
            })
    }

    const handleAccess = (e) => {
        setAccess(e.target.value)

        setError(false)

        if (access === null) {
            console.log('Noting is inside!')
        }
    }

    const descriptionElementRef = React.useRef(null)
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open])

    return (
        <Box sx={{
            '&:not(:first-child) .userEventSession': {
                borderTop: `1px solid`,
                borderColor: 'divider',
            }
        }}>
            <Paper
                elevation={0}
                className='userEventSession'
                sx={{
                    transition: '.2s',
                    display: 'flex',
                    borderRadius: 0
                }}>
                <Grid2
                    container
                    direction='row'
                    justifyContent='space-between'
                    px={2}
                    py={3}
                    width={'100%'}
                    rowGap={2}>
                    <Grid2 container xs={12} sm>
                        <Grid2 container xs gap={0.5}>
                            <Grid2 container alignContent={'center'} xs={12}  >
                                <Typography variant='h7'><b>{props.session.title}</b></Typography>
                            </Grid2>
                            <Grid2 container alignContent={'center'} xs={12} gap={1} >
                                <InfoChip label={`${startTime} - ${endTime}`} icon={<AccessTimeOutlined /> } size='small' tooltip={'Time'} />
                                <InfoChip label={props.location} icon={<LocationOnOutlined /> } size='small' tooltip={'Location'} />
                            </Grid2>

                        </Grid2>

                        <Grid2 container alignContent={'flex-start'} xs={'auto'} display={{sm: 'none'}}>
                                <Tooltip title='More Info'>
                                    <IconButton onClick={handleDialog} >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                        </Grid2>
                    </Grid2>

                    <Grid2 container xs={12} sm={'auto'} gap={1} justifyContent={'flex-end'} alignContent={'center'}>
                        {auth.role===7 && <Grid2 container alignContent={'center'} xs={'auto'} height={'42px'}>
                            <FormControl sx={{ minWidth: '150px' }} error={error} disabled={disabled} size='small'>
                                <InputLabel>Access Type</InputLabel>
                                <Select
                                    value={access}
                                    label='Access Type'
                                    onChange={handleAccess}>
                                    <MenuItem value={1}>Interpreter</MenuItem>
                                    <MenuItem value={2}>Captioner</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>}
                        {auth.role===7?<Grid2 container alignContent={'center'} xs={'auto'} justifyContent={'flex-end'} >
                            <ButtonStyled action={() => { !subscribe?handleSubscribe() : 
                            setOpen1(true)
                            }} variant={subscribe?'outlined':'contained'} title={subscribe?'Unsubscribe':'Subscribe'} width='130px' />
                        </Grid2>:<Grid2 container alignContent={'center'} xs={'auto'} justifyContent={'flex-end'} >
                            <ButtonStyled action={() => { !subscribe?handleSubscribe() : 
                            setOpen1(true)
                            }} variant={subscribe?'outlined':'contained'} title={subscribe?'Reject':'Accept'} width='130px' />
                        </Grid2>}
                        <Grid2 container alignContent={'center'} xs={'auto'} display={{xs: 'none', sm: 'flex'}}>
                            <Tooltip title='More Info'>
                                <IconButton onClick={handleDialog} >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                    </Grid2>

                </Grid2>
            </Paper>
            <Dialog
                open={open}
                onClose={handleDialog}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle variant='dialogTitle'>More Info</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDialog}
                    className='closeButton'>
                    <Close />
                </IconButton>
                <DialogContent dividers={true}>
                    <SessionInfoTemplate 
                        title={props.session.title}
                        eventTitle={props.eventTitle}
                        startTime={startTime}
                        endTime={endTime}
                        date={format(new Date(props.date), 'MM/dd/yyyy')}
                        description={props.session.description}
                        location={props.location}/>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled action={handleDialog} color='info' title='OK' />
                    {/* <ButtonStyled action={()=>{subscribe==='subscribe'?handleSubscribe():handleUnsubscribe()}} title={subscribe}/> */}
                </DialogActions>
            </Dialog>
            <Dialog
              open={open1}
              onClose={handleDialog1}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="unsubscribeEventDialog">
              <DialogTitle id="alert-dialog-title">
                Are you sure?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This cancellation request will be permanent. Please click below to confirm your action.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonStyled variant="outlined" color='secondary' action={handleDialog1} title='Go Back' />
                <ButtonStyled variant="contained" color='error' action={handleUnsubscribe} autoFocus title='Unsubscribe' />
              </DialogActions>
            </Dialog>

        </Box>
    )
}
//#endregion

