/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import React from 'react';
import {AppBar, Box, Toolbar, Drawer, List, IconButton, Typography, Button, Stack, Grid, Menu, MenuItem, ToggleButtonGroup} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleSwitch from './ToggleSwitch'
import { useNavigate } from 'react-router-dom';
import logo from '../media/green.png'
import LinkButton from './LinkButton';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {useDispatch, useSelector} from'react-redux';
import {LoggingOut} from '../slices/auth.slice';
import { useLogOutMutation } from '../endpoints/member.endpoint';

const AppBarDrawer = (props)=>
{
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const history = useNavigate()
    const dispatch = useDispatch()
    const auth = useSelector((state)=>state.auth)
    const [logOut,{isLoading}] = useLogOutMutation()

    console.log(window.location.pathname)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCancel=()=>
    {
      setAnchorEl(null)
    }

    const handleClose = (e) => {
      switch(e.target.value)
      {
        case 0:
          window.location.href='/profile'
         break;
        case 1:
          LogOut()
        break;
        default:
        break;
      }
      
      setAnchorEl(null);
    }

    async function LogOut()
    {
        console.log('Log Status: '+auth.isAuthenticated)

        const result = await logOut({token:auth.token})

        dispatch(LoggingOut())

    }

    React.useEffect(()=>{

    },[props.link, props.them, auth.isAuthenticated])

return(
  <AppBar position="sticky" sx={{ backgroundColor:'appBar.bar'}}>
      <Toolbar sx={{'&':{padding:0, display:'inline-block'}}}>
        <Grid container columns={{ xs: 12, sm: 12, md: 12, xl: 12}} justifyContent='center'>
          <Grid container
              direction="row"
              justifyContent='space-around'
              alignItems="center"
              wrap='nowrap'
              maxWidth={'1232px'}
              >
            <Button color='secondary' href='/' sx={{m:1, display:{xs:'none', md:'inline-block'},'&:hover':{cursor:'pointer'}}}>
              <Stack spacing={1} direction='row' sx={{justifyContent:'center', width: 'max-content'}}>
                <img src={logo} alt=''/>
                <Box>
                  <Typography align='left' sx={{lineHeight:.9,mt:1,fontSize:20}}><b>Access</b><br/>on Demand</Typography>
                </Box>
                <Box sx={{background:'linear-gradient(47deg,#84BD00,#C4D600)', height:32, m:'auto',p:'auto', borderRadius:2}}>
                    <Typography sx={{lineHeight:.9,m:1,fontSize:20, color:'whitesmoke'}}><b>Beta</b></Typography>
                    </Box>
              </Stack>
            </Button>
            <Box sx={{display:{xs: 'inline-block', md:'none'}}}>
              <IconButton
                size='large'
                edge="start"
                
                aria-label="menu"  
                onClick={() => setIsDrawerOpen(true)}
                >
                <MenuIcon/>
              </IconButton>
              <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <List sx={{width:160}}>
                  {auth.isAuthenticated?<LinkButton link='/myrequests' title='My Requests'/>:<LinkButton link='/' title='Overview'/>}
                  {auth.isAuthenticated?<></>:<LinkButton link='/about' title='About Us'/>}
                  {auth.isAuthenticated?<LinkButton link='/events' title='Events' color='secondary'/>:<></>}
                  {auth.isAuthenticated?<LinkButton link='/help' title='Help'/>:<></>}
                  {auth.isAuthenticated?<><LinkButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  color='secondary'
                  title='My Account' action={handleClick}/>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCancel}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                  <MenuItem value={0} onClick={handleClose}>Profile</MenuItem>
                  <MenuItem value={1} onClick={handleClose}>Log Out</MenuItem>
                </Menu></>:<LinkButton link='/login' title='Log In'/>}
                </List>
              </Drawer>
            </Box>
            <Button color='secondary' href='/' sx={{m:1, display:{xs:'inline-block', md:'none'},'&:hover':{cursor:'pointer'}}}>
              <Stack spacing={1} direction='row' sx={{justifyContent:'center'}}>
                <img src={logo} alt=''/>
                <Box>
                  <Typography align='left' sx={{lineHeight:.9,mt:1,fontSize:20}}><b>Access</b><br/>on Demand</Typography>
                </Box>
                <Box sx={{background:'linear-gradient(47deg,#84BD00,#C4D600)', height:32, m:'auto',p:'auto', borderRadius:2}}>
                    <Typography sx={{lineHeight:.9,m:1,fontSize:20, color:'whitesmoke'}}><b>Beta</b></Typography>
                    </Box>
              </Stack>
            </Button>
            <Grid2 sx={{display:{xs: 'none', md:'flex', width: '100%'}}} container flexDirection={'row'} justifyContent={'flex-end'} >
                {auth.isAuthenticated?<LinkButton link='/myrequests' title='My Requests'/>:<LinkButton link='/' title='Overview'/>}
                {auth.isAuthenticated?<></>:<LinkButton link='/about' title='About Us'/>}
                {auth.isAuthenticated?<LinkButton link='/events' title='Events' color='secondary'/>:<></>}
                {auth.isAuthenticated?<LinkButton link='/help' title='Help'/>:<></>}
                {auth.isAuthenticated?<><LinkButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color='secondary' title='My Account' action={handleClick}/>
                
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCancel}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem value={0} onClick={handleClose}>Profile</MenuItem>
                  <MenuItem value={1} onClick={handleClose}>Log Out</MenuItem>
                </Menu></>:<LinkButton link='/login' title='Sign In / Sign Up'/>}
            </Grid2>
            <Box sx={{display:{xs: 'initial'},opacity:0}}>
              <ToggleSwitch/>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
  </AppBar>
 )
}

export default AppBarDrawer