import { create } from "lodash";
import { createContext } from "react";


const SessionCardContext = createContext(null)

const SessionsContext = createContext(null);

const EventsContext = createContext(null);

const SessionEventsContext = createContext(null);

const RequestsContext = createContext(null);

const ConferenceContext = createContext(null);

const AgencyContext = createContext(null)

const AssignTranslatorsContext = createContext(null)

export {SessionsContext, RequestsContext, EventsContext, ConferenceContext, SessionEventsContext, SessionCardContext, AgencyContext, AssignTranslatorsContext}
