/********************************************************
 *                                                      *
 *    Created by: jpaccl - James Patrick Anderson       *
 *                                                      *
 *                                                      *
 ********************************************************/

import {useCallback, useEffect, useState, useTransition} from 'react'
import { Box , Typography, Grid, Card, CardContent, Button, Stack} from '@mui/material'
import { useTheme } from "@mui/material/styles";
import {LocalizationProvider} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SearchBar from '../components/SearchBar'
import {format} from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import '../styles/test.css'
import {useNavigate} from 'react-router-dom'
import { customFetch } from '../utils'
import {useSelector, useDispatch} from 'react-redux'
import { setMCU } from '../slices/mcu.slice'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tag from '../components/Tags'
import axios from 'axios'
import { setStream } from '../slices/stream.slice'

import SessionCard from '../components/SessionCard'
import { Access, Category, Status } from '../utilities/Enums'
import ButtonStyled from '../components/ButtonStyled';
import { ErrorBoundary } from 'react-error-boundary';
import { useRetrieveAssignmentsQuery } from '../endpoints/translators.endpoint'




//#region TerpSessions
const TerpSessions = () => {

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState()
  const [data, setData] = useState([])
  const [accessType, setAccessType] = useState(null)
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [inputText, setInputText] = useState('')
  const [sessions, setSessions] = useState([])
  const [tag, setTag] = useState('Title')
  const [tokens, setTokens] = useState([])
  const auth = useSelector(state=>state.auth)
  const {data:services, isLoading, isSuccess} = useRetrieveAssignmentsQuery(auth.token)


  useEffect(()=>{
    if(isLoading)
    {

    }
    else if(isSuccess)
    {
      console.log(services.result)
      if(services.result.length>0)
      {
        setAccessType(services.id)
        setTokens(services.sessionTokens)
        setData(services.result)
      }
    }
  },[isLoading, isSuccess, services])

  if(window.webRTCAdaptor)
  {
    console.log('Closing the stream')
    window.webRTCAdaptor.closeStream()
    setTimeout(()=>{
      window.webRTCAdaptor.leave(window.streamId)
    },3000)
    
  }

  const filterSessions = useCallback(()=>{

    if(!(data.length>0)) { return ;}

    let arr = []
    data.map((data,index) => {

        console.log(tokens[index])

        console.log(tokens)

        var getToken = tokens[index]
        
        console.log(data)

        var sess = <TerpSessionGrid key={data.bus_ag.id} businessAgreement={data.bus_ag} accessType={accessType} token={getToken}/>

        arr.push(sess)
        return arr
    })
    setSessions(arr)

  },[data, tokens, accessType, inputText,tag])

  // const filterSessions = useCallback(()=>
  // {
  //   console.log(data)
  //   if(!(data.length>0)) { return ;}

  //   var arr = []
  //   if(inputText.length > 0)
  //   {
  //       switch(tag)
  //       {
  //         case "Title":
  //           data.map((data,index)=>{
  //             console.log(`${data[index].session.title} === ${inputText}`)
  //             if(data[index].session.title.includes(inputText))
  //             {
  //               var sess = <TerpSessionGrid key={data.id} session={data[index].req.sess} sessId={data[index].req.sess.id} accessType={accessType} token={tokens[index]}/>

  //               arr.push(sess)
  //             }
  //             })

  //             console.log(arr)

  //             setSessions(arr)
  //         break;
  //         case "Time":
  //           data.map((data,index)=>{

  //             console.log(`${data[index].session.start_time} === ${inputText}`)
  //             if(data[index].session.start_time.includes(inputText))
  //             {
  //               var sess = <TerpSessionGrid key={data.id} session={data[index].req.sess} sessId={data[index].req.sess.id} accessType={accessType} token={tokens[index]}/>

  //               arr.push(sess)
  //             }
  //             })

  //             console.log(arr)

  //             setSessions(arr)
  //         break;
  //         case "Status":
  //           data.map((data,index)=>{

  //             var status
  //             if(inputText === 'Uncovered')
  //             {
  //               status = 0
  //             }
  //             else
  //             {
  //               status = 1
  //             }

  //             console.log(`${status} === ${inputText}`)
  //             if(data[index].session.status === status)
  //             {
  //               var sess = <TerpSessionGrid key={data.id} session={data[index].req.sess} sessId={data[index].req.sess.id} accessType={accessType} token={tokens[index]}/>

  //               arr.push(sess)
  //             }
  //             })

  //             console.log(arr)

  //             setSessions(arr)
  //           break;
  //         case "Category":
  //           data.map((data,index)=>{

  //             console.log(`${data[index].session.category} === ${inputText}`)
  //             if(data[index].session.category.includes(inputText))
  //             {
  //               var sess = <TerpSessionGrid key={data.id} session={data[index].req.sess} sessId={data[index].req.sess.id} accessType={accessType} token={tokens[index]}/>

  //               arr.push(sess)
  //             }
  //             })

  //             console.log(arr)
              
  //             setSessions(arr)
  //           break;
  //         default:

  //           break;
  //       }
  //   }
  //   else
  //   {
  //     data.map((data,index) => {

  //       console.log(tokens[index])

  //       console.log(tokens)

  //       var getToken = tokens[index]
        
  //       console.log(data)

  //       var sess = <TerpSessionGrid key={data.bus_ag.id} request={data.bus_ag.req} sessId={data.bus_ag.req.sess.id} accessType={accessType} token={getToken}/>

  //       arr.push(sess)
  //     })
  //     setSessions(arr)
  //   }
  // },[data, tokens, accessType, inputText,tag])

  useEffect(()=>{

    filterSessions()

},[filterSessions])

  useEffect(()=>{

  },[sessions])

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ErrorBoundary fallback={<div>Problems with the design</div>}>
      <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{height:'100%'}}>
          <Box sx={{ml:3}}>
            <SearchBar setInputText={setInputText}/>
            <Tag setTag={setTag} />
          </Box>
      </Grid>
      <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
        <Typography variant="h4" sx={{mt: 5}}>My Sessions</Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{mb:3}}>
          {sessions}
        </Grid>
      </Grid>
      </ErrorBoundary>
    </LocalizationProvider>
    </>
  )
}

export default TerpSessions
//#endregion

//#region TerpSessionGrid
const TerpSessionGrid = (props) => {

    const {businessAgreement, accessType, token} = props
    const session = businessAgreement.req.sess


    const [status, setStatus] = useState(Status[businessAgreement.status_id])
    const [access, setAccess] = useState(`${businessAgreement.req.access_id}`)
    const [startTime, setStartTime] = useState(format(new Date(session.start_time),"hh:mm aaaaa'm'"))
    const [endTime, setEndTime] = useState(format(new Date(session.end_time),"hh:mm aaaaa'm'"))
    const [date, setDate] = useState(format(new Date(businessAgreement.req.date), 'MM/dd/yyyy'))
    const [copied, setCopied] = useState(false)
    const [text, setText] = useState('Copy Session Token')
    const theme = useTheme();

    //temp


    const [isPending, startTransition] = useTransition()
    const auth = useSelector(state=>state.auth)

    const dispatch = useDispatch()

    const history = useNavigate()

    function live(checkLive){
      if (checkLive > 0){
        return "visible"
      } else {
        return "hidden"
      }
    }

    const CopyHandle =()=>
    {
      setCopied(true)
      setText('Session Token Copied')

      setTimeout(()=>{
        setText('Copy Session Token')
      },5000)
    }

    const ClipboardButton = (props) => {

      return (
        <CopyToClipboard text={token} onCopy={CopyHandle}>
          <ButtonStyled variant='outlined' title={text} width="100%" size="medium" />
        </CopyToClipboard>
      )
    }

    const sessionSelected=()=>
    {
      axios.get(process.env.REACT_APP_LOCALHOST+`/token/?sessId=${session.id}&id=${auth.token}`)
      .then(result=>{

          if(result.status===200)
          {
            return result.data
          }
      })
      .then(data=>{

          if(data.status > 0)
          {
            
            dispatch(setStream({
              name:data.name, 
              position:data.position, 
              title:session.title, 
              streamId: data.result.streamId, 
              sessionId: data.result.roomId, 
              streamToken: data.result.tokenId}))
              
              startTransition(history('/broadcast'))
          }
          else
          {
            console.log('No session is found or token is rejected')
          }
      })
      .catch(err=>{
          console.log(err.message)
      })
    }

    return (

      <SessionCard 
        status={status}
        date={date}
        startTime={startTime}
        endTime={endTime}
        title={session.title}
        category={session.category_id}
        access={access}
        role={auth.role}
        accessType={accessType}
        token={token}
        requestNum={businessAgreement.req.number}
        organization={businessAgreement.bus.name}
        description={session.description}
        // notes={info.sess.notes}
        theme={theme}
        sessionSelected={sessionSelected}
        clipboard={accessType === 2 && <ClipboardButton />}
    />
    )
  }
//#endregion

