import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField} from '@mui/x-date-pickers/DateField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const DateBar = (props)=>{

    let dateHandler = (date)=>{
        console.log(date)
        
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateField onChange={dateHandler} sx={{width:'15em'}}/>
        </LocalizationProvider>
    )
}

export default DateBar