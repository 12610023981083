/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import { toggleTheme } from '../slices/theme.slice'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
  
function ToggleSwitch(props){

  const dispatch = useDispatch()  

  function toggled()
  {
    dispatch(toggleTheme())
  }

    return(
      <IconButton onClick={toggled}
      sx={{position:'fixed',
          bottom:10,
          right:30,
          zIndex:1000,
          backgroundColor:'accessbility.main'}}>
        <FontAwesomeIcon icon={faUniversalAccess} size='2xl'/>
      </IconButton>
    )
}

export default ToggleSwitch