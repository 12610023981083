/******************************************************\
*    Modified by ajpccl -AaronJoel Parker              *
\******************************************************/

import * as React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SearchBar from '../components/SearchBar'
import Tag from '../components/Tags'
import { ErrorBoundary } from 'react-error-boundary';
import '../styles/test.css'
import { format } from 'date-fns'
import { useNavigate, Navigate } from 'react-router-dom'
import { Status, Category } from '../utilities/Enums'
import { setStream } from '../slices/stream.slice'
import { useStreamingSelectedMutation } from '../slices/api.slice'
import { useRetrievePresentationsQuery } from '../endpoints/org.endpoint'
import {useSelector, useDispatch} from 'react-redux'
import { SessionsContext } from '../utilities/Contexts'
import SessionCard from '../components/SessionCard'

//#region Presenting
const Presenting = () => {

  const [collection, setCollection] = React.useState([])
  const [display, setDisplay] = React.useState(<></>)
  const [inputText, setInputText] = React.useState('')
  const [sessions, setSessions] = React.useState([])
  const [tag, setTag] = React.useState('Title')
  const auth = useSelector(state => state.auth)
  const { data: presentations,
    isLoading,
    isSuccess } = useRetrievePresentationsQuery(auth.token)

  const filterSessions = React.useCallback(() => {

    if(collection.length===0) { return ;}

    if (inputText.length > 0) {
      let arr = []
      switch (tag) {
        case "Title":
          arr = collection.filter(row => row.title.includes(inputText))
          setSessions(arr)
          break;
        case "Time":
          arr = collection.filter(row => row.req.start_time.includes(inputText))
          setSessions(arr)
          break;
        case "Status":
          arr = collection.filter(row => Status[row.req.tick_req.ticket.approval] === inputText)
          setSessions(arr)
          break;
        case "Category":
          arr = collection.filter(row => Category[row.category_id])
          setSessions(arr)
          break;
        default:
          console.log('Cannot find any data')
          break;
      }
    }
    else {
      let arr = []
      collection.map(row => {
        arr.push(row)
        return row
      })

      startSettingSessions(arr)
    }
  },[collection, inputText, tag])

  React.useEffect(() => {
    filterSessions()
  }, [filterSessions])

  


  const fetchingData=React.useCallback(()=>{
    if (isLoading) {
      console.log('isLoading...')
    }
    else if (isSuccess) {

      console.group(presentations.result)

      setCollection(presentations.result)
    }
  },[isLoading, isSuccess, presentations])

  React.useEffect(()=>{
    fetchingData()
  },[fetchingData])

  const startSettingSessions = (sessionArr) => {
    var arr = []
    var _sessionId = ''
    sessionArr.map((row, index) => {

      console.log(row)

      const {req} = row

      if (_sessionId !== req.session_Id) {
        _sessionId = req.session_Id

      }

      var session = <SessionGrid key={index} info={req} statusId={row.status_id} index={index} />
      arr.push(session)
      return session

    })
    setDisplay(arr)
  }

  React.useEffect(() => {
      filterSessions()

  }, [filterSessions])

  return (
    <SessionsContext.Provider value={{ collection }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ErrorBoundary fallback={<div>Whoops!</div>}>
        <Box>
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ mt: 3 }}>
            <Box sx={{ ml: 8 }}>
              <SearchBar setInputText={setInputText} />
              <Tag setTag={setTag} />
            </Box>
          </Grid>
          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Typography variant="h4" sx={{ mt: 5 }}>Sessions</Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 3 }}>
              {display}
            </Grid>
          </Grid>
        </Box>
        </ErrorBoundary>
      </LocalizationProvider>
    </SessionsContext.Provider>
  )
}

export default Presenting
//#endregion

//#region SessionGrid
const SessionGrid = (props) => {

  const { info, statusId } = props

  const status = Status[statusId]

  const [startTime] = React.useState(format(new Date(info.sess?.start_time), "hh:mm aaaaa'm'"))
  const [endTime] = React.useState(format(new Date(info.sess?.end_time), "hh:mm aaaaa'm'"))
  const [date] = React.useState(format(new Date(info.date), 'MM/dd/yyyy'))
  const [title] = React.useState(info.sess.title)
  const [access, setAccess] = React.useState()

  const [isPending, startTransition] = React.useTransition()
  const auth = useSelector((state) => state.auth)
  const theme = useTheme();
  const [streamingSelected] = useStreamingSelectedMutation()

  const history = useNavigate()
  const dispatch = useDispatch()

  const NaviPresentation = () => {
    if (auth.role === (3 || 4)) {
      dispatch(setStream({
        name: '',
        position: '',
        sessionTitle: info.title,
        streamId: '',
        sessionId: info.id,
        streamToken: ''
      }))

      startTransition(history('/webspeech'))

    }
    else {
      console.log('Not for Presenter')
    }
  }

  console.log(info.sess.title)
  
  const SessionSelected = async () => {
    streamingSelected({ token: auth.token, sessionId: info.sess.id })
      .then((res) => {
        console.log(res.data)
        if (res.data.status > 0) {

          dispatch(setStream({
            name: res.data.name,
            position: res.data.position,
            title: info.sess.title,
            streamId: res.data.result.streamId,
            sessionId: res.data.result.roomId,
            streamToken: res.data.result.tokenId
          }))

          startTransition(()=>{history('/broadcast')})

        }

      })
  }

  const getAccess = React.useCallback(() => {
    const accessArr = info.access_id
    setAccess(accessArr)
  },[info.access_id])

  React.useEffect(() => {
    getAccess()
  }, [getAccess])

  return (
    <>
      {auth.isAuthenticated ?
        <SessionCard
          status={status}
          date={date}
          startTime={startTime}
          endTime={endTime}
          title={title}
          category={info.category_id}
          access={access}
          role={auth.role}
          requestNum={info.number}
          // organization={request.tick_req.tick.bus_ag[0].bus.name}
          description={info.sess.description}
          // notes={info.sess.notes}
          theme={theme}
          sessionSelected={status.label === 'Assigned' ? SessionSelected : NaviPresentation}
        />
        : <Navigate replace to='/login' />}
    </>
  )
}
//#endregion
