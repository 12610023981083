import React, {useContext, useState, useEffect} from 'react'
import { Button, Grid,Box, FormControlLabel, Stack, FormControl, FormGroup, InputLabel, Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, Chip, IconButton, Backdrop, Typography, CircularProgress} from '@mui/material'
import ButtonStyled from './ButtonStyled'
import { useSelector } from 'react-redux'
import { useAddTranslatorMutation } from '../endpoints/agency.endpoint'

const NewTranslator=(props)=>
{
    const [ username, setUsername ] = useState('')
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ access, setAccess ] = useState(0)
    const [email, setEmail] = useState('')
    const [ addNewTranslator ] = useAddTranslatorMutation()
    const [pendingMsg, setPendingMsg] = useState('Generating new access provider')
    const [openPendingMsg, setOpenPendingMsg] = useState(false)
    const auth = useSelector(state=>state.auth)
    const cleanInput = [true, true, true, true, true]

    const handleClose = () => {
        props.setOpen(false);
    };

    const checkInput=(textInput, index)=>{
        if(textInput==='' || textInput===undefined || textInput===null || textInput===0)
        {
            cleanInput[index] = false;
            return true
        }
        else
        {
            cleanInput[index]= true
            return false
        }
    }

    const checkInputRequirements=()=>
    {
        if(checkInput(username,0)) {setUsername(null)}

        if(checkInput(firstName,1)) {setFirstName(null)}
        
        if(checkInput(lastName,2)) {setLastName(null)}

        if(checkInput(email, 3)) { setEmail(null)}

        if(checkInput(access,4)) { setAccess(null)}
    }

    const createTranslatorTask=async()=>{
        
        await addNewTranslator({
            username:username,
            firstName:firstName,
            lastName:lastName,
            access:access,
            email:email,
            id:auth.token
        })
        .then((_data)=>{

            setTimeout(()=>{
                setPendingMsg('Successfully generated new access provider')

                setTimeout(()=>{
                    setUsername('')
                    setEmail('')
                    setAccess(0)
                    setFirstName('')
                    setLastName('')
                    setOpenPendingMsg(false)
                    props.setOpen(false)
                },1000)
            },1000)
        })
        .catch((err)=>{
            console.log(err.message)
        })
    }

    const handleFirstName=(e)=>{
        setFirstName(e.target.value)
    }

    const handleLastName=(e)=>{
        setLastName(e.target.value)
    }

    const handleEmail=(e)=>{
        setEmail(e.target.value)
    }

    const handleUsername=(e)=>{
        setUsername(e.target.value)
    }

    const handleAccess = (e)=>{
        setAccess(e.target.value)
    }

    const handleTranslator=()=>{

        checkInputRequirements()

        postTranslator()
    }

    const postTranslator=async()=>{

        if(Object.values(cleanInput).every(Boolean))
        {
            setPendingMsg('Generating new access provider')
            setOpenPendingMsg(true)

            await createTranslatorTask()
        }
    }

    useEffect(()=>{
        console.log(access)
    },[access])

    return(
        <Dialog
        sx={{overflow:'hidden'}}
        open={props.open}>
            <DialogTitle>New Translator</DialogTitle>
            <DialogContent>
                <Grid 
                container
                columns={{xs:8, sm:8}}
                rowSpacing={2}
                columnSpacing={{xs:1, sm:2}}>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            autoFocus
                            required={true}
                            margin="dense"
                            error={firstName===null}
                            label="First Name"
                            value={firstName}
                            onChange={handleFirstName}
                            type="text"
                            fullWidth/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            autoFocus
                            required={true}
                            margin="dense"
                            label="Last Name"
                            error={lastName===null}
                            value={lastName}
                            onChange={handleLastName}
                            type="text"
                            fullWidth/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                            autoFocus
                            required={true}
                            margin="dense"
                            label="Email"
                            error={email===null}
                            value={email}
                            onChange={handleEmail}
                            type="text"
                            fullWidth/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            autoFocus
                            required={true}
                            margin="dense"
                            label="Username"
                            error={username===null}
                            value={username}
                            onChange={handleUsername}
                            type="text"
                            fullWidth/>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                    <FormControl 
                        required
                        fullWidth>
                        <InputLabel>Access Type</InputLabel>
                        <Select 
                            value={access}
                            error={access===null}
                            label='Access Type'
                            onChange={handleAccess}>
                            <MenuItem value={1}>Interpreter</MenuItem>
                            <MenuItem value={2}>Captioner</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item>
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={openPendingMsg}>
                            <Box sx={{display:'block', ml:'auto',mr:'auto'}}>
                            <Box>
                            <CircularProgress 
                                color='success'
                                size='25vh'
                                sx={{display:'block', ml:'auto',mr:'auto'}}/>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'center', opacity:1}}>
                                <Typography variant='h2' sx={{m:'auto', fontSize:{xs: '2.5rem', sm: '3rem'}}}><b>{pendingMsg}</b></Typography>
                            </Box>
                        </Box>
                        </Backdrop>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonStyled action={handleClose} variant='outlined' color='error' title='Cancel'/>
                <ButtonStyled action={handleTranslator} variant='contained' title='Confirm'/>
            </DialogActions>
        </Dialog>
    )
}

export default NewTranslator