/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'

import {Button} from '@mui/material'

const LinkButton = (props)=>
{
    const [selected, setSelected]= React.useState(false)

    React.useState(()=>{
        if(window.location.pathname === props.link || (props.title==='My Account' && window.location.pathname==='/profile'))
        {
            setSelected(true)
        }
    },[])

    return(
        <Button
        id={props.id}
        href={props.link}
        color={selected?'primary':'secondary'}
        variant={selected?'outlined':'text'}
        onClick={props.action}
        sx={{
            // m:'auto', 
            flex: {xs: '1 0 auto', md: '0 1 150px'},
            width: {xs: '100%', md: 'auto'},
            textAlign:'center', 
            backgroundColor:props.color, 
            fontWeight:'bold'
        }}>
            {props.title}
        </Button>
    )
}

export default LinkButton