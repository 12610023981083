import * as React from 'react'
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material'


const Tag = (props)=>
{
    const [value, setValue] = React.useState('Title')

    const handleRadioChange = (event)=>
    {
        setValue(event.target.value)
        props.setTag(event.target.value)   
    }

    return(
        <>
        <FormControl sx={{width:'10em', ml:3}}>
            <InputLabel>Tags</InputLabel>
                    <Select
                      label='Tags'
                      defaultValue='Title'
                      onChange={handleRadioChange}>
                      <MenuItem value={'Title'}>Title</MenuItem>
                      <MenuItem value={'Date'}>Date</MenuItem>
                      <MenuItem value={'Time'}>Time</MenuItem>
                      <MenuItem value={'Category'}>Category</MenuItem>
                      <MenuItem value={'Status'}>Status</MenuItem>
                    </Select>
        </FormControl>
        </>
    )
}

export default Tag