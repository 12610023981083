/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson,     *
 *                 gvccl -  Gabriel Veit                *
 *                                                      *
 ********************************************************/

import React,{useContext} from 'react'
import Footer from '../components/Footer'
import Presentation from '../widgets/Presentation'
import {Box, Typography, Button, Grid, Stack, Paper} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarDeaf, faGears, faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../components/ActionButton'
import {Navigate, useNavigate} from 'react-router-dom'
import ToggleSwitch from '../components/ToggleSwitch'
import Permissions from '../components/Permissions'
import Cookies from 'js-cookie'
import OurPartners from '../widgets/OurPartners'
import Template from '../components/Template'
import macbook from '../media/macbook.png'
import iphone from '../media/realiphone.png'
import vuzix from '../media/vuzix.png'
import '../styles/intro.css'
import {useSelector} from 'react-redux'


const Overview = ()=>
{
    const setDirect = []
    setDirect[3] = <Navigate replace to='/services/managerequests'/>
    setDirect[4] = <Navigate replace to='/services/presentation'/>
    setDirect[5] = <Navigate replace to='/services/requests'/>
    setDirect[6] = <Navigate replace to='/services/assignments'/>
    setDirect[7] = <Navigate replace to='/myrequests'/>


    const [open, setOpen] = React.useState(false)
    const auth = useSelector((state)=>state.auth)

    React.useEffect(()=>{

        document.title='Overview'

        console.log('isLoggedIn ', auth.isAuthenticated)
        console.log('Auth Role ', auth.role)

        if(!Cookies.get('user_cookie_consent'))
        {
            Cookies.set('user_cookie_consent',0)
        }
        else
        {
            if(Cookies.get('user_cookie_consent')==='0')
            {
                setOpen(true)
            }
        }
    },[])

    return(
        <>
        <Template>
        {auth.isAuthenticated?setDirect[auth.role]:
            <Box>
            <Box id='intro' sx={{alignItems:'center', m:0, justifyContent:'center', textAlign:'center'}}>
            <Box sx={{display:'flex', flexWrap:'wrap-reverse', flexDirection:'row', p:5, alignItems:'center', justifyContent:'center'}}>
                <Box sx={{display:'block',justifyContent:'center'}}>
                    <Typography variant='h2' sx={{textAlign:{md:'left',xs:'center'},color:'white', fontSize:{md:52,sm:46,xs:36}}} component='h2'><b>We provide<br/> <u>ultimate access</u><br/> anytime, anywhere</b></Typography>
                    <Button variant='outlined' sx={{borderRadius:5, size:'large',mt:2, color:'#fff', borderColor:'#fff'}}>More Information</Button>
                </Box>
                <Box sx={{mt:2}}>
                <Carousel

                navButtonsProps={{
                    style:{
                        backgroundColor:'transparent'
                    }
                }}

                navButtonsAlwaysVisible={true}

                autoPlay={true}

                indicators={false}

                duration={300}

                fullHeightHover={true}

                NextIcon={<ArrowForwardIosOutlined fontSize='large'/>}

                PrevIcon={<ArrowBackIosNewOutlined fontSize='large'/>}

                sx={{
                    width:{md:650,sm:530,xs:280},
                    height:{md:330,xs:200}
                }}>
                    <img src={vuzix}  style={{display:'block', width:'100%'}} alt=''/>
                    <img src={iphone}  style={{display:'block', width:'100%'}} alt=''/>
                    <img src={macbook}  style={{display:'block', width:'100%'}} alt=''/>
                </Carousel>
                </Box>
            </Box>
        </Box>
                <Presentation/>
                <ActionButton/>
                <ToggleSwitch />
                <Permissions open={open} setOpen={setOpen}/>
                <Box sx={{width:'auto', bottom:0, position:'relative', p:4, backgroundColor:'section.main', color:'primary.light'}}>
                <Box sx={{display:'flex', flexDirection:{md:'row',sm:'column',xs:'column'}, justifyContent:{md:'center',sm:'flex-start',xs:'flex-start'},textAlign:'left'}}>
                    <Stack spacing={2} sx={{textAlign:"center", mb:{md:0,sm:2,xs:6},m:{md:1,sm:4,xs:4}}}>
                        <Typography variant='h4' sx={{color:'#fff'}}>Why choose us?</Typography>
                        <Stack spacing={0}>
                            <Typography variant='body1' sx={{color:'#fff'}}><em>Learn how we aspire to be different.</em></Typography>
                            <Box sx={{ flexGrow: 1, marginTop: 5, marginBottom: 5 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                    <Paper elevation={24} className="chooseItem" sx={{maxWidth:300, width: "auto", height:250, mx: "auto", textAlign:'center'}}>
                                            <Box sx={{my: 3, pt:3}}>
                                                <FontAwesomeIcon className="chooseIcon" icon={faEarDeaf}/>
                                            </Box>
                                            <Box className="chooseUsText" sx={{marginBottom: 2, fontSize:{md:20, sm:16}}}>
                                                <Typography variant='body1'sx={{fontSize:{md:20, sm:16}}}><b>Deaf-led</b></Typography>
                                                <Typography variant='body1'sx={{fontSize:{md:20, sm:16}}}>Developed by the Deaf and for the Deaf</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                    <Paper elevation={24} className="chooseItem" sx={{maxWidth:300, width: "auto", height:250, mx: "auto", textAlign:'center'}}>
                                            <Box sx={{my: 3, pt:3}}>
                                                <FontAwesomeIcon className="chooseIcon" icon={faGears}/>
                                            </Box>
                                            <Box className="chooseUsText" sx={{marginBottom: 2, fontSize:{md:20, sm:16}}}>
                                                <Typography variant='body1' sx={{fontSize:{md:20, sm:16}}}><b>Interoperable</b></Typography>
                                                <Typography variant='body1' sx={{fontSize:{md:20, sm:16}}}>Seamless integration with all major state-of-the-art technologies</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Paper elevation={24} className="chooseItem" sx={{maxWidth:300, width: "auto", height:250, mx: "auto", textAlign:'center'}}>
                                            <Box sx={{my: 3, pt:3}}>
                                                <FontAwesomeIcon className="chooseIcon" icon={faUniversalAccess}/>
                                            </Box>
                                            <Box className="chooseUsText" sx={{marginBottom: 2, fontSize:{md:20, sm:16}}}>
                                                <Typography variant='body1'sx={{fontSize:{md:20, sm:16}}}><b>Accessible &amp; Inclusive</b></Typography>
                                                <Typography variant='body1'sx={{fontSize:{md:20, sm:16}}}>Designed for accessibility and to meet diverse needs</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <OurPartners/>
            <Footer />
            </Box>}
        </Template>
        </>
    )
}

export default Overview