/********************************************************
 *                                                      *
 *        Created by: ajpccl - AaronJoel Parker         *
 *        Modified by: gvccl - Gabriel Veit             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import nccl from '../media/ntid-rit-ccl.svg'
import deafHub from '../media/DeafHUB.png'
import nsf from '../media/nsf.png'
import Copyright from './Copyright'
import { Box, Typography, Card, Link } from '@mui/material'
import Grid from '@mui/material/Grid';

const Footer = (props)=>
{

    return(
        <>
            <Box sx={{width:'auto', bottom:0, position:'relative', paddingBottom:4, width:'100%', backgroundColor:'black', color:'primary.light'}}>
                <Grid container spacing={3} elevation={0} justify="flex-start" sx={{
                    position: 'relative',
                    maxWidth: 'lg',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card elevation={0} sx={{marginRight:5, backgroundColor:'black', color:'white'}}>
                            <Box  sx={{width:'100%', mx:'auto'}}>
                                <img src={nccl}/>
                            </Box>
                            <Box sx={{display: {xs: "none", md: "flex"}, marginX: 2}}>
                                <img src={nsf} style={{width:'35%', margin:'auto'}}/>
                                <Typography sx={{textAlign:{md:'Left', xs:'left', marginTop: '7px', marginLeft:'10px'}}}>
                                This material is based upon work supported by the National Science Foundation under Award No. 1811509. Any opinions, 
                                findings and conclusions or recommendations expressed in this material are of the author(s) and do not 
                                necessarily reflect the views of the National Science Foundation.
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Card elevation={0} sx={{backgroundColor:'black', color:'white'}}>
                            <Box sx={{textAlign:{md:'left', xs:'left'}}}>
                                <Box sx={{fontSize:{sm:'16px', xs:'12px'}, paddingBottom:2, marginTop: 1}}>
                                    <Typography variant='h5'><b>Useful Links</b></Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={12}>
                                            <Box sx={{fontSize:{sm:'16px', xs:'12px'}}}>
                                                <Link href='https://www.rit.edu/disclaimer' underline="hover">Disclaimer</Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={12}>
                                            <Box sx={{fontSize:{sm:'16px', xs:'12px'}}}>
                                                <Link href='https://www.rit.edu/copyright-infringement' underline="hover">Copyright Infringement</Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={12}>
                                            <Box sx={{fontSize:{sm:'16px', xs:'12px'}}}>
                                                <Link href='https://www.rit.edu/privacy-statement' underline="hover">Privacy Statement</Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={12}>
                                            <Box sx={{fontSize:{sm:'16px', xs:'12px'}}}>
                                                <Link href='https://www.rit/edu/nondiscrimination' underline="hover">Nondiscrimination</Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={12}>
                                            <Box sx={{fontSize:{sm:'16px', xs:'12px'}}}>
                                                <Link href='https://www.rit.edu/emergency-information' underline="hover">Emergency Information</Link>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Copyright/>
        </>
    )
}

export default Footer