/********************************************\
*   Created by: njcdhb - Nicholas Capadano   *
\********************************************/

import React from 'react'
import IconButton from '@mui/material/IconButton';
import ButtonStyled from '../components/ButtonStyled';
import { format } from 'date-fns'
import { Dialog, Box, DialogTitle, DialogActions, DialogContent, Modal, DialogContentText, useMediaQuery } from '@mui/material'
import ListOfTranslators from './ListOfTranslators';
import { Access, Status, Category } from '../utilities/Enums'
import { Close, Cancel, Comment, ChangeCircle, GroupAdd } from '@mui/icons-material'
import '../styles/table.css';
import '../styles/icon.css'
import { Typography, useTheme } from '@mui/material';
import { TimeOperators, TimeComparator } from '../utilities/Time';
import SessionInfoTemplate from '../components/SessionInfoTemplate';
import MoreOptionsMenu from '../components/MoreOptionsMenu';
import InfoChip from '../components/InfoChip';
import {useSelector} from 'react-redux'
import { useRetrieveTicketsQuery, useReassignmentMutation, useAssignTranslatorsMutation, useRejectRequestMutation } from '../endpoints/agency.endpoint'
import { RequestsContext } from '../utilities/Contexts';
import { baseColumns } from '../utilities/BaseColumns';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GridToolbar, DataGridPro } from '@mui/x-data-grid-pro';
import { remove } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AssignTranslatorsContext } from '../utilities/Contexts';

const sizes = {
    'compact':'small',
    'standard':'medium',
    'comfortable':'large'
}

//#region Requests
const Requests = () => {

    const auth = useSelector(state=>state.auth)
    const {data:tickets, isSuccess} = useRetrieveTicketsQuery(auth.token)
    const [requestRows, setRequestRows] = React.useState([])
    const theme = useTheme()
    const smallWidth = useMediaQuery(theme.breakpoints.down('sm'))
    const [density, setDensity] = React.useState('standard')

    React.useEffect(()=>{
        if(isSuccess){

            var arr = []
            tickets.result.map((info, index)=>{
                console.log(info)

                
                const statusId = info.status_id;
                const status = statusId === 3 ? 2 : statusId;

                arr.push({
                    id: index, 
                    requestNum: info.req.number,
                    title: info.req.sess.title,
                    accessType: Access[info.req.access_id],
                    date: info.req.date,
                    startTime: info.req.sess.start_time,
                    endTime: info.req.sess.end_time,
                    status: status,
                    dateUpdated: info.req.createdAt,
                    info: {info}
                })
                
                return arr
            })

            setRequestRows(arr)
    }
    },[isSuccess, tickets])

    const removeRow = React.useCallback(
        (index) => {
            console.log('Removing...')
            setRequestRows((prevRows) => prevRows.filter((item) => item.id !== index));
        }, [])

    const columns = React.useMemo(()=>[
        ...baseColumns,
        {
            field:'dateUpdated',
            headerName:'Updated On',
            width: 160, 
            type: 'dateTime',
            valueFormatter: (value) => {
                return format(new Date(value), "MM/dd/yyyy, hh:mm aaaaa'm'")
            }
        },
        {
            field:'actions',
            type:'actions',
            width: `${smallWidth ? 60:160}`,
            getActions:(params)=>[
                <ActionItems
                    info={params.row.info.info}
                    index={params.row.id}
                    removeRow={removeRow}
                    status={params.row.status}
                    theme={theme}
                    density={density}
                    smallWidth={smallWidth}/>
            ]
        }
    ],[removeRow, smallWidth, theme, density])

    return (
        <ErrorBoundary fallback={<div>Whoops</div>}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
            <DataGridPro
            rows={requestRows}
            columns={columns}
            slots={{ toolbar: GridToolbar}}
            onDensityChange={(newDensity)=>setDensity(newDensity)}
            initialState={{
                pinnedColumns: { right: ['actions'] },
                pagination: {
                    paginationModel: {
                        pageSize: 50,
                    },
                },
                sorting: {
                    sortModel: [{field: 'dateUpdated', sort: 'desc'}]
                }
            }}
            pageSizeOptions={[25, 50, 100]}
            pagination
            sx={{
                '& .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight, .MuiDataGrid-filler--pinnedRight': {
                    borderLeft: 'none',
                }
            }}/>
        </div>
        </LocalizationProvider>
        </ErrorBoundary>
    )
}

export default Requests

//#endregion

//#region ActionItems
const ActionItems=(props)=>{
    const { info } = props;
    
    const request = info.req
    const access = request.access_id
    console.log(request)
    const privlegedMembers = info.req.bus_ag[0].bus.pri_mem
    const businessName = info.req.bus_ag[0].bus.name
    const [translator, setTranslator] = React.useState({})
    const [list, setList] = React.useState([])
    
    const [openAssign, setOpenAssign] = React.useState(false);
    const [openError, setOpenError] = React.useState(false)
    const [openReject, setOpenReject] = React.useState(false)

    console.log(Status[info.status_id])

    const [date] = React.useState(format(new Date(request.date), "MM/dd/yyyy"))
    const [startTime] = React.useState(format(new Date(request.sess.start_time), "hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(request.sess.end_time), "hh:mm aaaaa'm'"))
    const [businessAgreementId, setBusinessAgreementId] = React.useState(request.bus_ag[0].id)
    const [clientNames, setClientNames] = React.useState(privlegedMembers.map((privilegedMember)=>`${privilegedMember.mem.first_name} ${privilegedMember.mem.last_name}`))
    const [clientEmails, setClientEmails] = React.useState(privlegedMembers.map((member)=>member.email))

    const auth = useSelector(state=>state.auth)
    const [assignTranslators] = useAssignTranslatorsMutation()
    const [rejectRequest] = useRejectRequestMutation()
    const [reassignment] = useReassignmentMutation()

    const theme = props.theme;
    const smallWidth = props.smallWidth;
    const [menuItems, setMenuItems] = React.useState([])

    const oopsStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const handleOpenAssign = React.useCallback(() => {

        setTranslator(request.number)
        setTimeout(() => {

            setOpenAssign(true);
        }, 100)

    },[request.number])

    const handleCloseAssign = () => {
        setOpenAssign(false);
    };

    const handleOpenReject = () => {

        setTimeout(() => {

            setOpenReject(true);
        }, 100)

    };

    const handleCloseReject = () => {
        setOpenReject(false)
    }

    const handleCloseError = () => {
        setOpenError(false)
    }

    React.useEffect(() => {
        console.log(list)
    }, [list])


    const confirm = () => {

        console.log(list)

        if (list.length > 0) {
            assignTranslators({
                list: list,
                requestId:info.req.id,
                token: auth.token
            })
            .then((res) => {
                
                handleCloseAssign()
            })
            .catch((err) => {
                console.log(err.message)
            })
        }
        else {
            console.log('List is empty')
            setOpenError(true)
        }
    };

    const update = () => {
        console.log('Confirm update list')
        console.log(list)

        if (list.length > 0) {

            reassignment({list:list,businessAgreementId:request.bus_ag[0].id})
            .then((res) => {
                handleCloseAssign()
                if (res.status === 200) {
                
                console.log('sucessfully created services')
                }
            })
            .catch((err) => {
                 console.log(err)
             })
        }
    }

    const reject = () => {

        rejectRequest({
            token: auth.token,
            ticket: info.ticket_id
        })
        .then((res) => {
            // props.removeRow(props.index)
            handleCloseReject()
        })
        .catch((err) => {
             console.log(err.message)
         })
    }

    React.useEffect(()=>{
        var menuArr=[]

        if (smallWidth) {
            menuArr.push({action:handleOpenAssign, title: 'Assign', icon: <GroupAdd />})
        }
        menuArr.push({action: ()=> {}, title: 'Request Change', icon: <ChangeCircle />, divider: smallWidth });
        menuArr.push({action: ()=> {}, title: 'Add a Note', icon: <Comment /> });
        menuArr.push({action:handleOpenReject, title: 'Reject Request', icon: <Cancel />, divider: true });
        setMenuItems(menuArr)

    },[smallWidth, handleOpenAssign])

    const statusFlag=()=>{
        const flag = Status[info.status_id].label==='Assigned'
        console.log(flag)
        return flag
    }

    return (
        <React.Fragment>
            {!smallWidth && <ButtonStyled action={handleOpenAssign} title={statusFlag()?'Edit':'Assign'} size={sizes[props.density]} width={'80px'} color={statusFlag()?'info':'primary'}/>}
            <Dialog
                fullScreen
                open={openAssign}
                className='fullscreen-dialog'
                onClose={handleCloseAssign}>
                <DialogTitle variant='dialogTitle'  textTransform={'capitalize'}>
                    Assign {`${Access[access]}s`}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAssign}
                    className='closeButton'>
                    <Close />
                </IconButton>
                <DialogContent className='full-dialog-content' >
                    <SessionInfoTemplate
                        title={request.sess.title}
                        accessType={Access[access]}
                        requestNum={request.number}
                        date={date}
                        startTime={startTime}
                        endTime={endTime}
                        category={Category[request.sess.category_id]}
                        organization={businessName}
                        //temporary
                        clientName={
                            // clientName
                            'Manager William Smith'
                        }
                        //temporary
                        clientEmail={
                            // clientEmail
                            'hospital_mgr@outlook.com'
                        }
                        status={Status[info.status_id]}

                        //temporary
                        coordClients={[{"first_name":`${request.sub[0].mem.first_name}` ,"last_name":`${request.sub[0].mem.last_name}`}]}
                        
                        description={request.sess.description}
                        notes={request.sess.notes}
                        role={auth.role}
                        theme={theme} />
                    <Box sx={{ flexGrow: 1 }}>
                        <AssignTranslatorsContext.Provider value={{list, setList, request, access, businessAgreementId}}>
                            <ListOfTranslators />
                        </AssignTranslatorsContext.Provider>
                        <Modal
                            open={openError}
                            onClose={handleCloseError}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description">
                            <Box sx={{ ...oopsStyle, width: 400 }}>
                                <Typography variant='h2' sx={{ textAlign: 'center' }}>OOPS!</Typography>
                                <Typography variant='body1'>No translator has been selected. If you want to cancel, please click on the cancel button.</Typography>
                            </Box>
                        </Modal>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled variant='outlined' color='error' action={handleCloseAssign} title='Cancel' />
                    <ButtonStyled variant="contained" color='primary' action={()=>{Status[info.status_id].label==='Assigned'?update():confirm()}} title='Confirm' />
                </DialogActions>
            </Dialog>
            {/* <ButtonStyled variant="outlined" color='error' action={handleOpenReject} title='Reject' /> */}
            <MoreOptionsMenu menuItems={menuItems} />
            <Dialog
                open={openReject}
                onClose={handleCloseReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This cancellation request will be permanent. Please click below to confirm your action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled variant="outlined" color='secondary' action={handleCloseReject} title='Go Back' />
                    <ButtonStyled variant="contained" color='error' action={reject} autoFocus title='Reject' />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}
//#endregion