/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import {FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import {Status } from '../utilities/Enums'

const StatusBar = (props)=>
{
    const [dropValue, setDropValue] = React.useState()

    let dropHandler = (e)=>{

        setDropValue(e.target.value)

        props.setInputText(Status[e.target.value].label)
    }

    return (
        <>
        <FormControl sx={{width:'15em'}}>
            <InputLabel>Status</InputLabel>
            <Select
            value={dropValue}
            onChange={dropHandler}
            label='Status'>
                <MenuItem value={1}>Unreviewed</MenuItem>
                <MenuItem value={2}>Pending</MenuItem>
                <MenuItem value={4}>Assigned</MenuItem>
                <MenuItem value={5}>Unassigned</MenuItem>
                <MenuItem value={6}>Cancelled</MenuItem>
            </Select>
        </FormControl>
        </>
    )
}

export default StatusBar