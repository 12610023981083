/********************************************************
 *                                                      *
 *    Created by: ajpccl - Aaron Parker                 *
 *                                                      *
 ********************************************************/

import {useSelector} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { darkTheme, lightTheme } from './themes/theme'
import Home from './routes/Home'
import Service from './routes/Service'
import React,{useEffect} from 'react'
import { LicenseInfo } from '@mui/x-license'

import './App.css'

const App=()=> {
  
  LicenseInfo.setLicenseKey(
    '0accbaf950b5aa1432043ea43247cdf3Tz05MTY1MCxFPTE3NDg3MjA2ODEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
  )

  const theme = useSelector((state)=>state.theme)

  useEffect(()=>{

  },[theme])

  return (
    <ThemeProvider theme={theme.darkTheme?darkTheme:lightTheme}>
      <CssBaseline enableColorScheme/>
      <Service/>
      <Home/>
    </ThemeProvider>
  );
}

export default App;
