import * as React from 'react';
import { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { IconButton, ListItemIcon, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { SessionCardContext } from '../utilities/Contexts';

const MoreOptionsMenu = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useSelector(state=>state.theme)
  

  // const [menuItems, setMenuItems] = React.useState([])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
    props.hoverLeave?.();
  };

  const handleItemClick = (menuItem) => {
    menuItem.action();
    handleClose();
  };

  const getMenuItems = () => {

    var arr = []

    // console.log(props.menuItems)

    props.menuItems.map((menuItem) => {

      if (menuItem) {
        var menuI = <MenuItem 
          key={menuItem.title}
          onClick={() => { handleItemClick(menuItem) }}
          disabled = {menuItem.disabled}
        >
          {menuItem.icon && 
            <ListItemIcon sx={{'& > .MuiSvgIcon-root':{
              fontSize: '1.25rem'
            }}}>
              {menuItem.icon}
            </ListItemIcon>
          }
          {menuItem.title}
        </MenuItem>
        
        if (menuItem.divider) {
          arr.push(<Divider key={`divider_${menuItem.title}`} />)
        }
        arr.push(menuI)
      }

    })

    return arr

  }

  // React.useEffect(() => {
  //   getMenuItems()
  // }, [])

  return (
    <div>
      <Tooltip title='Options' >
        <IconButton
          id="more-button"
          aria-label="More Options"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVert/>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      
        sx={{
          '& .MuiPaper-root': {
            minWidth: 180
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        {getMenuItems()}
      </Menu>
    </div>
  );
}

export default MoreOptionsMenu