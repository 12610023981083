import * as React from 'react'
import {Box, Typography} from '@mui/material'

const Copyright=()=>
{
    return(
    <Box sx={{backgroundColor:'#D0D3D4', color:'black', textAlign:'center',p:3}}>
        <Typography varient='h4'>Copyright © <b>Rochester Institute of Technology.</b> All Rights Reserved.</Typography>
    </Box>
    )
}

export default Copyright