import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const Notifications = ()=>{

    return(
        <>
        <ErrorBoundary fallback={<div>Whoops</div>}>
        
        </ErrorBoundary>
        </>
    )
}

export default Notifications