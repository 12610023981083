/**********************************************\
*    Created by: njcdhb - Nicholas Capadano    *
\**********************************************/

/*
  Used to sort the time fields of DataGrid (DataGrid does not support time natively yet)
*/

import { TextField } from '@mui/material';
import { getGridDateOperators } from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';


const TimeComparator = (v1, v2) => {
    const getTimeValue = (date) => {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return hours * 3600 + minutes * 60 + seconds;
    };

    const time1 = getTimeValue(v1);
    const time2 = getTimeValue(v2);

    return time1 - time2;
};




/*
    Used to compare the time fields for filtering in DataGrid (DataGrid does not support time natively yet)
    It modifies the DateOperator
*/
const TimeOperators = () => {

    const dateOperators = getGridDateOperators(true); // Get the existing date operators



    function GridFilterTimeInput(props) {
        const { item, applyValue } = props;

        const handleFilterChange = (event) => {
            const newValue = event.target.value;
            applyValue({ ...item, value: newValue });
        };
    
        return (
            <TextField
                type="time"
                value={item.value || ''}
                autoFocus
                label="Time"
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // Step value for 5-minute intervals
                onChange={handleFilterChange}
            />
        );
    }

    // Modify each date operator to handle time comparisons
    return dateOperators.map((operator) => ({
        ...operator,
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                console.log("null")
                return null;
            }

            return ( value ) => {
                if (!value) {
                    return false;
                }

                const filterTime = parse(filterItem.value, 'HH:mm', new Date());
                const cellTime = parse(format(new Date(value), 'HH:mm'), 'HH:mm', new Date());
                

                switch (operator.value) {
                    case 'is':
                        return cellTime.getTime() === filterTime.getTime();
                    case 'not':
                        return cellTime.getTime() !== filterTime.getTime();
                    case 'after':
                        return cellTime.getTime() > filterTime.getTime();
                    case 'onOrAfter':
                        return cellTime.getTime() >= filterTime.getTime();
                    case 'before':
                        return cellTime.getTime() < filterTime.getTime();
                    case 'onOrBefore':
                        return cellTime.getTime() <= filterTime.getTime();
                    case 'isEmpty':
                        return value == null;
                    case 'isNotEmpty':
                        return value != null;
                    default:
                        return false;
                }
            };
        },
        InputComponent: GridFilterTimeInput,
    }));
}


export {TimeComparator, TimeOperators}
