/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import * as React from 'react'

import { Fab } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'
import ToggleSwitch from './ToggleSwitch'

const AccessButton = (props)=>
{
    return(
        <ToggleSwitch/>
    )
}

export default AccessButton