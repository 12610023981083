import React, {useContext, useCallback} from 'react'
import NewEvent from '../components/NewEvent'
import NewEventSession from '../components/NewEventSession'
import ButtonStyled from '../components/ButtonStyled'
import InfoChip from '../components/InfoChip'
import {format} from 'date-fns'
import NewSessionT from '../components/NewSessionT'
import SessionInfoTemplate from '../components/SessionInfoTemplate'
import {Cancel, Comment, DeveloperBoard, ErrorOutlineOutlined} from '@mui/icons-material'
import {Box, Paper, Button, Stack, Icon} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import MoreOptionsMenu from '../components/MoreOptionsMenu'
import { ErrorBoundary } from 'react-error-boundary'
import Error from '@mui/icons-material/Error';
import { Status, Access, Category } from '../utilities/Enums'
import {useSelector, useDispatch} from 'react-redux'

import { 
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector } from '@mui/x-data-grid-pro'
// import {LocalizationProvider, AdapterDatefns } from '@mui/x-date-pickers'
import { useRetrieveScheduledSessionsQuery, useRetrieveScheduleEventQuery, useCancelEventSessionMutation, useDeleteOrgSessionMutation } from '../endpoints/org.endpoint'
import {EventsContext, AgencyContext } from '../utilities/Contexts'
import AgencyInfoTemplate from '../components/AgencyInfoTemplate'
import Add from '@mui/icons-material/Add'

const sizes = {
    'compact':'small',
    'standard':'medium',
    'comfortable':'large'
}

const SchedulePage = ()=>{


    const [open, setOpen] = React.useState(false)
    const [open1, setOpen1] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [rows, setRows] = React.useState([])
    const [eventSet, setEventSet] = React.useState({})
    const [request, setRequest] = React.useState(null)
    const [ticketRequests] = React.useState(null)

    const handleDialog=()=>
    {   
        console.log(open)
        setOpen(true)
    }

    const handleDialog1=useCallback((_eventSet)=>{

        setEventSet(_eventSet)
        setOpen1(true)
    },[])

    const handleDialog2=useCallback((_request)=>{
        setRequest(_request)
        setOpen2(true)
    },[])

    return(
        <EventsContext.Provider value={{rows, setRows, handleDialog2}}>
            <ErrorBoundary fallback={<Error fontSize='large'/>}>
                <Stack direction={'column'} spacing={0}>
                    <NewEvent open={open} setOpen={setOpen}/>
                    {eventSet && <NewEventSession open={open1} setOpen={setOpen1} eventSet={eventSet}/>}
                    <AgencyContext.Provider value={{open2, setOpen2, request, ticketRequests}}>
                        {request &&<AgencyInfoTemplate/>}
                    </AgencyContext.Provider>
                    <ScheduleGrid handleDialog={handleDialog} handleDialog1={handleDialog1} handleDialog2={handleDialog2}/>
                </Stack>
            </ErrorBoundary>
        </EventsContext.Provider>
    )
}

const ScheduleGrid = (props)=>{

    const auth = useSelector((state)=>state.auth)
    
    const {rows, setRows, handleDialog2} = useContext(EventsContext)
    const [density, setDensity] = React.useState('standard')


    const {
        data:events,
        isLoading,
        isSuccess
    } = useRetrieveScheduleEventQuery(auth.token)

    function generateEventSessions(eventSessions) {

        console.log(eventSessions)
    
        return eventSessions.map((eventSession, index)=>({
            id:index,
            sessionId:eventSession.sess.id,
            title:eventSession.sess.title,
            location:eventSession.sublocation,
            description:eventSession.sess.description,
            categoryId:eventSession.sess.category_id,
            accessType:(auth.role===5 || auth.hasAccess)?eventSession.sess.req[0].access_id:null,
            startTime:eventSession.sess.start_time,
            endTime:eventSession.sess.end_time,
            statusId:(auth.role===5 || auth.hasAccess)?eventSession.sess.req[0].bus_ag[0].status_id:1,
            requests:(auth.role===5 || auth.hasAccess)?generateRequests(eventSession.sess.req):null
        }))
    }
    
    function generateRequests(requests){
    
        console.log(requests)
    
        return requests.map((request,index)=>({
            id:index,
            requestId:request.id,
            accessId:request.access_id,
            number:request.number,
            date:request.date,
            businessAgreementId:request.bus_ag[0].id,
            status:request.bus_ag[0].serve===null?true:false
        }))
    }


    const fetchingData= React.useCallback(()=>{
        if(isLoading)
        {
            
        }
        if(isSuccess)
        {
            let arr = []
    
            events.result.map((event,index)=>{
    
                console.log(event)
    
                arr.push({
                    id:index,
                    eventId:event.id,
                    title:event.title,
                    venue:event.location,
                    date:event.date,
                    startTime:event.start_time,
                    endTime:event.end_time,
                    eventSessions:event.eve_ses.length>0?generateEventSessions(event.eve_ses):[],
                    handleDialog2:handleDialog2
                })
                return event
            })
    
            setRows(arr)
        }
    },[isLoading, isSuccess, events, handleDialog2, setRows])
    

    React.useEffect(()=>{
        fetchingData()
    },[fetchingData])

    const columns = React.useMemo(()=>[{
        field:'title', headerName:'Title', width:200
    },
    {
        field:'venue', headerName:'Venue', width:200
    },
    {
        field:'date', 
        headerName:'Date', 
        width:160,
        type:'date',
        valueFormatter: (data) => {

            return format(new Date(data), 'MM/dd/yyyy')
        }
    },
    {
        field:'startTime', 
        headerName:'Start Time', 
        type:'dateTime',
        width:160,
        valueFormatter:(data)=>{
            return format(new Date(data),"hh:mm aaaaa'm'")
        }
    },
    {
        field:'endTime', 
        headerName:'End Time',
        type:'dateTime',
        width: 160,
        valueFormatter: (data) => {
            return format(new Date(data), "hh:mm aaaaa'm'")
        }
    },
    {
        field:'actions',
        type:'actions',
        hideable: false,
        width:200,
        getActions:(params)=>[
            <ActionCol 
            density={density} 
            eventSet={{'eventId':params.row.eventId,'startTime':params.row.startTime,'endTime':params.row.endTime, 'date':params.row.date}} 
            handleDialog1={props.handleDialog1}/>
        ]
    }],[density, props.handleDialog1])

    const CustomToolBar=()=>{
        return(
            <GridToolbarContainer sx={{m:1}}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector
                slotProps={{ 
                    tooltip:{title:'Change density'} 
                }}/>
                <GridToolbarExport
                slotProps={{
                    tooltip: { title: 'Export data' },
                }}/>
                <Box sx={{flexGrow:1}}/>
                <ButtonStyled startIcon={<Add/>} action={props.handleDialog} title='NEW EVENT' size={'medium'} backgroundColor={'lightgray'} textColor={'#434343'}/>
            </GridToolbarContainer>
        )
    }

    const DetailPanelContent=(props)=>{

        var columnsSecond

        const CustomToolBar2=()=>{
            return(
                <GridToolbarContainer sx={{m:1}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                    }}/>
                </GridToolbarContainer>
            )
        }

        if(auth.role===5 || auth.hasAccess)
        {
            let columns2 = [
                {field:'title', headerName:'Title', width:200},
            {field:'location', headerName:'Location', width:200},
            {
                field:'accessType', 
                headerName:'Access Type', 
                width:130,
                valueFormatter:(data)=>{
                    console.log(data)
                    return Access[data]
                }
            },
            {
                field:'startTime', 
                headerName:'Start Time',
                width:130,
                valueFormatter: (data) => {
                    return format(new Date(data), "hh:mm aaaaa'm'")
                }
            },
            {
                field:'endTime', 
                headerName:'End Time',
                width:130,
                valueFormatter: (data) => {
                    return format(new Date(data), "hh:mm aaaaa'm'")
                }
            },
            { 
                field: 'statusId', 
                headerName: 'Status', 
                width: 100,
                renderCell: (params) => {
                    console.log(params.value)
                    let _status = params.value === 1 ? 2 : params.value;
                    const status = Status[_status];
                    return (<InfoChip color={status.color} label={status.label} size='small' icon={status.label === 'Unassigned' ? <ErrorOutlineOutlined /> : null} />);
                },
                type: 'singleSelect',
                valueOptions: [
                    { value: 1, label: 'Unreviewed' },
                    { value: 2, label: 'Pending' },
                    { value: 4, label: 'Assigned' },
                    { value: 5, label: 'Unassigned' },
                    { value: 6, label: 'Cancelled' },
                ],
            },
            {
                field:'actions2',
                type:'actions',
                width:160,
                getActions:(params)=>[
                    <ActionCol2 
                    density={density}
                    test={params}
                    eventSession={params.row}
                    handleDialog2={props.row.handleDialog2}/>
                ]
            }]

            columnsSecond = columns2
        }
        else
        {
            let columns2 = [
                {field:'title', headerName:'Title', width:200},
            {field:'location', headerName:'Location', width:200},
            {
                field:'startTime', 
                headerName:'Start Time',
                width:130,
                valueFormatter: (data) => {
                    return format(new Date(data), "hh:mm aaaaa'm'")
                }
            },
            {
                field:'endTime', 
                headerName:'End Time',
                width:130,
                valueFormatter: (data) => {
                    return format(new Date(data), "hh:mm aaaaa'm'")
                }
            },
            { 
                field: 'statusId', 
                headerName: 'Status', 
                width: 100,
                renderCell: (params) => {
                    console.log(params.value)
                    let _status = (auth.role===5 || auth.hasAccess) && params.value === 1 ? 2 : params.value;

                    const status = Status[_status];
                    return (<InfoChip color={status.color} label={status.label} icon={status.label === 'Unassigned' ? <ErrorOutlineOutlined /> : null} />);
                },
                type: 'singleSelect',
                valueOptions: [
                    { value: 1, label: 'Unreviewed' },
                    { value: 2, label: 'Pending' },
                    { value: 4, label: 'Assigned' },
                    { value: 5, label: 'Unassigned' },
                    { value: 6, label: 'Cancelled' },
                ],
            }]
            columnsSecond = columns2
        }

        return(
            <Stack
            sx={{py:2, height:'100%', boxSizing:'border-box'}}
            direction={'column'}>
                <Paper sx={{p:1, height:'100%'}}>
                <Stack direction={'column'} spacing={1} sx={{height:1}}>
                    <DataGridPro
                        density={density}
                        initialState={{
                            pinnedColumns: { right: ['actions2'] },
                            pagination:{
                                paginationModel: {
                                    pageSize:5
                                }
                            },
                            sorting: {
                                sortModel: [{field: 'dateCreated', sort: 'desc'}]
                            }
                        }}
                        columns={columnsSecond}
                        slots={{ toolbar: CustomToolBar2 }}
                        rows={props.row.eventSessions}
                        pageSizeOptions={[5, 10, 15]}
                        pagination
                        sx={{
                            '& .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight, .MuiDataGrid-filler--pinnedRight': {
                                borderLeft: 'none',
                                width:'auto'
                            }
                        }}
                        />
                </Stack>
                </Paper>
            </Stack>
        )
    }

    return (
        <Box sx={{width:'100%', height:400}}>
            <DataGridPro
                autoHeight
                initialState={{
                    pinnedColumns: { right: ['actions'] },
                    pagination:{
                        paginationModel: {
                            pageSize:25
                        }
                    },
                    sorting: {
                        sortModel: [{field: 'dateCreated', sort: 'desc'}]
                    }
                }}
                density={density}
                onDensityChange={(newDensity)=>setDensity(newDensity)}
                columns={columns}
                rows={rows}
                slots={{ toolbar: CustomToolBar }}
                pagination
                getDetailPanelContent={DetailPanelContent}
                sx={{
                    '& .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight, .MuiDataGrid-filler--pinnedRight': {
                        borderLeft: 'none',
                    }
                }}/>
        </Box>
    )
}

const ActionCol = (props)=>{

    console.log(props.density)

    return(
        <>
            <ButtonStyled title='New Session' action={()=>{props.handleDialog1(props.eventSet)}} size={sizes[props.density]}/>
        </>
    )
}

const ActionCol2 = (props)=>{

    console.log(props.eventSession)

    const request = {
        'id':props.eventSession.requests[0].requestId,
        'accessType':props.eventSession.accessType,
        'description':props.eventSession.description,
        'notes':'No notes',
        'location':props.eventSession.location,
        'categoryId':props.eventSession.categoryId,
        'date':props.eventSession.requests[0].date,
        'startTime':props.eventSession.startTime, 
        'endTime':props.eventSession.endTime,
        'number':props.eventSession.requests[0].number,
        'title':props.eventSession.title,
        'statusId':props.eventSession.statusId,
        'businessAgreementId':props.eventSession.requests[0].businessAgreementId
    }

    const bool = props.eventSession.statusId===4

    return(
        <>
            <ButtonStyled title={bool?'Edit':'Assign'} color={bool?'info':'primary'} action={()=>{props.handleDialog2(request)}} width={'80px'} size={sizes[props.density]}/>
            <MoreOptionsMenu
                menuItems={[
                    {action:()=>{} , title:'Post on board',icon:<DeveloperBoard/>}
                ]}
            />
        </>
    )
}


export default SchedulePage