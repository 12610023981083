/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import { createSlice } from "@reduxjs/toolkit";

import Cookies from 'js-cookie'
import { act } from "react";


export const authSlice = createSlice({

   name:'auth',
   initialState:{
       isAuthenticated:/true/.test(Cookies.get('isAuthenticated')),
       token:Cookies.get('token')||'',
       role:parseInt(Cookies.get('role'))||0,
       hasAccess:/true/.test(Cookies.get('hasAccess'))
   },
   reducers:{
       LoggingIn:(state,action)=>{

        state.isAuthenticated = true
        state.token = action.payload.token
        state.role = action.payload.redirect
        state.hasAccess = action.payload.hasAccess
        Cookies.set('isAuthenticated',true,{expires:5756})
        Cookies.set('token',action.payload.token,{expires:5756})
        Cookies.set('role',action.payload.redirect,{expires:5756})
        Cookies.set('hasAccess', action.payload.hasAccess,{expires:5756})
       },
       LoggingOut:(state)=>{
        state.isAuthenticated = false
        state.token = ''
        state.role = 0
        state.hasAccess = false
        Cookies.set('isAuthenticated',false)
        Cookies.set('token','')
        Cookies.set('role',0)
        Cookies.set('hasAccess',false)
       },
   }
})

export const { LoggingIn, LoggingOut } = authSlice.actions

export default authSlice.reducer