/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import React, {useContext} from 'react';
import { Typography, Box, TextField, Button, Grid, Paper, Avatar,Fade, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Template from '../components/Template'
import {Navigate} from 'react-router-dom'
import ButtonStyled from '../components/ButtonStyled';
import {useDispatch, useSelector} from 'react-redux'
import ToggleSwitch from '../components/ToggleSwitch'
import { useLogInMutation } from '../endpoints/member.endpoint';
import { LoggingIn } from '../slices/auth.slice'



const LogIn = ()=>
{
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [opacity, setOpacity] = React.useState(0)
    const [errorMsg, setErrorMsg] = React.useState('Invalid Credentials')
    const [scale, setScale] = React.useState('scale(1.0)')
    const [showPassword, setShowPassword] = React.useState(false)
    const [isPending, startTransition] = React.useTransition()
    const dispatch = useDispatch()
    const auth = useSelector((state)=>state.auth)
    const theme = useSelector((state)=>state.theme)
    const [logIn,{isLoading}] = useLogInMutation()

    function onUsernameChange(e)
    {
        console.log('Username: '+e.target.value)
        setUsername(e.target.value)
    }

    function onPasswordVisibility()
    {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    function onPasswordChange(e)
    {
        console.log('Password: '+e.target.value)
        setPassword(e.target.value)
    }

    function InvalidCredentials(msg)
    {
        setErrorMsg(msg)
        setOpacity(1)
        setScale('scale(1.1)')
        
        setTimeout(()=>{
            setOpacity(0)
            setScale('scale(1.0)')
        },2000)
    }

    const checkEnter = async(e)=>
    {
        console.log('check the key')
        if(e.key === 'Enter')
        {
            await logIn({username:`${username}`.toLowerCase(), password:password}).unwrap()
        }
    }

    const LogInTask =async()=>{
        console.log(username)
        const result = await logIn({username:`${username}`.toLowerCase(), password:password}).unwrap()
        const {token, redirect} = result
        console.log(token)

        dispatch(LoggingIn({token, redirect}))


    }

    React.useEffect(()=>{

        document.title='Sign In'

    },[])

    return(
        <Box sx={{p:0,m:0}} onKeyDown={checkEnter}>
        <Template>
            {auth.isAuthenticated?<Navigate replace to='/'/>:
            <Grid container component="main">
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        background:theme.darkTheme==='false'?'linear-gradient(47deg,#84BD00,#C4D600)':'linear-gradient(47deg,#C4D600,#84BD00)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>

                <Grid item xs={12} sm={8} md={5} elevation={6} square>
                    <Box sx={{
                        p:'auto', 
                        height:'100vh',
                        overflow:'none',
                        overflowY:'none'}}>
                        <Box sx={{ textAlign:'center'}}>
                            <Typography variant='h2' sx={{m:2}}>Sign In</Typography>
                        </Box>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1},
                                textAlign:'center',
                                m:'auto',
                                p:3,
                                borderRadius:5,
                                width:350,
                                borderColor:'#84BD00',
                                alignContent:'center'
                            }}
                            noValidate>
                            <FormControl sx={{m:1, width:'22ch'}} variant='outlined'>
                                <InputLabel htmlFor='outlined-adornment-username'>Username</InputLabel>
                                <OutlinedInput
                                id='outlined-adornment-username'
                                value={username}
                                require={true}
                                onChange={onUsernameChange}/>
                            </FormControl>
                            <FormControl sx={{m:1, width:'22ch'}} variant='outlined'>
                                <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
                                <OutlinedInput
                                id='outlined-adornment-password'
                                type={showPassword?'text':'password'}
                                required={true}
                                value={password}
                                onChange={onPasswordChange}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={onPasswordVisibility}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'>
                                            {showPassword?<Visibility/>:<VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }/>
                            </FormControl>
                            <Typography variant='h5' sx={{color:'red',opacity:opacity,transition:'.5s',transform:scale, textAlign:'center'}}>{errorMsg}</Typography>
                            <ButtonStyled action={LogInTask} title='Sign In' marginTop={0} width={180}/>
                            <br/>
                            <Button style={{color:'#84BD00', fontWeight:'bold'}} href='/registration' sx={{mt:2}}>Create an account</Button>
                            <Button style={{color:'#84BD00', fontWeight:'bold'}} href='/forgotUser' sx={{}}>Forgot Username?</Button>
                            <Button style={{color:'#84BD00', fontWeight:'bold'}} href='/forgotPass' sx={{}}>Forgot Password?</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>}
            <ToggleSwitch/>
        </Template>
    </Box>
    )
}

export default LogIn