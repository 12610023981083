/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import {createSlice} from "@reduxjs/toolkit";

import Cookies from 'js-cookie'

export const streamSlice = createSlice({
    name:'stream',
    initialState:{
        title:Cookies.get('title')||'',
        name:Cookies.get('name')||'',
        position:Cookies.get('position')||'',
        streamId:Cookies.get('streamId')||'',
        sessionId:Cookies.get('sessionId')||'',
        streamToken:Cookies.get('streamToken')||''
    },
    reducers:{
        setStream:(state,action)=>{
            state.title = action.payload.title
            state.name = action.payload.name
            state.position = action.payload.position
            state.streamId = action.payload.streamId
            state.sessionId = action.payload.sessionId
            state.streamToken = action.payload.streamToken
            Cookies.set('title',action.payload.title);
            Cookies.set('name',action.payload.name);
            Cookies.set('streamId',action.payload.streamId);
            Cookies.set('sessionId',action.payload.sessionId);
            Cookies.set('streamToken',action.payload.streamToken);
            Cookies.set('position',action.payload.position);
        },
        resetStream:(state)=>{
            state.title = null
            state.name = null
            state.position = null
            state.streamId = null
            state.sessionId = null
            state.streamToken = null
        }
    }
})

export const {setStream,resetStream} = streamSlice.actions

export default streamSlice.reducer