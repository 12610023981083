/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import Cookies from 'js-cookie'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const Permissions = (props)=>
{

    const handleBlock=()=>
    {
        
        props.setOpen(false)
    }

    const handleAllow = ()=>
    {
        Cookies.set('user_cookie_consent',1,30)
        props.setOpen(false)
    }

    React.useEffect(()=>{

    },[])


    return(
        <Dialog
        open={props.open}>
            <DialogTitle
            id="alert-dialog-title">
                {"Using Cookies"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                id="alert-dialog-description">
                    We are using cookies to help enhanced your experiences 
                    surfing the website. We will not collect 
                    or track your activity.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBlock}>Block</Button>
                <Button onClick={handleAllow}>Allow</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Permissions