/********************************************************
 *                                                      *
 *    Created by: njcdhbs - Nicholas Capadano           *
 *    Help by: ajpccl - AaronJoel Parker                *
 *                                                      *
 ********************************************************/

import * as React from "react";
import axios from "axios";
import {useSelector} from 'react-redux'
import { Navigate } from 'react-router-dom'

import {
  Grid,
  Card,
  Typography,
  Link,
  Box,
  Stack,
  CardContent,
  Button,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Template from "../components/Template";
import ToggleSwitch from "../components/ToggleSwitch";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@mui/material/styles";

import "../styles/markdown.css";

//This is react's hook
const Help = () => {
  const [instructions, setInstructions] = React.useState([]);
  const auth = useSelector((state) => state.auth);
  // const [instructionTitles, setInstructionTitles] = React.useState([])

  //this is just a function
  const getInstructions = () => {

    axios.get(process.env.REACT_APP_LOCALHOST+`/help_pages/?id=${auth.token}`)
    .then((res)=>{
       if(res.status===200)
       {
          return res.data;
       }
    })
    .then((data)=>{
      console.log(data)
       var instructionList = <HelpCenter key={1} instructions={data.result} />;
       setInstructions(instructionList);
    })
  };

  React.useEffect(() => {
    document.title = "Help";

    getInstructions();
  }, []);

  return (
    <>{auth.isAuthenticated?
    <Template>
      <Grid2
        container
        columns={{ xs: 8, sm: 8, md: 8, lg: 8 }}
        sx={{ justifyContent: "center" }}
      >
        {instructions}
      </Grid2>
      <ToggleSwitch />
    </Template>:<Navigate replace to='/login'/>}</>
  );
};

//This is also react's hook
const HelpCenter = (props) => {
  const [instructionTitles, setInstructionTitles] = React.useState([]);
  const [instruction, setInstruction] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false)
  const [page, setPage] = React.useState(null);
  const [markdown, setMarkdown] = React.useState(null);
  const [otherHelpComponent, setOtherHelpComponent] = React.useState()
  const theme = useTheme();

  //Returning the main list of instruction links
  const InstructionLinks = () => {
    return (
      <Grid2 xs={7.2} sm={7} md={6} lg={5} sx={{ py: 4 }}>
        <Card sx={{ maxWidth: 1000, p: 2, mx: "auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h3" component="div">
              Help Center
            </Typography>
            <ButtonGroup
              orientation="vertical"
              color="secondary"
              sx={{ alignItems: "flex-start" }}
            >
              {instructionTitles}
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid2>
    );
  };

  React.useEffect(() => {

  },[otherHelpComponent])

  //It is a function
  //Maybe it should be a hook

  const updateTheArticles=(id)=>{
    setOtherHelpComponent(<OtherHelpArticles id={id} instructions={props.instructions} swapPage={SwapPage} page={page} />)
  }

  const InstructionPage = (id) => {

    return (
      <Grid2
        container xs={8} sm={8} md={8} lg={8}
        columns={{ xs: 8, sm: 8, md: 8, lg: 8 }}
        sx={{
          py: 4,
          // justifyContent: 'center',
          [theme.breakpoints.up("lg")]: {
            justifyContent: "center",
          },
          [theme.breakpoints.down("md")]: {
            py: 0,
          },
        }}
      >
        {/* Back Button */}
        <Grid2
          container
          xs={8}
          sm={8}
          md="auto"
          sx={{ 
            alignItems: "flex-start",
            [theme.breakpoints.up("md")]: {
              justifyContent: "flex-end",
              pt: '16px',
              top: '50px',
              position: 'sticky',
              height: 'fit-content',
            },
            [theme.breakpoints.up("lg2")]: {
              pr: 1,
            },
          
          }}
        >
          <BackButton
            action={() => {
              BackToHelpCenter();
            }}
          />
        </Grid2>

        {/* Article */}
        <Grid2 xs={8} sm={8} md lg2="auto" > 
          <Markdown
            rehypePlugins={[rehypeRaw]}
            className={"markdown-container"}>
            {props.instructions[id].Instruction}
          </Markdown>
        </Grid2>

        {/* OtherHelpArticles */}
        <Grid2 
          container
          xs={8} 
          sm={8} 
          md="auto"
          sx={{ 
            [theme.breakpoints.up("md")]: {
              pt: '30px',
              maxWidth: '270px',
              top: '45px',
              position: 'sticky',
              height: 'fit-content',
            },

          }}
        >
          {
            //you can loop it here using the lambda function that filter out the article matching the id
            //But that is also for the HelpCenter links too
          }
          <OtherHelpArticles id={id} instructions={props.instructions} swapPage={SwapPage} />
        </Grid2>
      </Grid2>
    );
  };

  //Does not work, trying to make BackButton return to the main InstructionLinks page
  const BackToHelpCenter = () => {
    setRefresh(!refresh)
  };

  //Switching page to InstructionPage
  const SwapPage = (id) => {
    setPage(InstructionPage(id));
  };

  //Setting the page to HelpCenter main list of instructions
  React.useEffect(() => {
    setPage(InstructionLinks());
  }, [instructionTitles]);


  //Setting the main list of Instructions for HelpCenter (not otherHelpArticles list)
  React.useEffect(() => {
    if (props.instructions.length > 0) {
      var titleArr = [];

      props.instructions.map((instruction, index) => {
        var link = (
          <InstructionLinkButton
            key={instruction.id}
            action={() => {
              SwapPage(index);
            }}
            title={instruction.Title}
          />
        );
        titleArr.push(link);
      });
      setInstructionTitles(titleArr);
    }
  }, [refresh]);

  
  return (
  <>{page}</>
  );
};

//Hook for both HelpCenter links and OtherHelpArticle links
const InstructionLinkButton = (props) => {
  return (
    <Button
      variant="text"
      sx={{ fontWeight: 600, textDecoration: "underline", textAlign: "left", textTransform: "none" }}
      onClick={props.action}
    >
      {props.title}
    </Button>
  );
};

//BackButton Hook
const BackButton = (props) => {
  return (
    <IconButton
      variant="outlined"
      onClick={props.action}
      sx={{ p: 2, m: 2, border: '1px solid #c5c5c5'}}
      size="large"
    >
      <KeyboardBackspaceIcon fontSize="inherit"/>
    </IconButton>
  );
};

//Hook for OtherHelpArticles
const OtherHelpArticles = (props) => {
  const [otherHelpArticles, setOtherHelpArticles] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false)

  const getOtherHelpArticles = () => {
    var titleArr = [];
    // console.log("Getting other help articles")

    props.instructions.map((instruction, index) => {

      //Checking if this is not the current article
      if (index !== props.id) {

        var link = (
          <InstructionLinkButton
            key={instruction.id}
            action={() => {
              setRefresh(!refresh)
              props.swapPage(index);
            }}
            title={instruction.Title}
          />
        );
        titleArr.push(link);
      }
    });
    
    setOtherHelpArticles(titleArr);
  };

  

  React.useEffect(() => {
    getOtherHelpArticles();
  }, [refresh]);


  return (
    <Stack direction={{ sm: "row", md: "column" }} sx={{ p: 2 }}>
      <Typography variant="subtitle3" sx={{px: "8px", pb: 1}}>Other help articles</Typography>
      <ButtonGroup
        orientation="vertical"
        color="secondary"
        
        sx={{ 
          alignItems: "flex-start", 
          '& > *':{
            color: '#001E8A',
            lineHeight: '130%',
          } 
        }}
>
        {otherHelpArticles}
      </ButtonGroup>
    </Stack>
  );
};

export default Help;
