/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import axios from 'axios'

export class VideoContainer {

    _ratios = ['1:1']
    _aspect = 0
    _cameras = 0
    _margin = 10
    _video = false
    _conference = false
    _videoContainer = false
    _ratio = this.Ratio()
    _streamsList = new Array()
    _roomOfStream = new Array()
    _socket = null
    _localSvg = null
    _manageCamera = null

    constructor(scene,io, manageCameras){

        this.scene = scene

        this.Generate()

        this._socket = io
        this._manageCamera = manageCameras
        this.Render()
        return this
    }
 
    Generate(){
        this._conference = document.createElement('div')
        this._conference.classList.add('Conference')

        this._videoContainer = document.createElement('div')
        this._videoContainer.classList.add('VideoContainer')
        this._videoContainer.setAttribute('id','videoContainer')

        this._conference.appendChild(this._videoContainer)
        this.scene.appendChild(this._conference)
    }
    AddLocalAudio(streamId){
        let tag = document.createElement('div')
        // tag.classList.add('Audio')
        let audio = document.createElement('audio')
        audio.setAttribute('id',streamId)
        audio.muted = false
        audio.autoplay = true
        tag.appendChild(audio)
        this._videoContainer.appendChild(tag)
        this._manageCamera('increment')
    }

    AddLocal(streamId, _name, _position, sessionId){

        let camera = document.createElement('div')
        camera.classList.add('Camera')
        let overlay = document.createElement('div')
        let broadcast = document.createElement('div')
        let name = document.createElement('h3')
        name.classList.add('videoInformation')
        let position = document.createElement('h4')
        position.classList.add('videoInformation')
        let status = document.createElement('h5')
        status.classList.add('videoInformation')

        let svg = document.createElementNS('http://www.w3.org/2000/svg','svg')
            svg.setAttribute('class','svg_speaker')
            svg.setAttribute('width','30')
            svg.setAttribute('height','30')
            svg.setAttribute('style','opacity:0')
            svg.setAttribute('viewBox','0 0 17 16')
            svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink")
        var path1 = document.createElementNS('http://www.w3.org/2000/svg','path')
        var path2 = document.createElementNS('http://www.w3.org/2000/svg','path')

        path1.setAttribute('d', 'M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z')
        path1.setAttribute('fill', 'white')
        path2.setAttribute('d', 'M8 4.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zM8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-3.5 1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z')
        path2.setAttribute('fill', 'white')

        name.innerHTML = _name
        position.innerHTML = _position
        status.innerHTML = 'local'

        overlay.setAttribute('class','overlay')
        camera.setAttribute('id',streamId)

        let video = document.createElement('video')

        video.setAttribute('id','localVideo'+streamId)
        video.autoplay = true;
        video.muted = true;
        video.playsinline = true;
        video.controls = false;
        // video.classList.add('remoteVideo')

        camera.appendChild(overlay)
        overlay.appendChild(name)
        overlay.appendChild(position)
        overlay.appendChild(status)
        svg.appendChild(path1)
        svg.appendChild(path2)
        this._localSvg = svg
        camera.appendChild(video)
        camera.appendChild(svg)
        camera.appendChild(broadcast)

        this._videoContainer.appendChild(camera)
        this.Resize()
        this._cameras++

        this._socket.emit('joinStream',{streamID:streamId,name:_name,position:_position,sessionID:sessionId})
        this._manageCamera('increment')
    }
    
    Append(){
        this.scene.appendChild(this._conference)
    }

    Dimensions(){
        //to get the dimensions calculations.
        this._width = this._videoContainer.offsetWidth - (this._margin * 2)
        this._height = this._videoContainer.offsetHeight - (this._margin * 2)
    }

    Render(){

        if(this._videoContainer.children)
        {  
            for(let i = this._cameras; i < this._videoContainer.children.length; i++)
            {
                let camera = this._videoContainer.children[i]
                this._videoContainer.removeChild(camera)
            }

            for(let i = this._videoContainer.children.length; i < this._cameras; i++)
            {
                let camera = document.createElement('div')
                camera.setAttribute('id',i)

                this._videoContainer.appendChild(camera)
            }
        }
    }

    AddCamera(streamID, stream, videoStatus){

        let camera = document.createElement('div')
        camera.classList.add('externalCamera')
        camera.classList.add('Camera')
        let overlay = document.createElement('div')
        let broadcast = document.createElement('div')
        let name = document.createElement('h3')
        name.classList.add('videoInformation')
        let position = document.createElement('h4')
        let status = document.createElement('h5')
        position.classList.add('videoInformation')
        status.classList.add('videoInformation')
        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute('class', 'svg_speaker');
            svg.setAttribute('width', '30');
            svg.setAttribute('height', '30');
            svg.setAttribute('style','opacity:0')
            svg.setAttribute('viewBox', '0 0 17 16');
            svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink")
        var path1 = document.createElementNS("http://www.w3.org/2000/svg", 'path');
        var path2 = document.createElementNS("http://www.w3.org/2000/svg", 'path');

        path1.setAttribute('d', 'M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z')
        path1.setAttribute('fill', 'white')
        path2.setAttribute('d', 'M8 4.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zM8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-3.5 1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z')
        path2.setAttribute('fill', 'white')

        axios.get(process.env.REACT_APP_LOCALHOST+`/getActive/?streamId=${streamID}`)
        .then(result=>{
            console.log(result)
            if(result.status===200)
            {
                return result.data
            }
        })
        .then(data=>{
            console.log(data)
            name.innerHTML = data.name
            position.innerHTML = data.position
            status.innerHTML = 'remote'

            console.log(data.spotlight)
            if(data.spotlight)
            {
                console.log('Broadcast Live!')
                camera.classList.add('selected')
                camera.children[0].classList.add('selected')
                camera.children[3].classList.add('broadcastLive')
            }
        })

        overlay.setAttribute('class','overlay')

        camera.setAttribute('id',streamID)

        var video = this.Video(videoStatus+streamID, stream)

        video.classList.add('remoteVideo')

        camera.appendChild(overlay)
        overlay.appendChild(name)
        overlay.appendChild(position)
        overlay.appendChild(status)
        svg.appendChild(path1)
        svg.appendChild(path2)
        camera.appendChild(video)
        camera.appendChild(svg)
        camera.appendChild(broadcast)

        this._videoContainer.appendChild(camera)
        this.Render()
        this.Resize()
        this._cameras++
        this._manageCamera('increment')
    }

    RemoveCamera(streamID){

        let camera = document.getElementById(streamID)
        let video = document.getElementById('remoteVideo'+streamID)

        console.log(camera)
        console.log(video)

        if(video != null){ video.srcObject = null}

        if(camera != null)
        {
            console.log('Removing the camera!')
            console.log(camera)

            this._videoContainer.removeChild(camera)
            this.Resize()
            this._cameras--
            this._manageCamera('decrement')
        }
    }

    Resizer(width){

        for(var s = 0; s < this._videoContainer.children.length; s++){

            let element = this._videoContainer.children[s]

            element.style._margin = this._margin+'px'

            element.style.width = width+'px'
            element.style.height = (width * this._ratio) +'px'

            element.setAttribute('data-_aspect', this._ratios[this._aspect])
        }
    }

    Resize(){

        this.Dimensions()

        let max = 0
        let i = 1

        while(i < 5000)
        {
            let area = this.Area(i)
            if(area === false){
                max = i -1
                break
            }
            i++
        }

        max = max - (this._margin * 2)

        this.Resizer(max)
    }

    AddStreamID(streamID){
        this._streamsList.push(streamID)
    }

    Ratio(){
        var ratio = this._ratios[this._aspect].split(':')
        return ratio[1] / ratio[0]
    }

    Area(increment){

        let i = 0
        let w = 0
        let h = increment * this._ratio + (this._margin * 2)

        while(i < (this._videoContainer.children.length))
        {
            if((w+increment) > this._width){
                w = 0
                h = h +(increment * this._ratio) + (this._margin * 2)

            }
            w = w + increment + (this._margin * 2)
            i++
        }
        if(h > this._height || increment > this._width) return false
        else return increment
    }

    Add(){
        this.Render()
        this.Resize()
        this._cameras++

    }

    Delete(){
        this._cameras--
        this.Render()
        this.Resize()
    }

    Ratios(){
        return this._ratios
    }

    Cameras(){
        return this._cameras
    }

    Aspect(i){
        this._aspect = i
        this._ratio = this.Ratio()
        this.Resize()
    }

    Scaled(){

        let frames = this._conference.querySelector('.Frame')

        if(frames){

            this._conference.removeChild(frames)
        }
        else{

            let frame = document.createElement('div')

            frame.classList.add('Frame')

            this._conference.prepend(frame)
        }

        this.Resize()
    }

    Video(streamId, stream){

            let video = document.createElement('video');

            console.log(stream)


            // random number 1-5
            video.autoplay = true;
            video.muted = false;
            video.playsinline = true;
            video.controls = false;
            video.width = 100;
            video.setAttribute('id',streamId)

            video.srcObject = stream

            return video

    }
}