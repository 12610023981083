import React, {useContext} from 'react'
import {Navigate} from 'react-router-dom'
import NewEvent from '../components/NewEvent'
import NewEventSession from '../components/NewEventSession'
import ButtonStyled from '../components/ButtonStyled'
import TabPanel from '../components/TabPanel'
import {format} from 'date-fns'
import NewSessionT from '../components/NewSessionT'
import SessionInfoTemplate from '../components/SessionInfoTemplate'
import {Cancel, Comment, KeyboardArrowDown, KeyboardArrowUp, Close} from '@mui/icons-material'
import {Box, IconButton, Tabs, Tab,TableBody,Grid, TableContainer, Table, Paper, TableHead, TableRow, TableCell, Collapse, Stack, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Checkbox, FormGroup, FormControlLabel} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import MoreOptionsMenu from '../components/MoreOptionsMenu'
import { Status, Access, Category } from '../utilities/Enums'
import {useSelector, useDispatch} from 'react-redux'
import { useRetrieveScheduledSessionsQuery, useRetrieveScheduleEventQuery, useCancelEventSessionMutation, useDeleteOrgSessionMutation } from '../endpoints/org.endpoint'
import { SessionsContext, EventsContext, SessionEventsContext } from '../utilities/Contexts'

//#region Schedule
function a11yProps(index)
{
    return{
        id:`simple-tab-${index}`,
        'aria-controls':`simple-tabpanel-${index}`
    }
}

const Schedule = (props)=>
{
    const [value, setValue] = React.useState(0)
    const auth = useSelector(state=>state.auth)

    const handleChange = (event, newValue) =>{

        setValue(newValue)
    }

    React.useEffect(()=>{
        
        document.title='Schedule'
        
    },[])

    return(
        <>  
        {auth.isAuthenticated?<Box>
                <Box sx={{borderBottom:1, borderColor:'divider'}}>
                    <Tabs value={value} 
                          onChange={handleChange} 
                          aria-label='basic tabs' 
                          textColor='secondary' 
                          indicatorColor='secondary'>
                        <Tab label='Events' {...a11yProps(0)} color='secondary'/>
                        <Tab label='Sessions' {...a11yProps(1)} color='secondary'/>
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <EventsTable/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SessionsT/>
                </TabPanel>
            </Box>:<Navigate to='/login' replace={true}/>}
            
        </>
    )
}

export default Schedule
//#endregion

//#region Events
const EventsTable = (props)=>
{
    const [openTable, setOpenTable] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [collection, setCollection] = React.useState(null)
    const [display, setDisplay] = React.useState(null)
    const auth = useSelector(state=>state.auth)
    const {data:events, isLoading,isSuccess} = useRetrieveScheduleEventQuery(auth.token)

    if(isLoading)
    {

    }
    else if(isSuccess)
    {
        if(collection===null)
        {   
            if(events.result.length>0)
            {
                setCollection(events.result)
            }
            else
            {
                setCollection([])
            }
        }
    }

    const handleDisplay=()=>{
        let arr = []
        collection.map((event,index)=>{
            console.log(event)
            var eventTable = <NewEventTable key={index} event={event} eventSessions={event.eve_ses} eventId={event.id} index={index}/>
            arr.push(eventTable)
        })
        setDisplay(arr)
    }

    React.useEffect(()=>{
        if(collection!==null)
        {
            handleDisplay()
        }
    },[collection])

    const handleDialog=()=>
    {
        setOpen(true)
    }

    return(
        <EventsContext.Provider value={{collection, setCollection, setOpenTable}}>
        <Box sx={{minWidth:660, mb:2}}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          sx={{mt:3}}>
          <ButtonStyled action={handleDialog} title='New Event'/>
        </Grid>
        <NewEvent open={open} setOpen={setOpen}/>
        </Box>
        <TableContainer component={Paper}>
         <Table aria-label='collapsible table'>
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell><b>Event</b></TableCell>
                    <TableCell align='left'><b>Venue</b></TableCell>
                    <TableCell align="left"><b>Date</b></TableCell>
                    <TableCell align="left"><b>Time</b></TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {display}
            </TableBody>
         </Table>
        </TableContainer>
        </EventsContext.Provider>
    )
}
//#endregion

//#region NewEventTable
const NewEventTable = (props)=>
{
    const [openTable, setOpenTable] = React.useState(false)
    const [open1, setOpen1] = React.useState(false)
    const [display, setDisplay] = React.useState([])
    const [date] = React.useState(format(new Date(props.event.date),'MM/dd/yyyy'))
    const [startTime] = React.useState(format(new Date(props.event.start_time),"hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(props.event.end_time),"hh:mm aaaaa'm'"))
    const {collection, setCollection} = useContext(EventsContext)
    const [sessions, setSessions] = React.useState([])


    const handleDisplay=()=>{

            var arr = []
            console.log(sessions)
            console.log(props.eventSessions)
            sessions.map((eventSession,index)=>{

                console.log(eventSession.id)
                arr.push(<NewEventCollapseData key={eventSession.id} session={eventSession.sess} eventId={props.eventId} index={eventSession.id}/>)
            })
            setDisplay(arr)
    }

    React.useEffect(()=>{
        if(sessions.length===0)
        {
            setSessions(props.eventSessions)
        }
    },[])


    React.useEffect(()=>{
        if(sessions.length>0)
        {
            handleDisplay()
        }
        else
        {
            setDisplay([])
        }
        
    },[sessions])

    return(
        <SessionEventsContext.Provider value={{sessions, setSessions}}>
        <TableRow className='dropdownTable'>
            <TableCell>
                <IconButton
                aria-label='expand row'
                size='small'
                onClick={()=>setOpenTable(!openTable)}>
                {openTable ? <KeyboardArrowUp/>:<KeyboardArrowDown/>}
                </IconButton>
            </TableCell>
            <TableCell component='th' scope='row'>{props.event.title}</TableCell>
            <TableCell align='left'>{props.event.location}</TableCell>
            <TableCell align='left'>{date}</TableCell>
            <TableCell align='left'>{startTime} - {endTime}</TableCell>
            <TableCell align='left'>
                <Grid2 container gap={1} wrap='nowrap'>
                    <ButtonStyled action={()=>{setOpen1(true)}} title='New Session'/>
                    {/* <Tooltip title='Delete Event' >
                            <IconButton
                                    aria-label="Delete Event"
                                    onClick={() => {  }}>
                                    <Delete />
                                </IconButton>
                    </Tooltip> */}
                    <MoreOptionsMenu menuItems={[
                            {action: () => { }, title: 'Cancel Event', icon: <Cancel /> },
                            {action: ()=> {}, title: 'Add a Note', icon: <Comment /> }
                        ]}  
                    />
                </Grid2>
            </TableCell>
            <NewEventSession open={open1} setOpen={setOpen1} eventId={props.eventId} getCollections={props.getCollections} date={props.event.date} startTime={new Date(props.event.start_time)} endTime={new Date(props.event.end_time)}/>
        </TableRow>
        <TableRow>
            <TableCell sx={{pb:0, pt:0}} colSpan={6}>
                <Collapse in={openTable} timeout='auto' unmountOnExit>
            <Box sx={{m:1}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'><b>Title</b></TableCell>
                            <TableCell align='left'><b>Description</b></TableCell>
                            <TableCell align='left'><b>StartTime</b></TableCell>
                            <TableCell align='left'><b>EndTime</b></TableCell>
                            <TableCell align='left'></TableCell>
                        </TableRow>
                    </TableHead>
                    {display}
                </Table>
            </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </SessionEventsContext.Provider>
    )
}
//#endregion

//#region NewEventCollapseData
const NewEventCollapseData = (props)=>
{
    const [startTime] = React.useState(format(new Date(props.session.start_time),"hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(props.session.end_time),"hh:mm aaaaa'm'"))
    const {sessions, setSessions} = useContext(SessionEventsContext)
    const [openReject, setOpenReject] = React.useState(false)
    const [translator, setTranslator] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const auth = useSelector(state=>state.auth)
    const [enabled, setEnabled] = React.useState(auth.role===5?false:true)
    const [cancelEventSession] = useCancelEventSessionMutation()

    const deleteSession=()=>
    {
        cancelEventSession({token:auth.token, session:props.session.id})
        .then((res)=>{
            setSessions(sessions.filter(session=>session.id!==props.index))
        })
    }

    const handleRejectOpen = () => {

        setTimeout(() => {

            setOpenReject(true);
        }, 100)

    };

    const handleClickOpen = () => {

        // setTranslator(request.number)
        setTimeout(() => {

            setOpen(true);
        }, 100)
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <TableBody>
            <TableCell align='left' sx={{maxWidth:'200px'}}>
                {props.session.title}
            </TableCell>
            <TableCell align='left'>
                {props.session.description}
            </TableCell>
            <TableCell align='left'>
                {startTime}
            </TableCell>
            <TableCell align='left'>
                {endTime}
            </TableCell>
            <TableCell align='right'>
                <Stack direction={'row'} spacing={1}>
                    {auth.role===5 && !enabled && <ButtonStyled variant='contained' title='Assign'/>}
                    {!enabled && <ButtonStyled variant='outlined' color='error' action={deleteSession} title='Cancel'/>}
                </Stack>
            </TableCell>
            <TableCell align='right'>
            {auth.role===5 && <FormControlLabel control={<Checkbox onChange={()=>{setEnabled(!enabled)}} />} label="board"/>}
            </TableCell>
        </TableBody>
    )
}

{/* <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', pr: '15px' }}>
                        <ButtonStyled action={handleClickOpen} title={'Assign'} />
                        <Dialog
                            fullScreen
                            open={open}
                            className='fullscreen-dialog'
                            onClose={handleClose}>
                            <DialogTitle variant='dialogTitle'>
                                Assign Translators
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                className='closeButton'>
                                <Close />
                            </IconButton>
                            <DialogContent className='full-dialog-content'>
                                <SessionInfoTemplate
                                    title={request.sess.title}
                                    accessType={Access[access]}
                                    requestNum={request.number}
                                    date={date}
                                    startTime={startTime}
                                    endTime={endTime}
                                    category={Category[request.sess.category_id]}
                                    organization={request.bus.name}

                                    //temporary
                                    clientName={
                                        // clientName
                                        'Manager William Smith'
                                    }
                                    //temporary
                                    clientEmail={
                                        // clientEmail
                                        'hospital_mgr@outlook.com'
                                    }
                                    status={Status[info.status_id]}

                                    //temporary
                                    coordClients={`${request.sub.mem.first_name}, ${request.sub.mem.last_name}`}
                                    
                                    description={request.sess.description}
                                    notes={request.sess.notes}
                                    role={auth.role}
                                    theme={theme} />
                                <Box sx={{ flexGrow: 1 }}>
                                    <ListOfTranslators list={list} setList={setList} request={request} accessType={`${Access[request.access_id]}s`} />
                                    <Modal
                                        open={open1}
                                        onClose={handleClose1}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description">
                                        <Box sx={{ ...style, width: 400 }}>
                                            <Typography variant='h2' sx={{ textAlign: 'center' }}>OOPS!</Typography>
                                            <Typography variant='body1'>No translator has been selected. If you want to cancel, please click on the cancel button.</Typography>
                                        </Box>
                                    </Modal>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <ButtonStyled variant='outlined' color='error' action={cancel} title='Cancel' />
                                <ButtonStyled variant="contained" color='primary' action={confirm} title='Confirm' />
                            </DialogActions>
                        </Dialog>
                        <MoreOptionsMenu menuItems={[
                                {action: ()=> {}, title: 'Request Change', icon: <ChangeCircle /> },
                                {action: ()=> {}, title: 'Add a Note', icon: <Comment /> },
                                {action: () => { handleRejectOpen() }, title: 'Reject Request', icon: <Cancel />, divider: true },
                            ]}  
                        />
                        <Dialog
                            open={openReject}
                            onClose={handleRejectClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            >
                            <DialogTitle id="alert-dialog-title">
                                Are you sure?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                This cancellation request will be permanent. Please click below to confirm your action.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <ButtonStyled variant="outlined" color='secondary' action={handleRejectClose} title='Go Back' />
                                <ButtonStyled variant="contained" color='error' action={reject} autoFocus title='Reject' />
                            </DialogActions>
                        </Dialog>
                    </Stack> */}
//#endregion

//#region SessionsT
const SessionsT = ()=>
{
    const [collection , setCollection] = React.useState(null)
    const [display, setDisplay] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const auth = useSelector(state=>state.auth)
    const {data:sessions, isSuccess} = useRetrieveScheduledSessionsQuery(auth.token)

    if(isSuccess)
    {
        if(collection===null)
        {
            setCollection(sessions.result)
        }
    }

    const handleDisplay=()=>{
        let arr = []
        if(collection.length>0)
        {
            collection.map((session,index)=>{
                
                console.log(session)
                arr.push(<SessionsTable key={index} info={session} index={index}/>)
            })
            setDisplay(arr)
        }
        else
        {
            setDisplay([])
        }
    }

    const handleDialog=()=>{
        setOpen(true)
    }

    React.useEffect(()=>{

        if(collection!==null)
        {
            handleDisplay()
        }
    },[collection])

    return(
        <SessionsContext.Provider value={{collection, setCollection}}>
        <Box sx={{minWidth:660, mb:2}}>
            <Grid container
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
            sx={{mt:3}}>
                <ButtonStyled action={handleDialog} title='New Session'/>
            </Grid>
        </Box>
        <NewSessionT open={open} setOpen={setOpen} />
        <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell><b>Request #</b></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Day</TableCell>
                        <TableCell align='left'>Time</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                {display}
                </TableBody>
            </Table>
        </TableContainer>
        </SessionsContext.Provider>
    )
}
//#endregion

//#region SessionTable
const SessionsTable = (props)=>
{
    const {info} =props
    const [openTable, setOpenTable] = React.useState(false)
    const [date] = React.useState(format(new Date(info.date),'MM/dd/yyyy'))
    const [startTime] = React.useState(format(new Date(info.sess.start_time),"hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(info.sess.end_time),"hh:mm aaaaa'm'"))
    const {display, setDisplay} = useContext(SessionsContext)
    const auth = useSelector(state=>state.auth)
    const [deleteOrgSession] = useDeleteOrgSessionMutation()

    const deleteSession=()=>
    {
        deleteOrgSession({token:auth.token, session:info.sess.id})
        .then((res)=>{
            setDisplay(display.filter(session=>session.key!==`${props.index}`))
        })
        .catch((err)=>{
            console.log(err.message)
        })
    }

    return(
        <>
        <TableRow className='dropdownTable' >
            <TableCell>
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenTable(!openTable)}>
                    {openTable?<KeyboardArrowUp/>:<KeyboardArrowDown/>}
                </IconButton>
            </TableCell>
            <TableCell>{info.number}</TableCell>
            <TableCell component='th' scope='row'>{info.sess.title}</TableCell>
            <TableCell align='left'>{Access[info.access_id]}</TableCell>
            <TableCell align='left'>{date}</TableCell>
            <TableCell align="left">{startTime} - {endTime}</TableCell>
            <TableCell>
                <ButtonStyled variant='outlined' color='error' action={deleteSession} title='Cancel' />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={openTable} timeout="auto" unmountOnExit>
                    <SessionCollapseData info={info}/>
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    )
}
//#endregion

//#region SessionCollapseData
const SessionCollapseData = (props)=>
{
    const { info, request} = props

    return(
        <Box sx={{m:1}}>
            <Table size='small' aria-label='purchases'>
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><b>Description</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableCell align='left' sx={{maxWidth:'200px'}}>
                        {info.sess.description}
                    </TableCell>
                </TableBody>
            </Table>
        </Box>
    )
}
//#endregion