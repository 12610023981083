/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import { ConferenceContext } from '../utilities/Contexts';
import { useContext } from 'react';

const DeviceInputs = ({title, input, sourceObj, defaultLabel, changeSource})=>{

    const [_menu, _setMenu] = React.useState([])
    const [deviceInput, setDeviceInput] = React.useState('')
    const [defaultDevice, setDefaultDevice] = React.useState()
    const [selectedDevice, setSelectedDevice] = React.useState(null)
    const [restart, setRestart] = React.useState(false)
    const [initialized, setInitialized] = React.useState(false)
    const { webRTCAdaptor, stream } = useContext(ConferenceContext)


    navigator.mediaDevices.ondevicechange = (event) => {
        console.log('Check for media device change')
        setRestart(!restart)
    }

    const onInputChange = (event)=>{
        setDeviceInput(event.target.value)
        
        // changeSource(event.target.value)
        if(input==='audioinput')
        {
            webRTCAdaptor.switchAudioInputSource(stream.streamId, event.target.value)
        }
        else
        {
            webRTCAdaptor.switchVideoCameraCapture(stream.streamId, event.target.value)
        }
    }

    // function GetStreamTracks()
    // {
    //     if(props.input === 'videoinput')
    //     {
    //         navigator.mediaDevices.getUserMedia({audio: false, video: true})
    //         .then((mediaStream)=>{
    //             setSelectedDevice(mediaStream.getVideoTracks()[0].label)
    //         })
    //     }
    //     else
    //     {
    //         navigator.mediaDevices.getUserMedia({audio: true, video: false})
    //         .then((mediaStream)=>{
    //             setSelectedDevice(mediaStream.getAudioTracks()[0].label)
    //         })
    //     }
    // }

    function gotInitializedDevices(deviceInfos){

        var theMenu = []

        console.log(deviceInfos)

        for(const deviceInfo of deviceInfos)
        {
            console.log(deviceInfo)
            theMenu.push(<MenuItem key={deviceInfo.deviceId} value={deviceInfo.deviceId}>{deviceInfo.label}</MenuItem>)
        }

        _setMenu(theMenu)
    }

    function gotDevices(deviceInfos) {
        
        window.deviceInfos = deviceInfos; // make available to console
        var theMenu = []
        
        for (const deviceInfo of deviceInfos) {

            if(deviceInfo.label === defaultLabel  && (!initialized)) {

                setSelectedDevice(deviceInfo.label)
                setDeviceInput(deviceInfo.deviceId)
                setInitialized(true)
            }
            theMenu.push(<MenuItem key={deviceInfo.deviceId} value={deviceInfo.deviceId}>{deviceInfo.label}</MenuItem>)
        }
        _setMenu(theMenu)

        
      }

    React.useEffect(() => {
        console.log('UseEffect for Restart & Selected Device')
        console.log('Getting the devices')
            if(!initialized)
            {
                gotDevices(sourceObj)
            }
            else{
                navigator.mediaDevices.enumerateDevices().then(devices =>{
                    const filtered = devices.filter(device => device.kind===input)
                    gotInitializedDevices(filtered)
                })
            }
    },[restart])

    return(
        <FormControl fullWidth>
            <InputLabel id='select-label' variant='outlined'>{deviceInput!==null?title:''}</InputLabel>
            <Select
                labelId="select-label"
                value={deviceInput}
                onChange={onInputChange}>
                    {_menu}
            </Select>
        </FormControl>
    )
}

export default DeviceInputs

