import { apiSlice } from "../slices/api.slice";

const translatorApi = apiSlice.injectEndpoints({
    endpoints:build=>({
        retrieveAssignments:build.query({
            query:token=>`/translator/sessions/?id=${token}`,
            providesTags:['Translator Sessions']
        })
    })
})

export const {
    useRetrieveAssignmentsQuery
} = translatorApi