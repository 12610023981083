import React, {useContext} from 'react';
import {createRoot} from 'react-dom/client'
import './index.css';
import App from './App';
import store from './reduxs/store'
import { Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  //  <QueryClientProvider client={new QueryClient()}>
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>
  //</QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
