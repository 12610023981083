/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import {Paper, Box, Button, TextField, ToggleButton, Stack, Tooltip, Typography} from '@mui/material'
import { Navigate } from 'react-router-dom'
import { Edit } from '@mui/icons-material'
import Template from '../components/Template'
import ToggleSwitch from '../components/ToggleSwitch'
import {useSelector} from 'react-redux'
import { useRetrieveProfileQuery, useUpdateProfileMutation } from '../endpoints/member.endpoint'

const Profile = ()=>
{
    document.title='Profile'

    const [toggle, setToggle] = React.useState(true)
    const [firstName, setFirstName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [firstNameError, setFirstNameError] = React.useState(false)
    const [lastNameError, setLastNameError] = React.useState(false)
    const [emailError, setEmailError] = React.useState(false)
    const [selected, setSelected] = React.useState(false)
    const [scale, setScale] = React.useState('scale(1.0)')
    const [opacity, setOpacity] = React.useState(0)
    const cleanInput = [true, true, true]
    const [pendingMsg, setPendingMsg] = React.useState('your update information have been saved.')
    const auth = useSelector((state)=>state.auth)
    const {data:profile,
        isLoading,
        isSuccess,
        isFetching,
        isError,
        error} = useRetrieveProfileQuery(auth.token)
    const [updateProfile] = useUpdateProfileMutation()

    const setEdit = (event)=>
    {
        setToggle(!toggle)
    }

    const onSelectedChange = ()=>
    {
        setSelected(!selected)
    }
    
    function onFirstChanged(e)
    {
        setFirstName(e.target.value)
    }

    function onEmailChanged(e)
    {
        setEmail(e.target.value)
    }

    function onLastChange(e)
    {
        setLastName(e.target.value)
    }

    const checkInputRequirements=()=>
    {
        if(checkInput(firstName,0))
        {
            setFirstNameError(true)
        }
        else
        {
            setFirstNameError(false)
        }
        
        if(checkInput(lastName,1))
        {
            setLastNameError(true)
        }
        else
        {
            setLastNameError(false)
        }

        if(checkInput(email, 2))
        {
            setEmailError(true)
        }
        else
        {
            setEmailError(false)
        }
    }

    const checkInput =(textInput,index)=>
    {
      if(textInput==='' || textInput===undefined || textInput===null)
      {
        cleanInput[index] = false;
        return true
      }
      else
      {
        cleanInput[index]= true
        return false
      }
    }

    const ButtonStyled=(props)=>{
  
        return <Button
        disabled={toggle}
        variant='contained'
        size='large'
        sx={{
            color:'#fff', 
            marginBottom:10,
            background:toggle?'gray':'linear-gradient(47deg,#84BD00,#C4D600)',
            border:0,
            fontWeight:'bold',
            borderRadius:7,
            m:2
            }}
            onClick={toggle?()=>{}:async()=>{

                checkInputRequirements()

                if(Object.values(cleanInput).every(Boolean))
                    {
                        await updateProfile({
                            id:auth.token,
                            firstName:firstName,
                            lastName:lastName,
                            email:email})
                            .then((result)=>{
                                setOpacity(1)
                                setScale('scale(1.1)')

                                setTimeout(()=>{
                                    setOpacity(0)
                                    setScale('scale(1.0)')
                                },5000)
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
                    }
            }}>Save</Button>
    }

    React.useEffect(()=>{
        checkInputRequirements()
    },[firstName,lastName,email])

    if(isLoading)
    {

    }
    else if(isSuccess)
    {
        if(email==='')
        {
            setFirstName(profile.data.first_name)
            setLastName(profile.data.last_name)
            setEmail(profile.data.email)
        }
    }

    return(
        <>{auth.isAuthenticated?
        <Template>
        <Paper elevation={0}>
                <Box sx={{display:'flex', justifyContent:'center', p:10, alignItems:"center"}}>
                    <Box
                    sx={{'& .MuiTextField-root':{m:1, width:'25ch'}
                    }}
                    noValidate
                    autoComplete='off'>
                        <div>
                            <Box component='form'
                                sx={{'& .MuiTextField-root': { m: 1, width: '25ch'},
                                display:'flex',
                                flexDirection:'column',
                                alignItems:"center",
                                justifyContent:"center",
                                p:3,
                                borderRadius:5,
                                width:300,
                                borderColor:'#84BD00'}}
                                noValidate
                                autoComplete='off'>
                                <Stack spacing={2}>
                                    <TextField
                                        disabled={toggle}
                                        error={firstNameError}
                                        color='success'
                                        id='outlined-disabled'
                                        label='First Name'
                                        value={firstName}
                                        onChange={onFirstChanged}/>
                                    <TextField
                                        disabled={toggle}
                                        color='success'
                                        id='outlined-disabled'
                                        label='Last Name'
                                        error={lastNameError}
                                        value={lastName}
                                        onChange={onLastChange}/>
                                    
                                    <TextField
                                        disabled={toggle}
                                        color='success'
                                        id='outlined-disabled'
                                        label='Email Address'
                                        value={email}
                                        error={emailError}
                                        onChange={onEmailChanged}/>
                                </Stack>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <ButtonStyled />
                                <Tooltip title='Edit your information'>
                                    <ToggleButton
                                        value='edit'
                                        sx={{height:55,m:1}}
                                        selected={selected}
                                        onClick={setEdit}
                                        size='large'
                                        onChange={onSelectedChange}>
                                        <Edit/>
                                    </ToggleButton>
                                </Tooltip>
                                </Box>   
                                <Typography variant='h5' sx={{color:'green',opacity:opacity,transition:'.5s',transform:scale, textAlign:'center'}}><b>{pendingMsg}</b></Typography>
                            </Box>
                        </div>
                    </Box>
                </Box>
            {/* <Footer/> */}
        </Paper>
        <ToggleSwitch/>
        </Template>
        :<Navigate replace to='/login'/>}</>
    )
}

export default Profile