/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie'

export const themeSlice = createSlice({

    name:'theme',
    initialState:{
    darkTheme:/true/.test(Cookies.get('theme')),
    },
    reducers:{
        toggleTheme:(state)=>{
            state.darkTheme = !state.darkTheme
            Cookies.set('theme',state.darkTheme,{expires:5756})
        }
    }
})

export const { toggleTheme } = themeSlice.actions

export default themeSlice.reducer