import React, {useContext} from 'react'
import {
    Typography,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText,
    DialogActions, 
    IconButton,
    Modal, 
    Box} from '@mui/material'
import { Close } from '@mui/icons-material';
import { AgencyContext } from '../utilities/Contexts';
import ButtonStyled from './ButtonStyled';
import { Access, Category, Status } from '../utilities/Enums';
import SessionInfoTemplate from './SessionInfoTemplate';
import { useRetrieveTicketsQuery, useReassignmentMutation, useAssignTranslatorsMutation, useRejectRequestMutation } from '../endpoints/agency.endpoint'
import ListOfTranslators from '../widgets/ListOfTranslators';
import MoreOptionsMenu from './MoreOptionsMenu';
import {format} from 'date-fns'
import { useSelector } from 'react-redux';
import { AssignTranslatorsContext } from '../utilities/Contexts'

const AgencyInfoTemplate=()=>{

    const [openAssign, setOpenAssign] = React.useState(false);
    const [openError, setOpenError] = React.useState(false)
    const [openReject, setOpenReject] = React.useState(false)
    const [translator, setTranslator] = React.useState({})
    const [list, setList] = React.useState([])
    const {open2, setOpen2, request}=useContext(AgencyContext)
    const [access, setAccess] = React.useState(null)
    const [date, setDate] = React.useState(null)
    const [startTime, setStartTime] = React.useState(null)
    const [endTime, setEndTime] = React.useState(null)
    const [number, setNumber] = React.useState(null)
    const [categoryId, setCategoryId] = React.useState(null)
    const [description, setDescription] = React.useState(null)
    const [businessAgreementId, setBusinessAgreementId]= React.useState(null)
    const [status, setStatus] = React.useState(null)
    const client = 'Myself'

    const auth = useSelector(state=>state.auth)
    const [assignTranslators] = useAssignTranslatorsMutation()
    const [rejectRequest] = useRejectRequestMutation()
    const [reassignment] = useReassignmentMutation()

    const oopsStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const initializeDialog=React.useCallback(()=>{

        if(request)
        {
            console.log(request.id)
            setStartTime(format(new Date(request.startTime), "hh:mm aaaaa'm'"))
            setEndTime(format(new Date(request.endTime), "hh:mm aaaaa'm'"))
            setDate(format(new Date(request.date), "MM/dd/yyyy"))
            setAccess(request.accessType)
            setNumber(request.number)
            setCategoryId(request.categoryId)
            setDescription(request.description)
            setStatus(request.statusId)
            setBusinessAgreementId(request.businessAgreementId)
            console.log(request.accessType)
        }

    },[request])

    const handleCloseAssign = () => {
        setOpenAssign(false);
    };

    const handleOpenReject = () => {

        setTimeout(() => {

            setOpenReject(true);
        }, 100)

    };

    const handleCloseReject = () => {
        setOpenReject(false)
    }

    const handleCloseError = () => {
        setOpenError(false)
    }

    const confirm = () => {
        console.log('Confirm create list')
        if (list.length > 0) {
            assignTranslators({
                list: list,
                requestId:request.id,
                token: auth.token
            })
            .then((res) => {
                setOpen2(false)
            })
            .catch((err) => {
                console.log(err.message)
            })
        }
        else {
            console.log('List is empty')
            setOpenError(true)
        }
    };

    const update = () => {
        console.log('Confirm update list')
        console.log(list)

        if (list.length > 0) {

            reassignment({list:list,requestId:request.id})
            .then((res) => {
                setOpen2(false)
                if (res.status === 200) {
                
                console.log('sucessfully created services')
                }
            })
            .catch((err) => {
                 console.log(err)
             })
        }
    }

    const reject = () => {

        rejectRequest({
            token: auth.token,
            requestId:request.id
        })
        .then((res) => {
            // props.removeRow(props.index)
            handleCloseReject()
        })
        .catch((err) => {
             console.log(err.message)
         })
    }

    React.useEffect(()=>{
        initializeDialog()
    },[initializeDialog])

    React.useEffect(()=>{
        console.log(list)
    },[list])

    return(
            <>
            <Dialog
                fullScreen
                open={open2}
                className='fullscreen-dialog'
                onClose={handleCloseAssign}>
                <DialogTitle variant='dialogTitle'  textTransform={'capitalize'}>
                    Assign {`${Access[access]}s`}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={()=>{setOpen2(false)}}
                    className='closeButton'>
                    <Close />
                </IconButton>
                <DialogContent className='full-dialog-content' >
                    {request && <SessionInfoTemplate
                        title={request.title}
                        accessType={Access[access]}
                        requestNum={number}
                        date={date}
                        startTime={startTime}
                        endTime={endTime}
                        category={Category[categoryId]}
                        organization={request?.bus?.name ?? client}
                        //temporary
                        clientName={
                            // clientName
                            'Manager William Smith'
                        }
                        //temporary
                        clientEmail={
                            // clientEmail
                            'hospital_mgr@outlook.com'
                        }
                        status={Status[status]}
                        //temporary
                        coordClients={request?.sub?.mem??null}
                        description={description}
                        notes={request.notes}
                        role={auth.role}/>}
                    <Box sx={{ flexGrow: 1 }}>

                        {request&&<AssignTranslatorsContext.Provider value={{list, setList, request, access, client, businessAgreementId}}>
                            <ListOfTranslators/>
                        </AssignTranslatorsContext.Provider>}
                        <Modal
                            open={openError}
                            onClose={handleCloseError}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description">
                            <Box sx={{ ...oopsStyle, width: 400 }}>
                                <Typography variant='h2' sx={{ textAlign: 'center' }}>OOPS!</Typography>
                                <Typography variant='body1'>No translator has been selected. If you want to cancel, please click on the cancel button.</Typography>
                            </Box>
                        </Modal>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled variant='outlined' color='error' action={()=>{setOpen2(false)}} title='Cancel' />
                    <ButtonStyled variant="contained" color='primary' action={()=>{Status[status].label==='Assigned'?update():confirm()}} title='Confirm' />
                </DialogActions>
            </Dialog>
            <Dialog
                open={openReject}
                onClose={handleCloseReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This cancellation request will be permanent. Please click below to confirm your action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled variant="outlined" color='secondary' action={handleCloseReject} title='Go Back' />
                    <ButtonStyled variant="contained" color='error' action={reject} autoFocus title='Reject' />
                </DialogActions>
            </Dialog>
            </>
    )
}

export default AgencyInfoTemplate