/********************************************************
 *                                                      *
 *    Created by: njcdhb - Nicholas Capadano           *
 *                                                      *
 ********************************************************/

import { Chip, Tooltip } from '@mui/material'

const InfoChip = (props) => {

    return <Tooltip
        title={props.tooltip}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            // offset: [0, -10],
                        },
                    },
                ],
            },
        }}

    >
        <Chip
            variant={props.variant || 'filled'}
            size={props.size}
            icon={props.icon}
            color={props.color || 'chipGrey'}
            label={props.label}
            sx={{
                fontSize: props.size === 'medium' && '14px',
                borderRadius: '4px',
                // fontFamily: 'sans-serif',
                ...props.sx
            }}
        />
    </Tooltip>

}

export default InfoChip