/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import { createTheme} from "@mui/material";
import {alpha} from "@mui/material/styles";

const coveredMain = '#3CA711';

export const lightTheme = createTheme({

    palette:{

        mode:'light',
        appBar:{
            bar:'#fff'
        },
        common:{
            black:'#000',
            white:'#fff',
            green:'#84BD00',
            lightGreen:'#C4D600'
        },

        primary:{
            light:'#fff',
            main:'#84BD00',
            contrastText:'#fff'
        },
        secondary: {
            main: '#5e5e5e',
        },
        error: {
            main: '#d32f2f'
        },
        info: {
            main: '#3274B4'
        },
        success: {
            main:'#84BD00',
        },
        chipGrey: {
            main: '#F0F0F0',
            contrastText: '#000'
        },
        section:{
            main:'#080402'
        },

        action:{
            hoverOpacity:0.06,
        },

        accessbility:{
            main:'#1976d2',
            light:'#42a5f5'
        },

        background:{
            main:'linear-gradient(47deg,#84BD00,#C4D600)'
        },

        covered: {
            main:'#3CA711',
            light: alpha(coveredMain, 0.5),
            dark: '#276d0c',
            contrastText:'#fff'
        },

        tonalOffset: {
            light: 0.9,
            dark: 0.2
        },
    },
    typography:{
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSmooth:'always',
        htmlFontSize: 16,
        h1: {
            fontSize: '6rem',
            fontWeight: 300,
            fontSmooth:'always',
        },
        h2: {
            fontSize: '3.75rem',
            fontWeight: 300,
            fontSmooth:'always'
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        h4: {
            fontSize: '2.125rem',
            fontWeight: 400,
            fontSmooth:'always',
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        h6: {
            fontSize: '1.25rem',
            fontWeight: 500,
            fontSmooth:'always'
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        subtitle3: {
            fontSize: '1rem',
            fontWeight: 600,
            fontSmooth:'always',
            color: '#5B5B5B'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        dialogTitle: {
            fontSize: '1.25rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        sessionTitle: {
            fontSize: '1.5rem',
            fontWeight: 600,
            fontSmooth:'always'
        }
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.MuiButton-textSecondary:hover, &.MuiButton-outlinedSecondary:hover': {
                        // backgroundColor: theme.palette.secondary.light,
                    },
                    '&.MuiButton-outlinedError:hover, &.MuiButton-textError:hover' : {
                        // backgroundColor: theme.palette.error.light,
                    },
                }),
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    ':not(.fullscreen-dialog) .MuiDialog-container > div': {
                        maxWidth: '700px'
                    },

                    '.MuiDialogTitle-root': {
                        paddingRight: '40px',
                    },

                    '.closeButton': {
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        // color: theme.palette.grey[500],
                    },

                    '@media only screen and (min-width: 1000px)': {
                        '.full-dialog-content .sessionInfo': {
                            flexDirection: 'row'
                        },
                        '.full-dialog-content .statusContainer' :{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '16px'
                        }
                    }
                },
            },
        },

        MuiAccordion: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.userEventCell:hover': {
                        backgroundColor: "#f6f6f6",
                    },
                    '&.userEventCell.Mui-expanded': {
                        backgroundColor: theme.palette.chipGrey.main
                    },
                    '&.userEventCell .MuiAccordionDetails-root': {
                        padding: "0 8px 8px",
                    },
                })
            },
        },


        MuiDialogContent: {
            defaultProps: {
                dividers: true,
            }
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.dropdownTable .MuiTableCell-root': {
                        borderBottom: 'unset'
                    }
                }
            }
        }

    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            lg2: 1300,
            xl: 1536,
        },
      },
})

export const darkTheme = createTheme({
    palette:{
        mode:'dark',
        appBar:{
            bar:'#000'
        },
        common:{
            black:'#000',
            white:'#fff',
            green:'#84BD00',
            lightGreen:'#C4D600'
        },
        primary:{
            main:'#84BD00',
            contrastText:'#fff'
        },
        error: {
            main: '#d32f2f'
        },
        info: {
            main: '#3274B4'
        },
        success: {
            main:'#84BD00',
        },
        section:{
            main:'#080402'
        },

        action:{
            hoverOpacity:0.15,
        },
        accessbility:{
            main:'#1976d2',
            light:'#42a5f5'
        },
        background:{
            main:'linear-gradient(47deg,#84BD00,#C4D600)'
        },
        covered: {
            main:'#3CA711',
            light: alpha(coveredMain, 0.5),
            dark: '#276d0c',
            contrastText:'#fff'
        },
        chipGrey: {
            main: '#464646',
            contrastText: '#fff'
        },

        tonalOffset: {
            light: 0.9,
            dark: 0.2
        },
        secondary:{
            main:'#fff',
            dark:'#fff',
        },
    },
    typography:{
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSmooth:'always',
        htmlFontSize: 16,
        h1: {
            fontSize: '6rem',
            fontWeight: 300,
            fontSmooth:'always',
        },
        h2: {
            fontSize: '3.75rem',
            fontWeight: 300,
            fontSmooth:'always'
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        h4: {
            fontSize: '2.125rem',
            fontWeight: 400,
            fontSmooth:'always',
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        h6: {
            fontSize: '1.25rem',
            fontWeight: 500,
            fontSmooth:'always'
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        subtitle3: {
            fontSize: '1rem',
            fontWeight: 600,
            fontSmooth:'always',
            color: '#5B5B5B'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            fontSmooth:'always',
            lineHeight: 'normal'
        },
        dialogTitle: {
            fontSize: '1.25rem',
            fontWeight: 400,
            fontSmooth:'always'
        },
        sessionTitle: {
            fontSize: '1.5rem',
            fontWeight: 600,
            fontSmooth:'always'
        }
    },

    components: {
        MuiDialog: {
            styleOverrides: {
                root: {
                    ':not(.fullscreen-dialog) .MuiDialog-container > div': {
                        maxWidth: '700px'
                    },

                    '.MuiDialogTitle-root': {
                        paddingRight: '40px',
                    },

                    '.closeButton': {
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        // color: theme.palette.grey[500],
                    },

                    '@media only screen and (min-width: 1000px)': {
                        '.full-dialog-content .sessionInfo': {
                            flexDirection: 'row'
                        },
                        '.full-dialog-content .statusContainer' :{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '16px'
                        }
                    
                    }
                },
            },
        },

        MuiDialogContent: {
            defaultProps: {
                dividers: true,
            }
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.dropdownTable .MuiTableCell-root': {
                        borderBottom: 'unset'
                    }
                }
            }
        },

        MuiAccordion: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.userEventCell .MuiAccordionDetails-root': {
                        padding: "0 8px 8px",
                    },
                })
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                '&:hover #more-button': {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                '#more-button:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                }
            }
        }
    },
    
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            lg2: 1300,
            xl: 1536,
        },
      },
})