import { format } from 'date-fns';
import {TimeOperators, TimeComparator} from './Time'
import { Status } from './Enums';
import InfoChip from '../components/InfoChip';
import { ErrorOutlineOutlined } from '@mui/icons-material';

const baseColumns = [
    { field: 'requestNum', headerName: 'Request Number', width: 130 },
    { field: 'title', headerName: 'Title', width: 130 },
    { field: 'accessType', headerName: 'Access Type', width: 130 },
    { 
        field: 'date',  
        headerName: 'Date', 
        width: 130, 
        type: 'date',
        valueFormatter: (value) => {
            return format(new Date(value), 'MM/dd/yyyy');
        }
    },
    { 
        field: 'startTime', 
        headerName: 'Start Time', 
        width: 130, 
        filterOperators: TimeOperators(),
        valueFormatter: (value) => {
            return format(new Date(value), "hh:mm aaaaa'm'");
        },
        sortComparator: TimeComparator,
    },
    { 
        field: 'endTime', 
        headerName: 'End Time', 
        width: 130, 
        filterOperators: TimeOperators(),
        valueFormatter: (value) => {
            return format(new Date(value), "hh:mm aaaaa'm'");
        },
        sortComparator: TimeComparator,
    },
    { 
        field: 'status', 
        headerName: 'Status', 
        width: 130,
        renderCell: (params) => {
            const status = Status[params.value];
            return (<InfoChip color={status.color} label={status.label} size='small' icon={status.label === 'Unassigned' ? <ErrorOutlineOutlined /> : null} />);
        },
        type: 'singleSelect',
        valueOptions: [
            { value: 1, label: 'Unreviewed' },
            { value: 2, label: 'Pending' },
            { value: 4, label: 'Assigned' },
            { value: 5, label: 'Unassigned' },
            { value: 6, label: 'Cancelled' },
        ],
    },
];

export {baseColumns}