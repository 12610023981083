/***********************************************\
*     Created by: ajpccl - AaronJoel Parker     *
\***********************************************/

import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Services from '../pages/Services'
import WebSpeech from '../pages/WebSpeech'
import Requests from '../widgets/Requests'
import Events from '../pages/Events'
import NewRequests from '../widgets/NewRequests'
import Translators from '../widgets/Translators'
import Approved from '../widgets/Approved'
import TerpSessions from '../widgets/TerpSessions'
import Profile from '../widgets/TerpProfile'
import Presenting from '../widgets/Presenting'
import Help from '../widgets/Help'
import ExpiredSessions from '../widgets/ExpiredSessions'
import VideoConference from '../pages/VideoConference'
import Schedule from '../pages/Schedule'
import ScheduleGrid from '../pages/ScheduleGrid'
import Alerts from '../widgets/Alerts'

const Service = ()=>
{
    return(
            <Routes>
                <Route path='/services' element={<Services />}>
                    <Route path='managerequests' element={<NewRequests />}/>
                    <Route path='requests' element={<Requests />}/>
                    {/* <Route path='approved' element={<Approved/>}/> */}
                    <Route path='translators' element={<Translators/>}/>
                    <Route path='profile' element={<Profile/>} default/>
                    <Route path='assignments' element={<TerpSessions/>}/>
                    <Route path='expiredsessions' element={<ExpiredSessions/>}/>
                    <Route path='presentation' element={<Presenting/>}/>
                    <Route path='schedule' element={<ScheduleGrid/>}/>
                    <Route path='accessproviders' element={<Translators/>}/>
                    {/* <Route path='scheduleold' element={<Schedule/>}/> */}
                    <Route path='Notifications' element={<Alerts/>}/>
                    <Route path='board' element={<Events/>}/>
                    <Route path='helpcenter'element={<Help />}/>
                </Route>
                <Route path='/broadcast' element={<VideoConference/>} />
                <Route path='/webspeech' element={<WebSpeech/>} />
            </Routes>
    )
}

export default Service