/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import Template from '../components/Template'
import Copyright from '../components/Copyright'
import {Grid, Box, Stack, Typography, Paper, Divider, Link} from '@mui/material'
import ToggleSwitch from '../components/ToggleSwitch'
import {useSelector} from 'react-redux'

import wd from '../media/WD.jpg'
import ap from '../media/AP.jpg'
import cs from '../media/CS.jpg'
import cr from '../media/CR.jpg'
import rm from '../media/RM.jpg'
import kd from '../media/KD.jpg'
import ja from '../media/JA.jpg'
import gv from '../media/GV.jpg'
import mn from '../media/MN.png'
import zs from '../media/ZS.png'
import nc from '../media/NC2.png'


const AboutUs = ()=>
{
    const [imgOpacity, setImgOpacity] = React.useState(0)

    React.useEffect(()=>{

        document.title='About Us'

        setTimeout(()=>{
            setImgOpacity(1)
            },500)
    },[])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return(
        <Template>
            <Box sx={{ textAlign:'center'}}>
                <Paper elevation={2} sx={{my:0, py:9, px:2}}>
                    <Typography variant='h3'>XR Accessibility Solutions Laboratory (XR ASL)</Typography>
                </Paper>
                <Grid container columns={{ xs: 12, sm: 12, md: 12, xl: 12}}>
                    <Grid item xs={0.5} sm={0.4} md={0.9} xl={1.5}/>
                        <Grid item xs={11} sm={11.2} md={10.2} xl={9}>
                            <Box sx={{pt:7}}>
                                <Stack spacing={2} sx={{m:0}}>
                                    <Typography variant='h4'><b>Mission</b></Typography>
                                    <Typography variant='p' sx={{fontSize:20, pb:6}}>XR ASL, housed in the NTID Research Deaf HUB, specializes in the use of real-time, immersive, and interactive technologies - including mixed, augmented, and virtual reality - to make physical spaces more accessible to deaf individuals.</Typography>
                                    <Divider />
                                    <Typography variant='h5' sx={{pt:2}}><b>The Team</b></Typography>
                                </Stack>
                            </Box>
                            <Box sx={{mt:2, justifyContent:'center', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                <Portrait path={wd} role='Associate Research Professor, XR ASL Director, and Principal Investigator' name='Wendy Dannel' imgOpacity={imgOpacity}/>
                                <Portrait path={ap} role='Software Engineer' name='Aaron Parker' imgOpacity={imgOpacity}/>
                                <Portrait path={cs} role='Software Development Engineer' flag={true} name='Connor Switenky' imgOpacity={imgOpacity}/>
                                <Portrait path={rm} role='Research Assistant' name='Roshan Mathew' imgOpacity={imgOpacity}/>
                                <Portrait path={nc} role='UI/UX Designer, and Developer' name='Nicohlas Capadano' imgOpacity={imgOpacity}/>
                                <Portrait path={kd} role='Research Assistant' name='Kelvin Ocampo-Diaz' imgOpacity={imgOpacity}/>
                                <Portrait path={cr} role='Application Developer' name='Chase Roth' imgOpacity={imgOpacity}/>
                                <Portrait path={ja} role='Research Assistant' flag={true} name='James Anderson' imgOpacity={imgOpacity}/>
                                <Portrait path={gv} role='CSS Developer & Research Assistant' flag={true} name='Gabriel Veit' imgOpacity={imgOpacity}/>
                                <Portrait path={mn} role='Research Assistant & UI/UX Designer' flag={true} name='Menna Nicola' imgOpacity={imgOpacity}/>
                                <Portrait path={zs} role='Unity Application Developer' flag={true} name='Zachary Selsley' imgOpacity={imgOpacity}/>
                            </Box>
                            <Box>
                                <Stack spacing={2} sx={{m:1}}>
                                    <Typography variant='h4'><b>Contact Us</b></Typography>
                                    <Typography variant='h5'><b>Email: </b><Link href='mailto:xrasl@rit.edu'>xrasl@rit.edu</Link></Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    <Grid item xs={0.5} sm={0.4} md={0.9} xl={1.5}/>
                </Grid>
                <Copyright/>
                <ToggleSwitch/>
            </Box>
        </Template>
    )
}

export default AboutUs


/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

const imgStyled = {
    maxWidth:'250px',
    maxHeight:'250px',
    objectFit:'cover',
    borderRadius:'5px'
}

const gridStyled = {
    width:330,
    m:3,
    '&:hover':{
        cursor:'pointer',
        transform:'scale(1.1)',
        border:'.1px solid #84BD00',
        borderRadius:'5px',
        borderImageSlice:1,
        borderImageSource: 'linear-gradient(to left, #C4D600, #84BD00)'
    },
    transition:'.5s'
}

const Portrait = (props)=>
{

    console.log('Boolean: '+props.flag)

    return(
        <Box sx={gridStyled}>
        <Paper 
            elevation={0}
            sx={{
                alignItems:'center',
                justifyContent:'center',
                opacity:props.imgOpacity,
                transition:'.3s',
                p:5,
                height:400,
                textAlign:'center'}}>
            <img src={props.path} style={imgStyled} alt={"Image of " + props.name}/>
            <Typography variant='h7' component='div'><b>{props.name}</b></Typography>
            <Typography variant='body2'>{props.role}</Typography>
        </Paper>
        </Box>
    )
}