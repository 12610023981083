/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import ActionButton from './ActionButton'
import AppBarDrawer from './AppBarDrawer'
import Box from '@mui/material/Box'
import { ErrorBoundary } from 'react-error-boundary'

const Template = (props)=>
{
    return(
        <Box sx={{m:0, p:0,minHeight:'100vh', minWidth:'100vw'}}>
            <AppBarDrawer/>
            <ErrorBoundary fallback={<div>Contact your adminstrator</div>}>
                {props.children}
            </ErrorBoundary>
            <ActionButton handleScroll={props.handleScroll}/>
        </Box>
    )
}

export default Template