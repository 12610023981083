import {apiSlice} from '../slices/api.slice'

const orgApi = apiSlice.injectEndpoints({
    // tagTypes: ['Org Sessions', 'NewRequests','Org Events','Presentations'],
    endpoints:build=>({
        unassignRequest:build.mutation({
            query:({id, requestId, session})=>({
                url:`/manager/ticket/?id=${id}&requestId=${requestId}&session=${session}`,
                method:'DELETE',
            }),
            invalidatesTags:['New Requests'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        requestAgency:build.mutation({
            query:initalRequest=>({
                url:'/manager/ticket',
                method:'POST',
                body:initalRequest,
            }),
            invalidatesTags: ['New Requests'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        orgRequestSession:build.mutation({
            query:initalRequest=>({
                url:'/orgRequest/',
                method:'POST',
                body:initalRequest,
            }),
            invalidatesTags:['Org Sessions'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        orgAddEvent:build.mutation({
            query:initalEvent=>({
                url:'/event',
                method:'POST',
                body:initalEvent,
            }),
            invalidatesTags:['Org Events'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        orgAddSessionEvent:build.mutation({
            query:initalSessionEvent=>({
                url:'/session',
                method:'POST',
                body:initalSessionEvent,
            }),
            invalidatesTags:['Org Events'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        retrievePresentations:build.query({
            query:token=>`/manager/presenting/?id=${token}`,
            providesTags:['Presentations']
        }),
        retrieveScheduledSessions:build.query({
            query:token=>`/manager/sessions/?id=${token}`,
            providesTags:['Org Sessions']
        }),
        retrieveRequests:build.query({
            query:token=>`/manager/requests/?id=${token}`,
            providesTags:['New Requests']
        }),
        retrieveScheduleEvent:build.query({
            query:token=>`/event/?id=${token}`,
            providesTags:['Org Events']
        }),
        cancelEventSession:build.mutation({
            query:({token, session})=>({
                url:`/request?id=${token}&session=${session}`,
                method:'DELETE',
            }),
            invalidatesTags:['Org Events']
        }),
        retrieveAgencies:build.query({
            query:token=>`/agencies`,
        }),
        deleteOrgSession:build.mutation({
            query:({token, session})=>({
                url:`/request?id=${token}&session=${session}`,
                method:'DELETE',
                
            }),
            invalidatesTags:['Org Sessions'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        })
    })
})

export const {
    useUnassignRequestMutation,
    useRequestAgencyMutation,
    useOrgRequestSessionMutation,
    useOrgAddEventMutation,
    useOrgAddSessionEventMutation,
    useRetrievePresentationsQuery,
    useRetrieveScheduledSessionsQuery,
    useRetrieveRequestsQuery,
    useRetrieveScheduleEventQuery,
    useCancelEventSessionMutation,
    useRetrieveAgenciesQuery,
    useDeleteOrgSessionMutation,
} = orgApi