import React, {useContext} from 'react'
import { Button, Grid,Box, FormControlLabel, Stack, FormControl, FormGroup, InputLabel, Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, Chip, IconButton, Backdrop, Typography, CircularProgress} from '@mui/material'
import { AddCircle, Delete } from '@mui/icons-material'
import ButtonStyled from '../components/ButtonStyled'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'
import {SingleInputTimeRangeField, MobileDatePicker, LocalizationProvider, TimePicker} from '@mui/x-date-pickers-pro'
import {useSelector} from 'react-redux'
import { useAddNewRequestMutation} from '../endpoints/member.endpoint'
import {useRetrieveOrgsQuery} from '../slices/api.slice'
import {SessionsContext} from '../utilities/Contexts'



const NewSession = (props)=>
{
    const [access, setAccess] = React.useState('')
    const [devMode] = React.useState(/true/.test(process.env.REACT_APP_MODE))
    const [org, setOrg] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [date, setDate] = React.useState(null)
    const [category, setCategory] = React.useState('')
    const [menuItem, setMenuItem] = React.useState(null)
    const [startTime, setStartTime] = React.useState(null)
    const [endTime, setEndTime] = React.useState(null)
    const [invitee, setInvitee] = React.useState([])
    const [checked, setChecked] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [list, setList] = React.useState([])
    const [delist, setDelist] = React.useState()
    const [titleError, setTitleError]=React.useState(false)
    const [categoryError, setCategoryError]=React.useState(false)
    const [orgError, setOrgError]=React.useState(false)
    const cleanInput = [true, true, true, true, true, true, true, true, true]
    const [accessTypeError, setAccessTypeError]=React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [pendingMsg, setPendingMsg] = React.useState('Submitting the request')
    const [addNewRequest] = useAddNewRequestMutation()
    const auth = useSelector(state=>state.auth)
    const {data:orgs,isLoading, isSuccess} = useRetrieveOrgsQuery()

    var index=5
    const batchSize=6
    const {collection, setCollection, filterSessions} = React.useContext(SessionsContext)

    if(isLoading)
    {

    }
    else if(isSuccess)
    {
      if(menuItem===null)
      {
        var arr = []

        orgs.data.map((org) => {

          arr.push(<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>)
        })

        setMenuItem(arr)
      }
    }

    const VALIDTIME=0
    var index=0

    const handleCheck = (e)=>
    {
      setChecked(e.target.checked)
    }
    
    const handleClose = () => {
        props.setOpen(false);
    };

    const checkInput =(textInput,index)=>
    {
      if(textInput==='' || textInput===undefined || textInput===null || textInput===0)
      {
        cleanInput[index] = false;
        return true
      }
      else
      {
        cleanInput[index]= true
        return false
      }
    }

    const checkInputRequirements=()=>
    {
      if(checkInput(title,0))
        {
          setTitleError(true)
        }
        if(checkInput(category,1))
        {
          setCategoryError(true)
        }
        if(checkInput(access,2))
        {
          setAccessTypeError(true)
        }
        if(checkInput(org,3))
        {
          console.log('Organization')
          console.log(org)
          setOrgError(true)
        }

        if(checkInput(date,4))
        {
          setDate('')
        }

        if(checkInput(startTime,5))
        {
          
          setStartTime('')
        }

        if(checkInput(endTime,6))
        {
          setEndTime('')
        }
    }

    const batch=(data)=>{
      
      var arr= collection

      if(arr.length >0)
      {
        for(var i in arr)
          {
            console.log(arr[i])
            if(arr[i].length < batchSize)
            {
              console.log('Pushing data into array')
              arr[i].push(data)
    
              console.log(arr[i])
            }
          }
      }
      else
      {
        arr.push([data])
      }

      console.log(arr)

      setCollection(arr)
      
      setTimeout(()=>{
        console.log(collection)
        filterSessions()
      },500)
    }

    const DateTimeSanitization=(_date, _time)=>{
      
      const fixedDate = new Date(new Date(_date).getFullYear(), new Date(_date).getMonth(), new Date(_date).getDate(), new Date(_time).getHours(), new Date(_time).getMinutes())

      console.log(fixedDate)

      return fixedDate
    }

    const createRequestTask=async()=>{
      await addNewRequest({
        title:title,
        description:description,
        categoryId:category,
        accessType:access,
        org:org,
        date:date,
        endTime:DateTimeSanitization(date, endTime),
        startTime:DateTimeSanitization(date, startTime),
        invitees:checked?invitee:null,
        id:auth.token})
        .then((_data)=>{
          setTimeout(()=>{
            setPendingMsg("Request successfully submitted")
            setTimeout(()=>{
              // batch(subscribed)
              if(!devMode)
              {
                setTitle('')
                setInvitee([])
                setChecked(false)
                setDate(null)
                setOrg(null)
                setAccess(null)
                setCategory(null)
                setStartTime(null)
                setEndTime(null)
              }
              setOpen(false)
              setDescription('')
              handleClose()
            },1000)
          },1000)
        })
    }
    
      const postRequest =async()=>
      { 

        if(Object.values(cleanInput).every(Boolean))
        {
          setPendingMsg("Submitting the request")
          setOpen(true)

          await createRequestTask();
        }
      }

      const handleRequest =()=>
      {
        checkInputRequirements()

        postRequest()

      }

  const handleTitle = (e)=>
  {
    setTitle(e.target.value)
    setTitleError(e.target.value!==''?false:true)
  }

  const handleOrg = (e)=>
  {
    setOrg(e.target.value)
    setOrgError(e.target.value!==''?false:true)
  }

  const handleAccess = (e)=>
  {
    setAccess(e.target.value)

    setAccessTypeError(e.target.value!==0?false:true)
  }

  const handleDescription = (e)=>
  {
    setDescription(e.target.value)
  }

  const handleEmail = (e)=>
  {
    setEmail(e.target.value)
  }

  const handleDate = (e)=>
  {
    console.log(e)
    if(e===null)
    {
      return
    }

    setDate(e)
  }

    const deleteEmail = (email)=>
    {
      console.log(email)

      setDelist(email)
    }

    const handleKey = (e)=>
    {

       if(e.key ==='Enter')
       {
          addEmail()  
       }
    }

    const addEmail=()=>
    {
      if(email!=='')
      {
        setInvitee(invitee.concat(email))
        setList(list.concat(<Chip id={email} key={index} onDelete={()=>{deleteEmail(email)}} label={email}  sx={{width:180, m:.3}}/>))
        setEmail('')
        index++
      }
    }

    React.useEffect(()=>{
      console.log('Title: '+(title===''))
      if(list.length > 0)
      {
        setList(list.filter(value=>value.props.id!==delist))
        setInvitee(invitee.filter(value=>value!==delist))
      }
    },[delist])

    React.useEffect(()=>{

      if(date!==null)
      {
        if(startTime!==null) { setStartTime(DateTimeSanitization(date, startTime)) }
        
        if(endTime!==null) { DateTimeSanitization(date, endTime) }
      }

    },[date])


    const handleCategory=(e)=>
    {
      setCategory(e.target.value)
      setCategoryError(e.target.value!==''?false:true)
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog 
            sx={{overflow:'hidden'}}
            open={props.open} 
            onClose={handleClose}>
            <DialogTitle>New Request</DialogTitle>
            <DialogContent>
              <Grid container 
                    columns={{xs: 8, sm: 8}}
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2}}>
                <Grid item xs={8} sm={8}>
                  <TextField
                      autoFocus
                      required={true}
                      margin="dense"
                      id="name"
                      label="Title"
                      error={titleError}
                      value={title}
                      onChange={handleTitle}
                      inputProps={{ maxLength: 50 }}
                      type="text"
                      fullWidth/>
                </Grid>
                <Grid item xs={8} sm={8}>
                <FormControl 
                required
                error={orgError}
                fullWidth>
                    <InputLabel>Organization</InputLabel>
                    <Select 
                      value={org}
                      label='organization'
                      onChange={handleOrg}>
                      {menuItem}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <FormControl 
                  required
                  error={accessTypeError}
                  fullWidth>
                    <InputLabel>Access Type</InputLabel>
                    <Select 
                      value={access}
                      label='Access Type'
                      onChange={handleAccess}>
                      <MenuItem value={1}>Interpreter</MenuItem>
                      <MenuItem value={2}>Captioner</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <FormControl 
                  required={true}
                  error={categoryError}
                  fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select 
                      value={category}
                      label='Category'
                      onChange={handleCategory}>
                      <MenuItem value={1}>Medical</MenuItem>
                      <MenuItem value={2}>Class</MenuItem>
                      <MenuItem value={3}>Sport</MenuItem>
                      <MenuItem value={4}>Concert</MenuItem>
                      <MenuItem value={5}>Meeting</MenuItem>
                      <MenuItem value={6}>Conference</MenuItem>
                      <MenuItem value={7}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <DemoContainer components={['MobileDatePicker']}>
                    <DemoItem>
                  <MobileDatePicker
                    label='Date'
                    disablePast
                    onChange={handleDate}
                    value={date}
                    renderInput={(params)=>(
                    <TextField
                      required
                      {...params} />
                      )}/>
                      </DemoItem>
                      </DemoContainer>
                </Grid>
                <Grid item xs={8} sm={4}>
                <DemoContainer components={['TimePicker']}>
                    <DemoItem>
                    <TimePicker
                            label='Start Time'
                            value={startTime}
                            onChange={(newValue)=>setStartTime(newValue)}
                            maxTime={endTime}
                          />
                    </DemoItem>
                </DemoContainer>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <DemoContainer components={['TimePicker']}>
                        <DemoItem>
                          <TimePicker
                            label='End Time'
                            value={endTime}
                            onChange={(newValue)=>setEndTime(newValue)}
                            minTime={startTime}
                          />
                        </DemoItem>
                    </DemoContainer>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Description (optional but recommended)"
                      value={description}
                      onChange={handleDescription}
                      type="text"
                      multiline={true}
                      maxRows='2'
                      minRows='2'
                      fullWidth
                      variant="filled"/>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheck} />} label='Invite members'/>
                  </FormGroup>
                  <Stack direction='row' spacing={1}>
                    <TextField
                        autoFocus
                        onKeyDown={handleKey}
                        disabled={!checked}
                        margin="dense"
                        id="name"
                        label="Email"
                        value={email}
                        onChange={handleEmail}
                        inputProps={{ maxLength: 50 }}
                        type="text"
                        fullWidth/>
                      <Box>
                        <IconButton  sx={{mt:1.5}} onClick={addEmail}>
                          <AddCircle fontSize='large' sx={{color:'#84BD00'}}/>
                        </IconButton>
                      </Box>
                    </Stack>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <Box sx={{overflow:'scroll',maxHeight:150, display:checked?'block':'none'}}>
                    {list}
                  </Box>
                </Grid>
                <Grid item/>
                  <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={open}>
                      <Box sx={{display:'block', ml:'auto',mr:'auto'}}>
                        <Box>
                          <CircularProgress 
                              color='success'
                              size='25vh'
                              sx={{display:'block', ml:'auto',mr:'auto'}}/>
                        </Box>
                        <Box sx={{display:'flex', justifyContent:'center', opacity:1}}>
                            <Typography variant='h2' sx={{m:'auto', fontSize:{xs: '2.5rem', sm: '3rem'}}}><b>{pendingMsg}</b></Typography>
                        </Box>
                      </Box>
                  </Backdrop>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonStyled action={handleClose} variant='outlined' color='error' title='Cancel' />
              <ButtonStyled action={handleRequest} variant='contained' color='primary' title='Confirm' />
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
    )
}

export default NewSession