/********************************************************
 *                                                      *
 *           Created by: gvccl - Gabriel Veit           *
 *           Modified by: ajpccl - AaronJoel Parker     *
 *                                                      *
 ********************************************************/

import React,{useState, useEffect, useMemo} from 'react'
import {useSelector} from'react-redux'
import { Access } from '../utilities/Enums';
import { Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useRetrieveTranslatorsQuery } from '../endpoints/agency.endpoint';
import { ErrorBoundary } from 'react-error-boundary';
import { 
    DataGridPro, 
    GridToolbarContainer, 
    GridToolbarColumnsButton, 
    GridToolbarDensitySelector, 
    GridToolbarFilterButton, 
    GridToolbarExport } from '@mui/x-data-grid-pro';
import ButtonStyled from '../components/ButtonStyled';
import NewTranslator from '../components/NewTranslator';


//#region Translators
const Translators=()=>{

    const auth = useSelector(state=>state.auth)
    const [rows, setRows] = useState([])
    const [open, setOpen]= useState(false)
    const {
        data:translators, 
        isSuccess
    } = useRetrieveTranslatorsQuery(auth.token)
    

    useEffect(()=>{
        if(isSuccess)
        {
            console.log(translators)
            let row = []
            translators.result.map((translator,index)=>{
                console.log(translator.mem)
                console.log(index)

                row.push({
                    id:index,
                    accessType:Access[translator.access_id],
                    firstName:translator.mem.first_name,
                    lastName:translator.mem.last_name,
                    email:translator.mem.email
                })

                return translator
            })

            setRows(row)
        }
    },[isSuccess, translators])

    const columns = useMemo(()=> [
            {
                field:'accessType',
                headerName:'Access Type',
                width:160,
            },
            {
                field:'firstName',
                headerName:'First Name',
                width:160,
            },
            {
                field:'lastName',
                headerName:'Last Name',
                width:160,
            },
            {
                field:'email',
                headerName:'Email',
                width:160
            }
        ],[])

    const openDialog=()=>{
        setOpen(true)
    }

    const CustomToolBar=()=>{
        return <GridToolbarContainer sx={{m:1}}>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport
            slotProps={{
                tooltip: { title: 'Export data' },
            }}/>
            <Box sx={{flexGrow:1}}/>
             <ButtonStyled startIcon={<Add/>} action={openDialog} title='NEW ACCESS PROVIDER' size={'medium'} backgroundColor={'lightgray'} textColor={'#434343'}/>
        </GridToolbarContainer>
    }

    return(
        <ErrorBoundary fallback={<div>Whoops</div>}>
            <Box sx={{width:'100%', height:400}}>
            <NewTranslator setOpen={setOpen} open={open}/>
            <DataGridPro
                autoHeight
                rows={rows}
                columns={columns}
                slots={{toolbar:CustomToolBar}}
                pagination
                />
            </Box>
        </ErrorBoundary>
    )

}

export default Translators
