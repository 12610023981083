import {apiSlice} from '../slices/api.slice'

const memberApi = apiSlice.injectEndpoints({
    endpoints:build=>({
        logIn:build.mutation({
            query:initialLogIn=>({
                url:`/auth`,
                method:'POST',
                body:initialLogIn
            }),
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        logOut:build.mutation({
            query:initialLogOut=>({
                url:`/auth/`,
                method:'POST',
                body:initialLogOut
            }),
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        addNewRequest:build.mutation({
            query:newRequest=>({
                url:`/request/`,
                method:'POST',
                body:newRequest
            }),
            invalidatesTags: ['Member Sessions'],
            transformResponse:(res)=>{
                console.log(res)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        retrieveSessions:build.query({
            query:token=>`/member/sessions/?id=${token}`,
            providesTags: ['Member Sessions']
        }),
        cancelSession:build.mutation({
            query:({token,request})=>({
                url:`/member/session/?id=${token}&request=${request}`,
                method:'DELETE',
            }),
            invalidatesTags: ['Member Sessions'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        retrieveProfile:build.query({
            query:token=>`/member/?id=${token}`,
            providesTags:['Member Profile']
        }),
        updateProfile:build.mutation({
            query:updateProfile=>({
                url:`/members`,
                method:'PUT',
                body:updateProfile
            }),
            invalidatesTags:['Member Profile'],
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        passwordReset:build.mutation({
            query:passwordReset=>({
                url:`/member/reset/`,
                method:'POST',
                body:passwordReset
            }),
            transformResponse:(res)=>{
                console.log(res.result)
                return res.result
            },
            transformErrorResponse:(response, meta, arg)=>{
                console.log(response)
            },
        }),
        retrieveEvents:build.query({
            query:token=>`/public?id=${token}`,
            providesTags:['Member Events']
        }),
    })
})

export const {useLogInMutation, 
    useLogOutMutation,  
    useAddNewRequestMutation,
    useRetrieveSessionsQuery,
    useCancelSessionMutation,
    useRetrieveProfileQuery,
    useUpdateProfileMutation,
    usePasswordResetMutation,
    useRetrieveEventsQuery,
} = memberApi