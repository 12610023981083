/********************************************************
 *                                                      *
 *    Created by: jpaccl - James Patrick Anderson       *
 *                                                      *
 *                                                      *
 ********************************************************/

import React from 'react'
import {Box, Grid, Typography, Paper} from '@mui/material'
import axios from 'axios'
import SearchBar from '../components/SearchBar'
import Tag from '../components/Tags'
import { useSelector} from 'react-redux'

//#region Expired Sessions
const ExpiredSessions = (props) => {
  const [eventData, setEventData] = React.useState(null)
  const [inputText, setInputText] = React.useState('')
  const [tag, setTag] = React.useState('Title')
  const routePath = {'2':'manager','4':'translator'}
  const auth = useSelector(state=>state.auth)

  React.useEffect(()=>{
      document.title = 'Expired Sessions'
      
      axios.get(process.env.REACT_APP_LOCALHOST+`/${routePath[auth.role]}/expired/?id=`+auth.token)
      .then((res)=>{
          
          if(res.status===200)
          {
              return res.data.result
          }
      })
      .then((data)=>{

        var arr = []
          console.log(data)

          if(data.length > 0)
          {
            data.map((expired, index)=>
            {
              console.log(expired.session)

              var expire = expired.session

              var date = expired.session.requests[0].date

              var cell = <TerpEventCell key={index} title={expire.title} startTime={expire.start_time} endTime={expire.end_time} date={date} categoryId={expire.category_id}/>

              arr.push(cell)

            })

            setEventData(arr)
          }
          
      })
      .catch((err)=>{
          console.log(err)
      })
  },[])

  return (
    <div>
      <Grid container columns={{md: 8}} sx={{minHeight:'40em'}}>
        <Grid item md={0.5}/>
        <Grid item md={7}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid 
              container 
              columns={{md: 6}}
              sx={{maxWidth:1600, pt:6}}>
              <Grid item md={8}>
                <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:3}}>
                  <Box>
                    <SearchBar setInputText={setInputText}/>
                    <Tag setTag={setTag}/>
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={1.5}>
                <Typography variant='h6' sx={{textAlign:'center'}}>Sessions</Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography variant='h6' sx={{textAlign:'center'}}>Category</Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography variant='h6' sx={{textAlign:'center'}}>Date</Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography variant='h6' sx={{textAlign:'center'}}>Time</Typography>
              </Grid>
              <Grid item md={6}>
              {eventData}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item/>
    </Grid>
    </div>
  )
}

export default ExpiredSessions
//#endregion

//#region TerpEventCell
const TerpEventCell = (props) => {
    const [timeRange, setTimeRange] = React.useState()
    const [dateRange, setDateRange] = React.useState()
    const [cardElevation, setCardElevation] = React.useState(4)

    const categories = {0:'medical',1:'class',2:'sport',3:'concert',4:'meeting',5:'conference',6:'other'}


    const hoverEnter = ()=>
    {
        setCardElevation(14)
    }

    const hoverLeave = ()=>
    {
        setCardElevation(4)
    }

    const getSessions=()=>
    {
        console.log('Event ID: '+props.Id)
    }

  return (
    <Box sx={{my:1}} id={props.orgId} onLoad={props.resolve} onError={props.reject} onClick={getSessions}>
            <Paper elevation={cardElevation}
               sx={{
                p:2,
                textAlign:'center',
                '&:hover':{
                    cursor:'pointer',
                    transform:'scale(1.025)'
                },
                transition:'.5s',
                maxWidth:1500}}
                onMouseEnter={hoverEnter}
                onMouseLeave={hoverLeave}>
                <Grid 
                    container 
                    columns={{md: 6}}>
                    <Grid item md={1.5}>
                        <Typography variant='body1' sx={{textAlign:'center'}}>{props.title}</Typography>
                    </Grid>
                    <Grid item md={1.5}>
                        <Typography variant='body1' sx={{textAlign:'center'}}>{categories[props.categoryId]}</Typography>
                    </Grid>
                    <Grid item md={1.5}>
                        <Typography variant='body1' sx={{textAlign:'center'}}>{props.date}</Typography>
                    </Grid>
                    <Grid item md={1.5}>
                        <Typography variant='body1' sx={{textAlign:'center'}}>{`${props.startTime} - ${props.endTime}`}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
  )
}
//#endregion

