/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import { configureStore } from '@reduxjs/toolkit'

import theme from '../slices/theme.slice'
import auth from '../slices/auth.slice'
import stream from '../slices/stream.slice'
import mcu from '../slices/mcu.slice'
import navi from '../slices/navi.slice'
import { apiSlice } from '../slices/api.slice'


export default configureStore({
    reducer:{
        theme:theme,
        auth:auth,
        stream:stream,
        mcu:mcu,
        navi:navi,
        [apiSlice.reducerPath]:apiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})