import React, {useContext} from 'react'
import { Box , Button, Typography, Grid, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemText, List, Checkbox, FormControlLabel, Backdrop, CircularProgress} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {SingleInputTimeRangeField, MobileDatePicker, LocalizationProvider, TimePicker} from '@mui/x-date-pickers-pro'
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'
import ButtonStyled from './ButtonStyled'
import {useSelector} from'react-redux'
import { parseISO, parse, format, setDate } from 'date-fns'
import { EventsContext, SessionEventsContext } from '../utilities/Contexts'
import { useOrgAddSessionEventMutation } from '../endpoints/org.endpoint'


const NewEventSession = (props)=>
{   
    const [startTime, setStartTime] = React.useState(null)
    const [endTime, setEndTime] = React.useState(null)
    const [description, setDescription] = React.useState()
    const [date, setDate] = React.useState(props.eventSet?.date || null)
    const [title, setTitle] = React.useState('')
    const [category, setCategory] = React.useState()
    const [titleError, setTitleError] = React.useState()
    const [categoryError, setCategoryError] = React.useState()
    const [sublocation, setSublocation] = React.useState()
    const [sublocationError, setSublocationError] = React.useState(false)
    const [descriptionError,setDescriptionError] = React.useState()
    const [startTimeError, setStartTimeError] = React.useState()
    const [endTimeError, setEndTimeError] = React.useState()
    const [accessTypeError, setAccessTypeError]=React.useState(false)
    const cleanInput = [true,true,true,true,true,true,true,true,true]
    const [pendingMsg, setPendingMsg] = React.useState('Submitting the request')
    const [open, setOpen] = React.useState(false)
    const auth = useSelector(state=>state.auth)
    const { rows}= useContext(EventsContext)
    const [letters, setLetters] = React.useState([])
    // const {sessions, setSessions} = useContext(SessionEventsContext)
    const [orgAddSessionEvent] = useOrgAddSessionEventMutation()
    const [access, setAccess] = React.useState(auth.role===5?'':'N/A')

    const _rows = JSON.parse(JSON.stringify(rows))
    console.log(props.eventSet)

    React.useEffect(()=>{

        console.log(startTime)
        console.log(endTime)
        console.log(props.eventSet.date)
        setDate(props.eventSet.date ||null)

    },[startTime, endTime, props.eventSet.date])

    React.useEffect(()=>{
      console.log(date)
    },[date])

    const handleAccess = (e)=>
    {
      setAccess(e.target.value)
  
      setAccessTypeError(e.target.value!==''?false:true)
    }

    const handleRequest = ()=>
    {
      checkInputRequirements()

      postRequest()
    }

    const DateTimeSanitization=(_date, _time)=>{

      console.log(_date)
      
      const fixedDate = new Date(new Date(_date).getFullYear(), new Date(_date).getMonth(), new Date(_date).getDate(), new Date(_time).getHours(), new Date(_time).getMinutes())

      return fixedDate
    }

    const postRequest = async()=>
    {
      if(Object.values(cleanInput).every(Boolean))
      {
          setPendingMsg("Submitting the request")
          setOpen(true)
          const result = await orgAddSessionEvent({
            title:title, 
            startTime:DateTimeSanitization(date,startTime), 
            endTime:DateTimeSanitization(date,endTime), 
            accessType:access,
            categoryId:category,
            sublocation:sublocation,
            eventId:props.eventSet.eventId, 
            description:description, 
            date:date, 
            id:auth.token})

            console.log(result)
            
            setTimeout(()=>{
              setPendingMsg("Request successfully submitted")
              setTimeout(()=>{
                setOpen(false)
                props.setOpen(false)
              },2000)
            },2000)
      }
    }

    const checkInput =(textInput,index)=>
    {
      if(textInput==='' || textInput===undefined || textInput===null)
      {
        cleanInput[index] = false;
        return true
      }
      else
      {
        cleanInput[index]= true
        return false
      }
    }

    const checkInputRequirements=()=>
    {
      if(checkInput(title,0))
        {
          setTitleError(true)
        }
        if(checkInput(category,1))
        {
          setCategoryError(true)
        }
        if(checkInput(startTime,2))
        {
          // setStartTimeError(true)
        }
        if(checkInput(endTime,3))
        {
          // setEndTimeError(true)
        }
        if(checkInput(description,4))
        {
          setDescriptionError(true)
        }
        if(checkInput(access,5))
        {
          setAccessTypeError(true)
        }

        if(checkInput(endTime,6))
        {
          setEndTime('')
        }

        if(checkInput(startTime,7))
        {
          setStartTime('')
        }
        if(checkInput(sublocation,8))
        {
          setSublocationError(true)
        }
    }

    React.useEffect(()=>{
      console.log(letters.length)
      if(letters.length > 255)
      {
        setDescriptionError(true)
      }
    },[letters])


    const handleCategory = (e)=>
    {
      setCategory(e.target.value)
      setCategoryError(e.target.value!==''?false:true)
    }

    const handleTitle = (e)=>
    {
        setTitle(e.target.value)
        setTitleError(e.target.value!==''?false:true)
    }

    const handleSublocation = (e)=>
      {
          setSublocation(e.target.value)
          setSublocationError(e.target.value!==''?false:true)
      }

    const handleDescription = (e)=>
    {
        setDescription(e.target.value)
        setDescriptionError(e.target.value!==''?false:true)
        var arr = Array.from(e.target.value)

        setLetters(arr)
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={props.open}>
            <DialogTitle>New Session</DialogTitle>
            <DialogContent>
              <Grid container 
                    columns={{xs: 8, sm: 8}}
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2}}>
                <Grid item xs={8} sm={8}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Title"
                    value={title}
                    error={titleError}
                    onChange={handleTitle}
                    inputProps={{ maxLength: 30 }}
                    type="text"
                    fullWidth
                    />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Sublocation"
                    value={sublocation}
                    error={sublocationError}
                    onChange={handleSublocation}
                    inputProps={{ maxLength: 30 }}
                    type="text"
                    fullWidth
                    />
                </Grid>
                {auth.role === 5 &&<Grid item xs={8} sm={8}>
                <FormControl 
                  required
                  error={accessTypeError}
                  fullWidth>
                    <InputLabel>Access Type</InputLabel>
                    <Select 
                      value={access}
                      label='Access Type'
                      onChange={handleAccess}>
                      <MenuItem value={1}>Interpreter</MenuItem>
                      <MenuItem value={2}>Captioner</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>}
                <Grid item xs={8} sm={8}>
                  <FormControl fullWidth
                  error={categoryError}
                  required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      
                      value={category}
                      label='Category'
                      onChange={handleCategory}>
                      <MenuItem value={1}>Medical</MenuItem>
                      <MenuItem value={2}>Class</MenuItem>
                      <MenuItem value={3}>Sport</MenuItem>
                      <MenuItem value={4}>Concert</MenuItem>
                      <MenuItem value={5}>Meeting</MenuItem>
                      <MenuItem value={6}>Conference</MenuItem>
                      <MenuItem value={7}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={4}>
                <DemoContainer components={['TimePicker']}>
                    <DemoItem>
                  <TimePicker
                  label='Start Time'
                  
                  value={startTime}
                  minTime={parseISO(props.eventSet.startTime)}
                  maxTime={endTime===null?parseISO(props.eventSet.endTime):endTime}
                  onChange={(newValue)=>setStartTime(newValue)}/>
                  </DemoItem>
                  </DemoContainer>
                  {props.eventSet.startTime && <Typography sx={{m:1}}>{`Min Time: ${format(new Date(props.eventSet.startTime), "hh:mm aaaaa'm'")}`}</Typography>}
                </Grid>
                <Grid item xs={8} sm={4}>
                <DemoContainer components={['TimePicker']}>
                    <DemoItem>
                  <TimePicker
                  label='End Time'
                  required
                  value={endTime}
                  minTime={startTime===null?parseISO(props.eventSet.startTime):startTime}
                  maxTime={parseISO(props.eventSet.endTime)}
                  onChange={(newValue)=>setEndTime(newValue)}/>
                  </DemoItem>
                  </DemoContainer>
                  {props.eventSet.endTime && <Typography sx={{m:1}}>{`Max Time: ${format(new Date(props.eventSet.endTime), "hh:mm aaaaa'm'")}`}</Typography>}
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Description"
                    value={description}
                    onChange={handleDescription}
                    error={descriptionError}
                    maxLength={255}
                    type="text"
                    multiline={true}
                    maxRows='3'
                    minRows='3'
                    fullWidth
                    helperText={`Characters: ${letters.length}/255`}
                    variant="filled"/>
                </Grid>
                <Grid item/>
                  <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={open}>
                      <Box sx={{display:'block', ml:'auto',mr:'auto'}}>
                        <Box>
                          <CircularProgress 
                              color='success'
                              size='25vh'
                              sx={{display:'block', ml:'auto',mr:'auto'}}/>
                        </Box>
                        <Box sx={{display:'flex', justifyContent:'center', opacity:1}}>
                            <Typography variant='h2' sx={{m:'auto', fontSize:{xs: '2.5rem', sm: '3rem'}}}><b>{pendingMsg}</b></Typography>
                        </Box>
                      </Box>
                  </Backdrop>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonStyled variant='outlined' color='error' action={()=>{props.setOpen(false)}} title='Cancel' />
              <ButtonStyled variant='contained' color='primary' action={handleRequest} title='Confirm' />
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
    )
}

export default NewEventSession