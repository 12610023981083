/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import { useSearchParams,useNavigate } from 'react-router-dom'
import { Typography, Box, TextField,Button } from '@mui/material'
import Template from '../components/Template'
import ButtonStyled from '../components/ButtonStyled'
import { usePasswordResetMutation } from '../endpoints/member.endpoint'

const PasswordReset=()=>
{
    const [newPass, setNewPass] = React.useState('')
    const [confirmPass, setConfirmPass] = React.useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [token, setToken] = React.useState('')
    const [newError, setNewError] = React.useState(false)
    const [confirmError, setConfirmError] = React.useState(false)
    const [helperText, setHelperText] = React.useState('')
    const [confirmHelperText, setConfirmHelperText] = React.useState('')
    const [passwordReset,{isLoading}] = usePasswordResetMutation()

    const history = useNavigate()

    const NewPass =(e)=>
    {
        setNewPass(e.target.value)
    }

    const ConfirmPass =(e)=>
    {
        setConfirmPass(e.target.value)
    }

    const sendPasswordReset=async()=>
    {
        if(!newError && !confirmError)
        {
            await passwordReset({token:token,password:newPass})
            .then(result=>{
                history('/')
            })
            .catch((err)=>{
                 console.log(err)
            })
        }
    }

    React.useEffect(()=>{
        setToken(searchParams.get('token'))
    },[])

    React.useEffect(()=>{
        console.log(token)
    },[token])

    React.useEffect(()=>{

        if(newPass !== '')
        {
            const result = passwordReset({token:token,password:newPass})
            // axios.post(process.env.REACT_APP_LOCALHOST+`/auth/old`,{
            //     token:token,
            //     password:newPass
            // })
            // .then((res)=>{

            //     if(res.data.result==='true')
            //     {
            //         setHelperText('Must not have previous password')
            //         setNewError(true)
            //     }
            //     else
            //     {
            //         setHelperText('')
            //         setNewError(false)
            //     }
    
            // })
            // .catch((err)=>{
    
            //     console.log(err)
    
            // })
        }
    },[newPass])

    React.useEffect(()=>{

        if(confirmPass !== newPass || confirmPass !=='')
        {
            setConfirmError(true)
            setConfirmHelperText('Must match the new password')
        }
        else
        {
            setConfirmError(false)
            setConfirmHelperText('')
        }

    },[confirmPass])

    return(
        <Template>
            <Box sx={{p:'auto', height:'100vh'}}>
                <Box sx={{textAlign:'center'}}>
                    <Typography variant='h2' sx={{m:2}}></Typography>
                </Box>
                <Box
                    sx={{
                        '& .MuiTextField-root': { m: 1},
                        textAlign:'center',
                        m:'auto',
                        p:3,
                        borderRadius:5,
                        boxShadow:2,
                        width:350,
                        border:2, 
                        borderColor:'#84BD00',
                        alignContent:'center'
                    }}
                    noValidate>
                        <TextField
                            required
                            value={newPass}
                            color='success'
                            id='outlined-required'
                            label='New Password'
                            error={newError}
                            helperText={helperText}
                            placeholder='Enter New Password'
                            size='large'
                            onChange={NewPass}
                            sx={{
                                '&$error':{
                                    color:'red'
                                }
                            }}/>
                        <TextField
                            required
                            value={confirmPass}
                            color='success'
                            id='outlined-required'
                            label='Confirm Password'
                            placeholder='Enter Confirm Password'
                            size='large'
                            helperText={confirmHelperText}
                            error={confirmError}
                            onChange={ConfirmPass}
                            sx={{
                                '&$error':{
                                    color:'red'
                                }
                            }}/>
                        <ButtonStyled action={sendPasswordReset} title='Password Reset' marginTop={2}/>
                    </Box>
                </Box>
            </Template>
    )
}

export default PasswordReset