/********************************************************
 *                                                      *
 *    Created by: jpaccl - James Patrick Anderson       *
 *                                                      *
 *                                                      *
 ********************************************************/

import { Grid, TextField, Box, Typography, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material'
import React from 'react'
import ButtonStyled from '../components/ButtonStyled';
import axios from 'axios'
import { useSelector } from'react-redux'

const Profile = () => {
  const [role, setRole] = React.useState('')
  const [toggle, setToggle] = React.useState(true)
  const [title, setTitle] = React.useState('EDIT')
  const [firstName, setFirstName] = React.useState('Test')
  const [email, setEmail] = React.useState('test@gmail.com')
  const [lastName, setLastName] = React.useState('Two')
  const [username, setUsername] = React.useState('')
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [lastNameError, setLastNameError] = React.useState(false)
  const [usernameError, setUsernameError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [roleError, setRoleError] = React.useState(false)
  const [selected, setSelected] = React.useState(false)
  const [scale, setScale] = React.useState('scale(1.0)')
  const [opacity, setOpacity] = React.useState(0)
  const cleanInput = [true, true,true, true, true]
  const [pendingMsg, setPendingMsg] = React.useState('your update information have been saved.')
  const [color, setColor] = React.useState('green')
  const auth = useSelector((state)=>state.auth)

  const roleChange = (e) => {
    setRole(e.target.value)
  }

  const checkInput =(textInput,index)=>
  {
    if(textInput==='' || textInput===undefined || textInput===null)
    {
      cleanInput[index] = false;
      return true
    }
    else
    {
      cleanInput[index]= true
      return false
    }
  }

  const checkInputRequirements=()=>
  {
    if(checkInput(firstName,0))
    {
      setFirstNameError(true)
    }
    else
    {
      setFirstNameError(false)
    }

    if(checkInput(lastName,1))
    {
      setLastNameError(true)
    }
    else
    {
      setLastNameError(false)
    }

    if(checkInput(username, 2))
    {
      setUsernameError(true)
    }
    else
    {
      setUsernameError(false)
    }

    if(checkInput(email,3))
    {
      setEmailError(true)
    }
    else
    {
      setEmailError(false)
    }

    if(checkInput(role,4))
    {
      setRoleError(true)
    }
    else
    {
      setRoleError(false)
    }
  }

  const onEdit = (e) => {

    if(title==='EDIT'){
      setTitle('SAVE')
      setToggle(false)
    } else {

      checkInputRequirements()

      if(Object.values(cleanInput).every(Boolean))
      {
        setPendingMsg('your update information have been saved.')
        setColor('green')
        axios.put(process.env.REACT_APP_LOCALHOST+'/translators',{
            firstName:firstName,
            lastName:lastName,
            email:email,
            username:username,
            accessId:role,
            id:auth.token
        })
        .then((data)=>{
          setTimeout(()=>{
            setOpacity(1)
            setScale('scale(1.1)')
            setTitle('EDIT')
            setToggle(true)
            setTimeout(()=>{
              setOpacity(0)
              setScale('scale(1.0)')
            },5000)
          },500)
        })
        .catch((err)=>{
          console.log(err.message)
        })
      }
      else
      {   setPendingMsg('One of your fields are blank, please fill it in or cancel.')
          setColor('red')
            setTimeout(()=>{
              setOpacity(1)
              setScale('scale(1.1)')
                setTimeout(()=>{
                setOpacity(0)
                setScale('scale(1.0)')
                
                },5000)
          },500)
      }
      

    }
  }

  function onUsernameChanged(e)
  {
    setUsername(e.target.value)
  }

  const onSelectedChanged = ()=>
  {
    setSelected(!selected)
  }
    
  function onFirstChanged(e)
  {
    setFirstName(e.target.value)
  }

  function onEmailChanged(e)
  {
    setEmail(e.target.value)
  }

  function onLastChanged(e)
  {
    setLastName(e.target.value)
  }

  const onCancel = (e) => {
    setToggle(true)
    setTitle('EDIT')
  }

  React.useEffect(()=>{

    fetch(process.env.REACT_APP_LOCALHOST+`/translator/?id=${auth.token}`)
        .then(resp=>{
            if(resp.ok)
            {
              return resp.json()
            }
        })
        .then(data=>{
            console.log(data)
            setFirstName(data.data.mem.first_name)
            setLastName(data.data.mem.last_name)
            setEmail(data.data.mem.email)
            setUsername(data.data.mem.username)
            setRole(data.data.access_id)
            console.log(data.data.id)
        })
        .catch(err=>{
            console.log(err)
        })
  },[])

  React.useEffect(()=>{
    checkInputRequirements()
  },[username, firstName, lastName, email,role])

  return (
    <Grid container columns={{ xs: 8, md: 8, lg: 8, xl:8}} sx={{pt:8, height:'100vh'}}>
      <Grid item xs={0.2} md={0.2} lg={0.5} xl={1}/>
      <Grid item xs={7.6} md={7.6} lg={7} xl={6}>
        <Box
          sx={{borderRadius:5,
          borderColor:'#84BD00',
          p:3,
          maxWidth: 1254, mx:'auto'}}>
          <Grid container 
                rowSpacing={2} 
                spacing={5} 
                columns={{xs: 8, lg: 8, xl:8}}>
            <Grid item xs={4} lg={4}>
              <Box display="flex">
                <Typography variant='subtitle1' sx={{display:'inherit', alignItems:"center", width:'9em'}}>Username:</Typography>
                <TextField
                  disabled={toggle}
                  variant='filled'
                  color='success'
                  value={username}
                  error={usernameError}
                  onChange={onUsernameChanged}
                  fullWidth
                  sx={{maxWidth:400}}/>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex">
                <Typography variant='subtitle1' sx={{display:'inherit', alignItems:"center", width:'9em'}}>Role:</Typography>
                <FormControl 
                  variant='filled' 
                  disabled
                  fullWidth sx={{maxWidth:400}}
                  error={roleError}
                  color='success'
                  >
                  <InputLabel>Role</InputLabel>
                  <Select value={role} labal="Role" onChange={roleChange}>
                    <MenuItem value={1}>Interpreter</MenuItem>
                    <MenuItem value={2}>Captionist</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display='flex'>
                <Typography variant='subtitle1' sx={{display:'inherit', alignItems:"center", width:'9em'}}>First Name:</Typography>
                <TextField
                  disabled={toggle}
                  variant='filled'
                  color='success'
                  value={firstName}
                  error={firstNameError}
                  onChange={onFirstChanged}
                  fullWidth
                  sx={{maxWidth:400}}/>
              </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display='flex'>
                <Typography variant='subtitle1' sx={{display:'inherit', alignItems:"center", width:'9em'}}>Last Name:</Typography>
                <TextField
                  disabled={toggle}
                  variant='filled'
                  color='success'
                  value={lastName}
                  error={lastNameError}
                  onChange={onLastChanged}
                  fullWidth
                  sx={{maxWidth:400}}/>
                </Box>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Box display="flex">
                <Typography variant='subtitle1' sx={{display:'inherit', alignItems:"center", width:'9em'}}>Email:</Typography>
                <TextField
                  disabled={toggle}
                  variant='filled'
                  color='success'
                  value={email}
                  error={emailError}
                  onChange={onEmailChanged}
                  fullWidth
                  sx={{maxWidth:400}}/>
              </Box>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Button variant='outlined' color='error' disabled={toggle} onClick={onCancel} sx={{ borderRadius:7, mr:2}}>Cancel</Button>
                <ButtonStyled action={onEdit} title={title}/>
              </Grid>
            </Grid>
            <Grid item xs={8}>
            <Typography variant='h5' sx={{color:color,opacity:opacity,transition:'.5s',transform:scale, textAlign:'center'}}><b>{pendingMsg}</b></Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item/>
    </Grid>
  )
}

export default Profile