/******************************************************\
*    Created by: ajpccl - Aaron Joel Parker            *
*    Modified by: gvccl - Gabriel Veit,                *
*                 jpaccl - James Anderson              *
\******************************************************/

import * as React from 'react'
import logo from '../media/green.png'
import {useTheme, 
    Grid, 
    Box, 
    Drawer, 
    AppBar, 
    Toolbar, 
    List, 
    Typography, 
    Divider,
    ListItemButton,
    ListItemText, 
    Stack,
    Badge, 
    IconButton, 
    ListItemIcon, styled} from '@mui/material'
import Assignment from '@mui/icons-material/Assignment';
import Group from '@mui/icons-material/Group';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Dashboard from '@mui/icons-material/Dashboard';
import Computer from '@mui/icons-material/Computer';
import Help from '@mui/icons-material/Help';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import Person2 from '@mui/icons-material/Person2';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import {LightMode, DarkMode, Person } from '@mui/icons-material'
import {Outlet, Link, useNavigate, Navigate, NavLink, useLocation} from 'react-router-dom'
import ButtonStyled from '../components/ButtonStyled'
import { toggleTheme } from '../slices/theme.slice'
import { useDispatch } from 'react-redux'
import { IndexSelected, IndexRemove } from '../slices/navi.slice';
import {LoggingOut} from '../slices/auth.slice'
import { useSelector } from 'react-redux'
import { Role } from '../utilities/Enums'

const drawerWidth = 240

const Services = ()=>
{
    const [tabs, setTabs] = React.useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const open = Boolean(anchorEl);
    const [openPop, setOpenPop] = React.useState(false);
    const [Notifications, setNotifications] = React.useState(1)
    const theme = useTheme();
    const dispatch = useDispatch()
    const auth = useSelector((state)=>state.auth)
    const navi = useSelector((state)=>state.navi)
    const location = useLocation()


    const StyledBadge2 = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          right: 125,
          top: 8,
          padding: '0',
          borderRadius:'10px'
        },
      }));

      const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          right: 0,
          top: 0,
          padding: '0',
          borderRadius:'10px'
        },
      }));

    //,'Approved':<PlaylistAddCheck fontSize='large'/>

    const icons = []

    icons[3] = {'Manage Requests':<Assignment/>,'Schedule':<CalendarMonth/>,'Presentation':<Computer/>,'Notifications':<NotificationsOutlined/>,'Help Center':<Help/>}
    icons[4] = {'Presentation':<Computer/>,'Notifications':<NotificationsOutlined/>,'Help Center':<Help/>}
    icons[5] = {'Requests':<Assignment/>,'Schedule':<CalendarMonth/>,'Access Providers':<Group/>,'Notifications':<NotificationsOutlined/>,'Help Center':<Help/>}
    icons[6] = {'Assignments':<Assignment/>,'Board':<Dashboard/>,'Profile':<Person2/>,'Notifications':<NotificationsOutlined/>,'Help':<Help/>}

    function toggled()
    {
        dispatch(toggleTheme())
    }

    const history = useNavigate()

    const handleListItemClick = (event, index) => {
        dispatch(IndexSelected({index}))
      };

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPop((previousOpen) => !previousOpen);
      }

    function LogOut()
    {
        dispatch(IndexSelected({index:0}))
        fetch(process.env.REACT_APP_LOCALHOST+`/auth/?id=${auth.token}`)
        .then(res=>{
            
            dispatch(LoggingOut())
        })
        .then(data=>console.log(data))
        .catch(err=>console.log(err))
        setTimeout(history('/login'),1000)   
    }

    React.useEffect(()=>{
        console.log(location.pathname)
    },[location])

    React.useEffect(()=>{

        document.title='Portal'

        switch(auth.role)
        {
            case 3:
                setTabs(['Manage Requests','Schedule','Presentation','Notifications','Help Center',])
            break;
            case 4:
                setTabs(['Presentation', 'Notifications','Help Center'])
            break;
            case 5:
                setTabs(['Requests','Schedule','Access Providers','Notifications','Help Center'])
            break;
            case 6:
                setTabs(['Assignments','Board','Profile','Notifications','Help Center'])
            break;
            default:
                console.log('')
            break;
        }
    },[auth.role])

    return(
        <>
        {auth.isAuthenticated?
        <>
        <Box sx={{display:{xs:'none',md:'inline-block'}, width:'100%', minWidth:'360px'}}>
            <AppBar
                elevation={0}
                position='sticky'
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}>
                <Toolbar>
                    {/* <Grid
                        container
                        direction="row"
                        justifyContent='left'
                        alignItems="left">
                        <Typography variant='h3'>{Role[auth.role]}</Typography>
                    </Grid> */}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    display:'flex',
                    '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left">
                <Toolbar>
                    <Box color='secondary' sx={{ml:1.5}}>
                        <Stack spacing={1} direction='row' >
                                <img src={logo} alt=''/>
                                <Box>
                                    <Typography align='left' sx={{lineHeight:.9,mt:1,fontSize:20,}}><b>Access</b><br/>on Demand</Typography>
                                </Box>
                        </Stack>
                    </Box>
                </Toolbar>
                <Divider sx={{color:'primary.light'}}/>
                <Box sx={{position:'relative', height:'100%'}}>
                    <List>
                        <nav>
                            {tabs.map((text,index)=>(
                                <Link 
                                key={index} to={'/services/'+(text.toLowerCase()).replace(/\s+/g, '')} style={{textDecoration: 'none'}}>
                                <ListItemButton 
                                selected={location.pathname==='/services/'+(text.toLowerCase()).replace(/\s+/g, '')}
                                sx={{color:'secondary.main'}}>
                                    <ListItemIcon>
                                    {icons[auth.role][text]}
                                    </ListItemIcon>
                                    <ListItemText sx={{justifyContent:'center'}}>
                                        {text}
                                        {(text==='Notifications'&&Notifications>0)&&<StyledBadge2 badgeContent={4} overlap='circular' color='error'/>}
                                    </ListItemText>
                                </ListItemButton>
                                </Link>
                            ))}
                        </nav>
                    </List>
                    <Box sx={{bottom:0, position:'absolute',p:1,pl:5}}>
                        <IconButton onClick={toggled} sx={{ mr: 1,  }}>
                            {theme.palette.mode === 'light' ? <LightMode/> : <DarkMode/>}
                        </IconButton>
                        <ButtonStyled action={LogOut} title='Sign Out'/>
                    </Box>
                </Box>
            </Drawer>
            <Box
            component='main'
            sx={{flexGrow:1, width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}>
                <Outlet/>
            </Box>
        </Box>
        <>
            <Box sx={{display:{xs: 'inline-block', md:'none'}, minWidth:'360px'}}>
            <IconButton
                size='large'
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{m:1}} 
                onClick={() => setIsDrawerOpen(true)}>
                <MenuIcon/>
            </IconButton>
            <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}>
                <Toolbar>
                    <Box color='secondary' sx={{ml:1.5}}>
                        <Stack spacing={1} direction='row' >
                            <img src={logo} alt=''/>
                            <Box>
                                <Typography align='left' sx={{lineHeight:.9,mt:1,fontSize:20,}}><b>Access</b><br/>on Demand</Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Toolbar>
                <Divider sx={{color:'primary.light'}}/>
                <Box sx={{position:'relative', height:'100%'}}>
                    <List>
                        <nav>
                            {tabs.map((text,index)=>(
                                <Link key={index} to={'/services/'+(text.toLowerCase()).replace(/\s+/g, '')} style={{ textDecoration: 'none', fontSize:20}}>
                                <ListItemButton 
                                selected={location.pathname==='/services/'+(text.toLowerCase()).replace(/\s+/g, '')}
                                sx={{color:'secondary.main'}}>
                                    <ListItemIcon>
                                        {text==='Notifications'?<div>{icons[auth.role][text]}<StyledBadge badgeContent={4} overlap='circular' color='error'/></div>:icons[auth.role][text]}
                                    </ListItemIcon>
                                    <ListItemText>
                                            {text}
                                    </ListItemText>
                                </ListItemButton>
                                </Link>
                            ))}
                        </nav>
                    </List>
                    <Box sx={{bottom:0, position:'absolute',p:1,pl:5}}>
                        <IconButton onClick={toggled} sx={{ mr: 1,  }}>
                            {theme.palette.mode === 'light' ? <LightMode/> : <DarkMode/>}
                        </IconButton>
                        <ButtonStyled action={LogOut} title='Sign Out'/>
                    </Box>
                </Box>
            </Drawer>
            <Box
            component='main'
            sx={{minHeight:'100vh', minWidth:'100vw'}}>
                <Outlet/>
            </Box>
            </Box>
            </>
            </>:<Navigate replace to='/login'/>}
        </>
    )
}

export default Services



/*style={isActive=>({
    backgroundColor:isActive?'rgba(132, 189, 0, 0.16)':'transparent',
    textDecoration: 'none'
})} */ 