/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *                                                      *
 ********************************************************/

import * as React from 'react'
import {FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import {Category } from '../utilities/Enums'

const CategoryBar = (props)=>
{
    const [dropValue, setDropValue] = React.useState()

    let dropHandler = (e)=>{

        setDropValue(e.target.value)

        props.setInputText(Category[e.target.value])
    }

    return (
        <>
        <FormControl sx={{width:'15em'}}>
            <InputLabel>Category</InputLabel>
            <Select
            value={dropValue}
            onChange={dropHandler}
            label='Category'>
                <MenuItem value={1}>Medical</MenuItem>
                <MenuItem value={2}>Class</MenuItem>
                <MenuItem value={3}>Sport</MenuItem>
                <MenuItem value={4}>Concert</MenuItem>
                <MenuItem value={5}>Meeting</MenuItem>
                <MenuItem value={6}>Conference</MenuItem>
                <MenuItem value={7}>Other</MenuItem>
            </Select>
        </FormControl>
        </>
    )
}

export default CategoryBar