/********************************************************
 *                                                      *
 *    Created by: ajpccl - AaronJoel Parker             *
 *    Modified by: jpaccl - James Patrick Anderson      *
 *                                                      *
 ********************************************************/

import React, {useContext} from 'react'
import { Grid, Box, Typography, TextField, Button, FormControlLabel, Checkbox, CircularProgress, Backdrop } from '@mui/material'
import Template from '../components/Template'
import ButtonStyled from '../components/ButtonStyled'
import ToggleSwitch from '../components/ToggleSwitch'
import axios from 'axios'

const Register = ()=>
{
    const [firstName, setFirstName] = React.useState()
    const [firstNameError, setFirstNameError] = React.useState()
    const [firstNameErrorColor, setFirstNameErrorColor] = React.useState(false)
    const [firstNameRequired, setFirstNameRequired] = React.useState(false)

    const [lastName, setLastName] = React.useState()
    const [lastNameError, setLastNameError] = React.useState()
    const [lastNameErrorColor, setLastNameErrorColor] = React.useState(false)
    const [lastNameRequired, setLastNameRequired] = React.useState(false)

    const [username, setUsername] = React.useState()
    const [usernameError, setUsernameError] = React.useState()
    const [usernameErrorColor, setUsernameErrorColor] = React.useState(false)
    const [usernameRequired, setUsernameRequired] = React.useState(false)

    const [email, setEmail] = React.useState()
    const [emailError, setEmailError] = React.useState()
    const [emailErrorColor, setEmailErrorColor] = React.useState(false)
    const [emailRequired, setEmailRequired] = React.useState(false)

    const [password, setPassword] = React.useState()
    const [passwordError, setPasswordError] = React.useState()
    const [passwordErrorColor, setPasswordErrorColor] = React.useState(false)
    const [passwordRequired, setPasswordRequired] = React.useState(false)
    
    const [secPassword, setSecPassword] = React.useState()
    const [secPwdError, setSecPwdError] = React.useState()
    const [secPwdErrorColor, setSecPwdErrorColor] = React.useState(false)
    const [secPwdRequired, setSecPwdRequired] = React.useState(false)

    const [showPwd, setShowPwd] = React.useState('password')
    const [display, setDisplay] = React.useState(true)
    const [opacity, setOpacity] = React.useState(0)
    const [accept, setAccept] = React.useState(true)
    const [open, setOpen] = React.useState(false)

    const statusMsg = {false:'Failed register the account',true:'Successfully registered an account!'}

    const handleFirst = (event)=>
    {
        setFirstName(event.target.value)
    }

    const handleLast = (event)=>
    {
        setLastName(event.target.value)
    }

    const handleEmail = (event)=>
    {
        setEmail(event.target.value)
    }

    const handleUsername = (event)=>
    {
        setUsername(event.target.value)
    }

    const handlePassword = (event)=>
    {
        setPassword(event.target.value)
    }

    const secondPassword = (event)=>
    {
        setSecPassword(event.target.value)
    }

    const handleShowPwd = (event) =>
    {
        if(event.target.checked === true){
            setShowPwd('text');
        }
        else
        {
            setShowPwd('password');
        }
    }

    function Error(input, str)
    {
        var errFlag = null;

        if(str !== null && str !== "" && str !== undefined){
            errFlag = true;
        }
        else 
        {
            errFlag = false;
        }

        switch(input){
            case "firstName":
                if(errFlag)
                {
                    setFirstNameError(str)
                    setFirstNameErrorColor(true)
                    setFirstNameRequired(true)
                } else 
                {
                    setFirstNameError(null)
                    setFirstNameErrorColor(false)
                    setFirstNameRequired(false)
                }
                break;
            case "lastName":
                if(errFlag)
                {
                    setLastNameError(str)
                    setLastNameErrorColor(true)
                    setLastNameRequired(true)
                } else 
                {
                    setLastNameError(null)
                    setLastNameErrorColor(false)
                    setLastNameRequired(false)
                }
                break;
            case "username":
                if(errFlag)
                {
                    setUsernameError(str)
                    setUsernameErrorColor(true)
                    setUsernameRequired(true)
                } else 
                {
                    setUsernameError(null)
                    setUsernameErrorColor(false)
                    setUsernameRequired(false)
                }
                break;
            case "email":
                if(errFlag)
                {
                    setEmailError(str)
                    setEmailErrorColor(true)
                    setEmailRequired(true)
                } else 
                {
                    setEmailError(null)
                    setEmailErrorColor(false)
                    setEmailRequired(false)
                }
                break;
            case "password":
                if(errFlag)
                {
                    setPasswordError(str)
                    setPasswordErrorColor(true)
                    setPasswordRequired(true)
                } else 
                {
                    setPasswordError(null)
                    setPasswordErrorColor(false)
                    setPasswordRequired(false)
                }
                break;
            case "secPassword":
                if(errFlag)
                {
                    setSecPwdError(str);
                    setSecPwdErrorColor(true);
                    setSecPwdRequired(true);
                } else
                {
                    setSecPwdError(null);
                    setSecPwdErrorColor(false);
                    setSecPwdRequired(false);
                }
                break;
            default:
                console.log( "Error: Couldn't find the input - " + input)
        }
    }

    const checkInput = () =>
    {
        console.log('Checking Inputs...');

        // Total of inputs. If one of the inputs has a error and changes to true, 
        // then the POST request will not be executed.
        // firstName, lastName, username, email, password, secPassword
        const errorInput = [false, false, false, false, false, false];

        if(firstName !== undefined && firstName !== null && firstName !== '')
        {
            Error("firstName");
            errorInput[0] = false;
        }
        else
        {
            Error("firstName", "Enter a first name");
            errorInput[0] = true;
        }

        if(lastName !== undefined && lastName !== null && lastName !== '')
        {
            Error("lastName");
            errorInput[1] = false;
        }
        else
        {
            Error("lastName", "Enter a last name");
            errorInput[1] = true;
        }

        if(username !== undefined && username !== null && username !== '')
        {
            Error("username");
            errorInput[2] = false;
        }
        else
        {
            Error("username", "Enter a username");
            errorInput[2] = true;
        }

        if(email !== undefined && email !== null && email !== '')
        {
            Error("email");
            errorInput[3] = false;
        }
        else
        {
            Error("email", "Enter an email");
            errorInput[3] = true;
        }

        //Password & Confirm Password
        //Checking Password
        if(password !== undefined && password !== null && password !== '')
        {
            Error("password");
            errorInput[4] = false;

            //Checking Confirm Password
            if(password === secPassword)
            {
                Error("secPassword");
                errorInput[5] = false;
            } else
            {
                Error("secPassword", "Those passwords didn't match. Try again");
                errorInput[5] = true;
            }
        }
        else
        {
            Error("password", "Enter a password");
            errorInput[4] = true;

            Error("secPassword");
            errorInput[5] = false;
        }

        var noError = true;

        errorInput.forEach(element => {if(element){
            noError = false;
        }});
        
        return noError;
    }

    const onRegister = ()=>
    {
        if(checkInput()){

            setOpen(true)
            
            axios.post(process.env.REACT_APP_LOCALHOST+'/member',{
                firstName:firstName,
                lastName:lastName,
                username:`${username}`.toLowerCase(),
                email:email,
                password:password

            })
            .then((data)=>{
                console.log(data)

                setAccept(true)
                
                setTimeout(()=>{
                    setOpacity(1)

                    setTimeout(()=>{
                        setOpen(false)
                        setOpacity(0)
                        
                    },1000)
                    
                },1000)
            })
            .catch((err)=>{

                setAccept(false)
                setOpacity(1)

                setTimeout(()=>{

                    setOpen(false)
                },1000)
                
                console.log(err)
            })
        }
        else{
            console.log('Invalid Inputs')
        }
    }

    return(
        <Template>
            <Box sx={{py:{xs: 1, sm:5}, pb:{xs:5}, minHeight:'100vh'}}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{transition:'.3',m:1}}
                columns={{xs: 8, sm: 8, md: 8 }}>
                <Grid item />
                <Grid item xs={6.75} sm={5.5} md={4}>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Typography variant='h2' sx={{m:2, fontSize:{xs: '2.5rem', sm: '3rem'}}}>Registration</Typography>
                    </Box>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Box
                            sx={{flexGrow: 1,
                            borderRadius:5,
                            boxShadow:2,
                            border:2, 
                            borderColor:'#84BD00',
                            p:3,
                            maxWidth:850}}>
                            <Grid container 
                                rowSpacing={1} 
                                spacing={2} 
                                columns={{xs: 4, sm: 8 }}>
                                <Grid item xs={4} sm={8}>
                                    <TextField
                                        error={usernameErrorColor}
                                        required={usernameRequired}
                                        helperText={usernameError}
                                        color='success'
                                        label='Username'
                                        value={username}
                                        onChange={handleUsername}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={firstNameErrorColor}
                                        required={firstNameRequired}
                                        helperText={firstNameError}
                                        color='success'
                                        label='First Name'
                                        value={firstName}
                                        onChange={handleFirst}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={lastNameErrorColor}
                                        required={lastNameRequired}
                                        helperText={lastNameError}
                                        color='success'
                                        label='Last Name'
                                        value={lastName}
                                        onChange={handleLast}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4} sm={8}>
                                    <TextField
                                        error={emailErrorColor}
                                        required={emailRequired}
                                        helperText={emailError}
                                        color='success'
                                        label='Email Address'
                                        value={email}
                                        onChange={handleEmail}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={passwordErrorColor}
                                        required={passwordRequired}
                                        helperText={passwordError}
                                        color='success'
                                        label='Password'
                                        type={showPwd}
                                        value={password}
                                        onChange={handlePassword}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={secPwdErrorColor}
                                        required={secPwdRequired}
                                        helperText={secPwdError}
                                        color='success'
                                        label='Confirm Password'
                                        type={showPwd}
                                        value={secPassword}
                                        onChange={secondPassword}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={4} sm={8}>
                                    <FormControlLabel control={<Checkbox onChange={handleShowPwd}/>} label="Show Password"/>
                                </Grid>
                                <Grid item xs={4} sm={8}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center">
                                        <ButtonStyled action={onRegister} title='Sign Up' marginTop={3}/>
                                        <ButtonStyled variant='text' href='/login' sx={{mt:2}} title='Already have an account? Sign in' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item/>
                    <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={open}>
                    <Box sx={{display:'block', ml:'auto',mr:'auto'}}>
                    <Box>
                    <CircularProgress 
                        color='success'
                        size='25vh'
                        sx={{display:'block', ml:'auto',mr:'auto'}}/>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center', opacity:1}}>
                        <Typography variant='h2' sx={{m:'auto', fontSize:{xs: '2.5rem', sm: '3rem'}}}><b>{statusMsg[accept]}</b></Typography>
                    </Box>
                    </Box>
                    </Backdrop>
            </Grid>
            </Box>
            <ToggleSwitch />
        </Template>
    )
}

export default Register