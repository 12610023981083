import React, {useContext} from 'react'
import { Box , Button, Typography, Grid, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemText, List} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {format} from 'date-fns'
import { TimePicker, LocalizationProvider, DatePicker, MobileDatePicker} from '@mui/x-date-pickers'
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'

import {useSelector} from'react-redux'
import { FixedSizeList } from 'react-window'
import { SettingsPowerRounded } from '@mui/icons-material'
import { EventsContext } from '../utilities/Contexts'
import { useOrgAddEventMutation } from '../endpoints/org.endpoint'
import ButtonStyled from './ButtonStyled'

const NewEvent = (props)=>
{
    const [title, setTitle] = React.useState('')
    const [date, setDate] = React.useState('')
    const [endTime, setEndTime] = React.useState(null)
    const [startTime, setStartTime] = React.useState(null)
    const [location, setLocation] = React.useState('')
    const [titleError, setTitleError]=React.useState(false)
    const [locationError, setLocationError] = React.useState(false)
    const [dateError, setDateError] = React.useState(false)
    const [dateMsgError, setDateMsgError] = React.useState(false)
    const cleanInput =[true,true,true,true,true,true,true,true]
    const auth = useSelector(state=>state.auth)
    // const [open, setOpen] = React.useState(false)
    // const [pendingMsg, setPendingMsg] = React.useState('Submitting the request')
    const {rows, setRows} = useContext(EventsContext)
    const [orgAddEvent] = useOrgAddEventMutation()
    

    React.useEffect(()=>{

      console.log(date)
      console.log(startTime)
      console.log(endTime)

    },[startTime, endTime, date])


    const DateTimeSanitization=(_date, _time)=>{
      
      const fixedDate = new Date(new Date(_date).getFullYear(), new Date(_date).getMonth(), new Date(_date).getDate(), new Date(_time).getHours(), new Date(_time).getMinutes())

      return fixedDate
    }

    const checkInput =(textInput,index)=>
    {
      if(textInput==='' || textInput===undefined || textInput===null)
      {
        cleanInput[index] = false;
        return true
      }
      else
      {
        cleanInput[index]= true
        return false
      }
    }
    const checkInputRequirements=()=>
    {
        if(checkInput(title,0))
        {
          setTitleError(true)
        }
        if(checkInput(location,1))
        {
          setLocationError(true)
        }
        if(checkInput(date,2))
        {
          console.log(date)
          setDateError(true)
        }
    }

    const postRequest = async()=>
    {

        if(Object.values(cleanInput).every(Boolean))
        {
          console.log('Submitting the request')

          const result = await orgAddEvent({
            title:title,
            startTime:DateTimeSanitization(date,startTime),
            endTime:DateTimeSanitization(date, endTime),
            location:location,
            date:date, 
            id:auth.token})

            console.log(result)
            setRows([...rows, {id:rows.length, eventId:result.data.id, title:title, venue:location, date:result.data.date, startTime:result.data.start_time, endTime:result.data.end_time, eventSessions:[]}])
            props.setOpen(false)

        }
        else
        {

          // setPendingMsg('Request submit failed')
                setTimeout(()=>{

                  props.setOpen(false)
          },2000)
        }
    }

    const handleRequest =()=>
    {
       checkInputRequirements()

       postRequest()
    }

    const handleTitle=(e)=>
    {
        setTitle(e.target.value)
        setTitleError(false)
    }

    const handleLocation = (e)=>
    {
        setLocation(e.target.value)
        setLocationError(false)
    }

    const handleDate = (e)=>
    {
      console.log(e)
      if(e===null)
      {
        return
      }

      setDate(e)
      
      setDateError(false)
    }


    const DateErrorAlert=(status)=>
    {
      if(status==='error')
      {
        setDateError(true)
        setDateMsgError('invalid date')
      }
      else
      {
        setDateError(false)
        setDateMsgError('valid date')
      }
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={props.open}>
                <DialogTitle>New Event</DialogTitle>
                <DialogContent>
                    <Grid container
                          columns={{xs:8, ms:8}}
                          rowSpacing={2}
                          columnSpacing={{xs:1, sm:2}}>
                    <Grid item xs={8} sm={8}>
                        <TextField
                            autoFocus
                            required
                            margin='dense'
                            id='name'
                            label='Title'
                            value={title}
                            error={titleError}
                            inputProps={{maxLength:30}}
                            type='text'
                            onChange={handleTitle}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                        <TextField
                            autoFocus
                            required
                            margin='dense'
                            id='name'
                            label='Location'
                            error={locationError}
                            value={location}
                            onChange={handleLocation}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                    <DemoContainer components={['MobileDatePicker']}>
                    <DemoItem>
                  <MobileDatePicker
                    label='Date'
                    disablePast
                    onError={(error,value)=>{
                        if(error){

                          DateErrorAlert('error')
                          console.log(value)
                        }
                        else
                        {
                          console.log(value)
                          DateErrorAlert('functional')
                        }
                    }}
                    onChange={handleDate}
                    renderInput={(params)=>(
                    <TextField
                      required
                      {...params}
                      error={dateError}
                      helperText={dateMsgError} />
                      )}/>
                      </DemoItem>
                      </DemoContainer>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <DemoContainer components={['TimePicker']}>
                        <DemoItem>
                        <TimePicker
                          label='Start Time'
                          value={startTime}
                          onChange={(newValue)=>setStartTime(newValue)}
                          maxTime={endTime}/>
                        </DemoItem>
                      </DemoContainer>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <DemoContainer components={['TimePicker']}>
                        <DemoItem>
                        <TimePicker
                    label='End Time'
                        value={endTime}
                        onChange={(newValue)=>setEndTime(newValue)}
                        minTime={startTime}/>
                        </DemoItem>
                      </DemoContainer>
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
              <ButtonStyled title='Cancel' variant='outlined' color='error' action={()=>{props.setOpen(false)}} />
              <ButtonStyled title='Confirm' variant='contained' color='primary' action={handleRequest} />
            </DialogActions>
            </Dialog>
        </LocalizationProvider>
    )
}

export default NewEvent