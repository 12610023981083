/********************************************************
 *                                                      *
 *           Created by: gvccl - Gabriel Veit           *
 *                                                      *
 ********************************************************/

import * as React from 'react';
import { Box, Typography, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import vuzixLogo from '../media/vuzixLogo.png';
import ritNTIDLogo from '../media/ritNTIDLogo.png';
import ritRCLogo from '../media/ritRCLogo.png';
import Aos from "aos";
import '../styles/logo.css';
import "aos/dist/aos.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const imgStyled = {
    width:'250px',
    maxWidth:'100%',
    objectFit:'cover',
    padding:'1px'
}

const imgStyled2 = {
    width:'164px',
}

const OurPartners = (props)=>
{
    const [value, setValue] = React.useState(0)

    React.useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return(
        <>
        <Box sx={{backgroundColor:'#D0D3D4', color:'black', textAlign:'center',p:3}}>
            <Box data-aos="slide-up">
                <Typography variant='h4' sx={{marginTop:5, marginBottom: 3}}>Our Partners</Typography>
            </Box>

            <Box
                data-aos="fade-up"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 0,
                    flexDirection:{md:'row',sm:'column',xs:'column'},
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom:10
                }}

            >
                <Box className="logoBox">
                    <Item sx={{backgroundColor: 'white', width:300, cursor: 'pointer'}}>
                        <CardActionArea href="https://www.vuzix.com/">
                            <img className="logo" src={vuzixLogo} style={imgStyled} sx={{width:'250px'}} alt='vuzixLogo'/>
                        </CardActionArea>
                    </Item>
                </Box>
                <Box className="logoBox">
                    <Item sx={{backgroundColor: 'white', width:300, cursor: 'pointer'}}>
                        <CardActionArea href="https://www.rit.edu/ntid/">
                            <img className="logo" src={ritNTIDLogo} style={imgStyled} alt='ritNTIDLogo'/>
                        </CardActionArea>
                    </Item>
                </Box>
                <Box className="logoBox">
                    <Item sx={{backgroundColor: 'white', width:300, cursor: 'pointer'}}>
                        <CardActionArea href="https://www.rit.edu/researchcomputing/">
                            <img className="logo" src={ritRCLogo} style={imgStyled2} alt='ritRCLogo'/>
                        </CardActionArea>
                    </Item>
                </Box>

            </Box>

        </Box>
        <Box sx={{backgroundColor:'white', textAlign:'center',p:7}}>

        </Box>
        </>
    )
}

export default OurPartners;