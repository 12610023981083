/********************************************************
 *                                                      *
 *           Created by: gvccl - Gabriel Veit           *
 *                                                      *
 ********************************************************/

import React, { createContext } from 'react'
import { useTheme } from '@mui/material/styles';
import { format} from 'date-fns'
import ButtonStyled from '../components/ButtonStyled';
import SessionInfoTemplate from '../components/SessionInfoTemplate'
import MoreOptionsMenu from '../components/MoreOptionsMenu';
import { 
    Cancel, 
    Close, 
    Comment, 
    Info} from '@mui/icons-material'
import { 
    IconButton, 
    Dialog,Grid, 
    Typography, 
    DialogTitle, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    Button, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    useMediaQuery } from '@mui/material'
import { 
    Status, 
    Access, 
    Category } from '../utilities/Enums'
import '../styles/table.css';
import '../styles/icon.css'
import '../styles/dialog.css'
import { useSelector} from 'react-redux'
import { 
    useRetrieveRequestsQuery, 
    useRequestAgencyMutation,
    useUnassignRequestMutation, 
    useRetrieveAgenciesQuery } from '../endpoints/org.endpoint'
// import { RequestsContext } from '../utilities/Contexts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS as locale } from 'date-fns/locale';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { baseColumns } from '../utilities/BaseColumns';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GridToolbar } from '@mui/x-data-grid-pro';
import { ErrorBoundary } from 'react-error-boundary';


const dateAdapter = new AdapterDateFns({ locale });

const RequestsContext = createContext(null);

//#region NewRequests
const NewRequests = () => {

    const [requestRows, setRequestRows] = React.useState([])
    const auth = useSelector((state)=>state.auth)

    const theme = useTheme()
    const smallWidth = useMediaQuery(theme.breakpoints.down('sm'))


    const {
        data:requests,
        isLoading,
        isSuccess
    } = useRetrieveRequestsQuery(auth.token)

    React.useEffect(() => {
        if(isLoading){

            console.log('Loading...')

        }
        else if(isSuccess)
        {
            var rows = []

            console.log(requests)

            requests.result.businessAgreements.map((info, index)=>{
                
                const {sess} = info.req
                const {req} = info

                console.log(info)
                console.log(sess.title)
                console.log(req.date)
                const statusId = info.status_id;
                const status = statusId === 3 ? 2 : statusId;

                rows.push({
                    id:index,
                    requestNum:req.number,
                    title:sess.title,
                    accessType:Access[req.access_id],
                    date:req.date,
                    startTime:sess.start_time,
                    endTime:sess.end_time,
                    status:status,
                    dateCreated:req.createdAt,
                    info:info
                })
            })

            setRequestRows(rows)
        }
    }, [requests,
        isLoading,
        isSuccess])


    const removeRow = React.useCallback(
        (index) => {
            console.log('Removing...')
            setRequestRows((prevRows) => prevRows.filter((item) => item.id !== index));
        }, [])

    const updateRequestStatus = React.useCallback(
        (sessionId, newStatus) => {
            console.log('Updating...')
            setRequestRows(prevRows => prevRows.map((row) => row.id === sessionId ? { ...row, status: newStatus } : row ));
        }, [])

    const columns = React.useMemo(
        () => [
            ...baseColumns,
            { 
                field: 'dateCreated',  
                headerName: 'Created On', 
                width: 160, 
                type: 'dateTime',
                valueFormatter: (value) => {
                    return format(new Date(value), "MM/dd/yyyy, hh:mm aaaaa'm'")
                }
            },
            {
                field: 'actions',
                type: 'actions',
                width: `${smallWidth ? 60:200}`,
                getActions: (params) => [
                    <ActionItems
                        info={params.row.info}
                        index={params.row.id}
                        updateRequestStatus={updateRequestStatus}
                        removeRow={removeRow}
                        status={params.row.status}
                        theme={theme}
                        smallWidth={smallWidth}
                    />,
                ],
            },
        ], [removeRow, updateRequestStatus, smallWidth, theme],
    );

    return (
        <ErrorBoundary fallback={<div>Whoops</div>}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={{ height: 'calc(100vh - 64px)', width: '100%' }}>
                    <Grid2
                        sx={{
                            position: 'absolute',
                            top: '18px',
                            // left: '20px',
                            zIndex: 1100,
                            ml: {xs: 8, md:2},
                            color: {xs: 'black', md: 'white'},
                            overflow: 'hidden',
                            maxWidth: 'calc(100% - 64px)'}}>
                    <Typography variant='h6'
                    sx={{fontWeight:400, textWrap:'nowrap'}}>
                        Manager: New Requests
                    </Typography>
                    </Grid2>
                    <DataGridPro
                        rows={requestRows}
                        columns={columns}
                        slots={{toolbar:GridToolbar}}
                        initialState={{
                            pinnedColumns: { right: ['actions'] },
                            pagination: {
                                paginationModel: {
                                  pageSize: 50,
                                },
                            },
                            sorting: {
                                sortModel: [{field: 'dateCreated', sort: 'desc'}]
                            }
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        pagination
                        sx={{
                            '& .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight, .MuiDataGrid-filler--pinnedRight': {
                                borderLeft: 'none',
                                width:'auto'
                            }
                        }}
                    />
                </div>
                
            </LocalizationProvider>
            </ErrorBoundary>
    )
}


export default NewRequests



//#endregion

//#region ActionItems
const ActionItems = (props) => {

    const {info} = props;
    console.log(info)

    const {req} = info;
    const {sess, sub} = req

    const [translator, setTranslator] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false)
    const [agency, setAgency] = React.useState();
    const [agencyError, setAgencyError] = React.useState(false)
    // const [ticketId] = React.useState(tick_req.ticket_id)
    const [startTime] = React.useState(format(new Date(sess.start_time), "hh:mm aaaaa'm'"))
    const [endTime] = React.useState(format(new Date(sess.end_time), "hh:mm aaaaa'm'"))
    const [date] = React.useState(format(new Date(req.date), 'MM/dd/yyyy'))
    const [access] = React.useState(`${req.access_id}`)
    const [clientName] = React.useState(info===null?'No current user':`${sub[0].mem.last_name}, ${sub[0].mem.first_name}`)
    const [clientEmail] = React.useState(info===null?'N/A':sub[0].mem.email)
    const [titleOfAction, setTitleOfAction] = React.useState('More Info')
    const status = Status[props.status]
    const auth = useSelector((state)=>state.auth)
    const [unassignRequest,{isLoading}] = useUnassignRequestMutation()
    const [requestAgency] = useRequestAgencyMutation()

    const cleanInput = [true]
    const theme = props.theme
    const smallWidth = props.smallWidth

    const _boolFlag = React.useCallback(() => {

        return (status.label === 'Unreviewed' || status.label === 'Unassigned')

    },[status.label])

    const handleOpenAgency = React.useCallback(() => {

        setTranslator(info.number)
        setTimeout(() => {
            setOpen(true);
        }, 100)
    },[info.number]);

    const checkInput = (textInput) => {
        if (textInput === '' || textInput === undefined || textInput === null) {
            cleanInput[0] = false
            return true
        }
        else {
            cleanInput[0] = true
            return false
        }
    }

    const removeClickOpen = React.useCallback(() => {

        setTranslator(info.number)
        setTimeout(() => {
            setOpen1(true);
        }, 100)
    },[info.number]);

    const deleteRequest = async() => {

        await unassignRequest({id:auth.token,requestId:req.id,session:info.session_id})
        
        setOpen1(false)
        props.removeRow(props.index)
    }

    const removeClickClose = () => {
        setOpen1(false);
    };

    React.useEffect(()=>{
        if(smallWidth){
            setTitleOfAction('Find Agency')
        }
        else
        {
            setTitleOfAction('More Info')
        }
    },[smallWidth])

    const confirm = async() => {

        console.log("Confirmed: ");
        checkInput(agency)
        if (cleanInput[0]) {
            setOpen(false);
            await requestAgency({
                id:auth.token,
                requestId:info.request_id,
                sessionId:req.session_id,
                businessId:info.business_id,
                agencyName:agency})
            .then((res)=>{
                props.updateRequestStatus(props.index, 2)
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        else {
            setAgencyError(true)
        }
    };

    const cancel = () => {
        setOpen(false)
        console.log('Cancel: ')
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {!smallWidth && <ButtonStyled disabled={ !_boolFlag() } color={'primary'} action={handleOpenAgency}  title={'Find Agency'} />}
            <Dialog
                open={open}
                onClose={handleClose}
                className="addRequestDialog">
                <DialogTitle variant='dialogTitle'>
                    Find Agency
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className='closeButton'>
                    <Close />
                </IconButton>
                <DialogContent sx={{ pt: '10px' }}>
                    <SessionInfoTemplate
                        title={sess.title}
                        accessType={Access[access]}
                        requestNum={req.number}
                        date={date}
                        startTime={startTime}
                        endTime={endTime}
                        category={Category[sess.category_id]}
                        clientName={clientName}
                        clientEmail={clientEmail}
                        status={status}
                        description={sess.description}
                        notes={
                            sess.notes}
                        role={auth.role}
                        theme={theme} />
                </DialogContent>
                {_boolFlag() ? <DialogActions sx={{ flexDirection: 'column', p: '16px', alignItems: 'flex-end' }} >
                    <ListOfAgency setAgency={setAgency} agency={agency} agencyError={agencyError} setAgencyError={setAgencyError} />
                    <Grid display={'flex'} gap={'8px'}>
                        <ButtonStyled action={cancel} color={'error'} variant='outlined' title='Cancel' />
                        <ButtonStyled action={confirm} color={'primary'} title='Confirm' />
                    </Grid>
                </DialogActions> :
                <DialogActions>
                    <ButtonStyled action={() => { setOpen(false) }} color={'info'} title='OK' />
                </DialogActions>}
            </Dialog>
            <MoreOptionsMenu menuItems={ React.useMemo(()=>[
                {action: () => { handleOpenAgency() }, title:titleOfAction, icon: <Info /> },
                {action: ()=> {}, title: 'Add a Note', icon: <Comment /> },
                {action: removeClickOpen , title: 'Unassign', icon: <Cancel />, disabled: !_boolFlag(), divider: true },
            ],[titleOfAction, _boolFlag, handleOpenAgency, removeClickOpen])} />
            <Dialog
                open={open2}
                onClose={()=>{setOpen2(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id='alert-dialog-title'>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The request you sent to the agency is currently being reviewed by the agency itself. Please kindly wait for at least one business day.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" sx={{ backgroundColor: 'green', color: 'white' }} onClick={() => { setOpen2(false) }}><b>Ok</b></Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open1}
                onClose={removeClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="deleteRequestDialog">
                <DialogTitle id="alert-dialog-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This request will be deleted permanently. Please click below to confirm your action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonStyled variant='outlined' color='secondary' action={removeClickClose} title='Go Back' />
                    <ButtonStyled variant="contained" color='error' action={deleteRequest} title='Delete' />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
//#endregion

//#region List Of Agency
const ListOfAgency = (props) => {

    const [agencies, setAgencies] = React.useState(null)
    const {data:results, isSuccess} = useRetrieveAgenciesQuery()

    const handleChange = (event) => {
        props.setAgency(event.target.value);
        props.setAgencyError(false)
    };

    if(isSuccess){
        var arr = []
        if(agencies===null){
            console.log(results)
            results.result.map((agency)=>{
                var menu = <MenuItem key={agency.id} value={agency.name}>{agency.name}</MenuItem>
                arr.push(menu)
            })
            setAgencies(arr)
        }
    }

    return (
        <FormControl
            required
            error={props.agencyError}
            sx={{
                m: 1,
                minWidth: 200,
                width: '100%',
                alignSelf: 'center'
            }}>
            <InputLabel id="demo-simple-select-required-label" variant='outlined'>Select Agency</InputLabel>
            <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={props.agency}
                label="Select Agency"
                onChange={handleChange}>
                {agencies}
            </Select>
        </FormControl>
    );
}
//#endregion

